<template>
  <b-card title="Update Theme Settings">
    <b-overlay
      blur="10px"
      opacity="0.30"
      rounded="sm"
      variant="light"
    >
      <b-form @submit.prevent="updateColor(colorObject)">
        <b-row>
          <b-col v-if="store.getters.permissions.filter(permission => permission === 'web-theme-setting').length > 0">
            <b-card
              title="Web Theme"
            >
              <b-row>
                <b-col
                  cols="12"
                  md="12"
                >
                  <label
                    class="label"
                  >
                    Pick a color
                    <feather-icon
                      v-b-tooltip.hover.v-primary="'Select the left-side color for the primary system color and the right-side color for a gradient or secondary color applied to headers.'"
                      icon="HelpCircleIcon"
                      size="15"
                      class="cursor-pointer text-primary mb-1 ml-1 mt-1"
                    />
                  </label>
                  <div class="random-color">
                    <div
                      class="display"
                      :style="{ background: backgroundStyle }"
                    />
                    <div class="inputColors d-flex">
                      <input
                        v-model="gradientColor1"
                        type="color"
                        class="gradientColor1"
                      >
                      <input
                        v-model="gradientColor2"
                        type="color"
                        class="gradientColor2"
                      >
                      <v-select
                        id="gradientDirection"
                        v-model="gradientDirection"
                        disabled
                        label="name"
                        style="width: 168px;"
                      />
                    </div>
                  </div>
                </b-col>
              </b-row>
              <b-row class="mt-2">
                <b-col
                  cols="12"
                  md="12"
                  class="theme-tooltip"
                >
                  This setting gives the option to change the web theme color, the
                  default color will be set as blue unless the color is changed.
                </b-col>
              </b-row>
            </b-card>
          </b-col>
          <b-col v-if="store.getters.permissions.filter(permission => permission === 'mobile-theme-setting').length > 0">
            <b-card
              title="Mobile App Theme"
            >
              <b-row>
                <b-col
                  cols="12"
                  md="12"
                >
                  <Picker
                    v-model="colorObject"
                  />
                </b-col>
              </b-row>
              <b-row class="mt-2">
                <b-col
                  cols="12"
                  md="12"
                  class="theme-tooltip"
                >
                  This setting gives the option to change the mobile app theme color, the
                  default color will be set as blue unless the color is changed.
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
        <b-row class="mt-1">
          <!-- submit -->
          <b-col class="d-flex justify-content-end">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
              class="mr-1"
            >
              Submit
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-overlay>
  </b-card>
</template>

<script>
import {
  BCard,
  BOverlay,
  BRow,
  BCol,
  BButton,
  BForm,
  VBTooltip,
} from 'bootstrap-vue'
import Picker from '@/components/ColorPicker/Picker.vue'
import SuccessMessage from '@core/mixins/SuccessMessage'
import ErrorMessage from '@core/mixins/ErrorMessage'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import { RepositoryFactory } from '@/repository/RepositoryFactory'

const colorThemeRepository = RepositoryFactory.get('colorTheme')

export default {
  components: {
    Picker,
    BCard,
    BOverlay,
    BRow,
    BCol,
    BButton,
    BForm,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  mixins: [SuccessMessage, ErrorMessage],
  data() {
    return {
      store,
      loading: false,
      color: '',
      colorObject: { hex: '#ffffff' },
      gradientColor1: '#049cfb',
      gradientColor2: '#001061',
      currentColor: '',
      colorHex: this.currentColor,
      gradientDirection: 'to right',
      appliedSettings: {},
    }
  },
  computed: {
    backgroundStyle() {
      return `linear-gradient(${this.gradientDirection}, ${this.gradientColor1}, ${this.gradientColor2})`
    },
  },
  mounted() {
    this.getThemeColor()
  },
  methods: {
    async updateColor(value) {
      this.loading = true
      this.color = value.hex
      const payload = {
        theme_color: this.color,
        gradientColor1: this.gradientColor1,
        gradientColor2: this.gradientColor2,
        gradientDirection: this.gradientDirection,
      }
      try {
        const res = await colorThemeRepository.setColor(payload)
        if (res.status === 200) {
          this.showSuccessMessage(res.data.message)
          this.updateSCSSVariables()
        } else {
          this.showErrorMessage('Failed to update the theme color..')
        }
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.loading = false
    },
    async getThemeColor() {
      this.loading = true
      try {
        this.currentColor = (await colorThemeRepository.getColor())
        this.color = this.currentColor.data.theme_color
        this.gradientColor1 = this.currentColor.data.gradientColor1
        this.gradientColor2 = this.currentColor.data.gradientColor2
        this.gradientDirection = this.currentColor.data.gradientDirection
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.loading = true
    },
    updateSCSSVariables() {
      const styles = document.createElement('style')
      styles.textContent = `
        :root {
          --gradientColor1: ${this.gradientColor1};
          --gradientColor2: ${this.gradientColor2};
          --gradientDirection: ${this.gradientDirection};
          --primary: ${this.gradientColor1};
        }
      `
      document.head.appendChild(styles)
      window.location.reload()
    },
  },
}
</script>

<style scoped lang="scss">
@import '../../assets/scss/variables/_variables.scss';
.random-color {
  box-shadow: 0 0 5px rgba(4, 4, 4, 0.25);
  height: 240px;
  margin-top: 28px;
  padding: 10px;
}
.display {
  height: 170px;
  border: 2px solid white;
  background: linear-gradient(var(--gradientDirection), var(--gradientColor1), var(--gradientColor2));
  box-shadow: 0 0 10px rgba(1, 1, 1, 0.35);
}
.inputColors {
  padding-top: 10px;
}
.gradientColor1, .gradientColor2 {
  width: 70px;
  height: 40px;
  margin-right: 10px;
}
.theme-tooltip {
  text-align: justify;
}
.label {
  font-size: 15px; /* Increase label font size */
}
</style>
