<template>
  <b-modal
    id="sms-settings-modal"
    ref="modal"
    v-model="open"
    size="lg"
    no-close-on-backdrop
    hide-footer
    centered
    title="SMS Settings"
  >
    <b-form @submit.prevent>
      <b-row>
        <b-col md="3">
          <b-form-group
            label-for="mc-sms-sms_service"
            label="SMS Service"
          >
            <v-select
              v-model="form.sms_service"
              label="name"
              :options="serviceOptions"
              :reduce="option => option.value"
              placeholder="Select SMS Service"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="3">
          <b-form-group
            label="URL"
            label-for="mc-sms-url"
          >
            <b-form-input
              id="mc-sms-url"
              v-model="form.url"
              placeholder="URL"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label="Send to paramter name"
            label-for="mc-sms-send_to_param_name"
          >
            <b-form-input
              id="mc-sms-send_to_param_name"
              v-model="form.send_to_param_name"
              placeholder="to"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label="Message parameter name"
            label-for="mc-sms-msg_param_name"
          >
            <b-form-input
              id="mc-sms-msg_param_name"
              v-model="form.msg_param_name"
              placeholder="text"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-for="mc-sms-request_method"
            label="Request Method"
          >
            <v-select
              v-model="form.request_method"
              label="name"
              :options="requestMethodOptions"
              :reduce="option => option.value"
              placeholder="Select request method"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <hr>
      <b-row>
        <b-col md="3">
          <b-form-group
            label="Header 1 key"
            label-for="mc-sms-header_1"
          >
            <b-form-input
              id="mc-sms-header_1"
              v-model="form.header_1"
              placeholder="Header 1 key"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label="Header 1 value"
            label-for="mc-sms-header_val_1"
          >
            <b-form-input
              id="mc-sms-header_val_1"
              v-model="form.header_val_1"
              placeholder="Header 1 value"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="3">
          <b-form-group
            label="Header 2 key"
            label-for="mc-sms-header_2"
          >
            <b-form-input
              id="mc-sms-header_2"
              v-model="form.header_2"
              placeholder="Header 2 key"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label="Header 2 value"
            label-for="mc-sms-header_val_2"
          >
            <b-form-input
              id="mc-sms-header_val_2"
              v-model="form.header_val_2"
              placeholder="Header 2 value"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="3">
          <b-form-group
            label="Header 3 key"
            label-for="mc-sms-header_3"
          >
            <b-form-input
              id="mc-sms-header_3"
              v-model="form.header_3"
              placeholder="Header 3 key"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label="Header 3 value"
            label-for="mc-sms-header_val_3"
          >
            <b-form-input
              id="mc-sms-header_val_3"
              v-model="form.header_val_3"
              placeholder="Header 3 value"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <hr>
      <b-row>
        <b-col md="3">
          <b-form-group
            label="Parameter 1 key"
            label-for="mc-sms-param_1"
          >
            <b-form-input
              id="mc-sms-param_1"
              v-model="form.param_1"
              placeholder="Parameter 1 key"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label="Parameter 1 value"
            label-for="mc-sms-param_val_1"
          >
            <b-form-input
              id="mc-sms-param_val_1"
              v-model="form.param_val_1"
              placeholder="Parameter 1 value"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="3">
          <b-form-group
            label="Parameter 2 key"
            label-for="mc-sms-param_2"
          >
            <b-form-input
              id="mc-sms-param_2"
              v-model="form.param_2"
              placeholder="Parameter 2 key"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label="Parameter 2 value"
            label-for="mc-sms-param_val_2"
          >
            <b-form-input
              id="mc-sms-param_val_2"
              v-model="form.param_val_2"
              placeholder="Parameter 2 value"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="3">
          <b-form-group
            label="Parameter 3 key"
            label-for="mc-sms-param_3"
          >
            <b-form-input
              id="mc-sms-param_3"
              v-model="form.param_3"
              placeholder="Parameter 3 key"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label="Parameter 3 value"
            label-for="mc-sms-param_val_3"
          >
            <b-form-input
              id="mc-sms-param_val_3"
              v-model="form.param_val_3"
              placeholder="Parameter 3 value"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="3">
          <b-form-group
            label="Parameter 4 key"
            label-for="mc-sms-param_4"
          >
            <b-form-input
              id="mc-sms-param_4"
              v-model="form.param_4"
              placeholder="Parameter 4 key"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label="Parameter 4 value"
            label-for="mc-sms-param_val_4"
          >
            <b-form-input
              id="mc-sms-param_val_4"
              v-model="form.param_val_4"
              placeholder="Parameter 4 value"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="3">
          <b-form-group
            label="Parameter 5 key"
            label-for="mc-sms-param_5"
          >
            <b-form-input
              id="mc-sms-param_5"
              v-model="form.param_5"
              placeholder="Parameter 5 key"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label="Parameter 5 value"
            label-for="mc-sms-param_val_5"
          >
            <b-form-input
              id="mc-sms-param_val_5"
              v-model="form.param_val_5"
              placeholder="Parameter 5 value"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="3">
          <b-form-group
            label="Parameter 6 key"
            label-for="mc-sms-param_6"
          >
            <b-form-input
              id="mc-sms-param_6"
              v-model="form.param_6"
              placeholder="Parameter 6 key"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label="Parameter 6 value"
            label-for="mc-sms-param_val_6"
          >
            <b-form-input
              id="mc-sms-param_val_6"
              v-model="form.param_val_6"
              placeholder="Parameter 6 value"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="3">
          <b-form-group
            label="Parameter 7 key"
            label-for="mc-sms-param_7"
          >
            <b-form-input
              id="mc-sms-param_7"
              v-model="form.param_7"
              placeholder="Parameter 7 key"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label="Parameter 7 value"
            label-for="mc-sms-param_val_7"
          >
            <b-form-input
              id="mc-sms-param_val_7"
              v-model="form.param_val_7"
              placeholder="Parameter 7 value"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="3">
          <b-form-group
            label="Parameter 8 key"
            label-for="mc-sms-param_8"
          >
            <b-form-input
              id="mc-sms-param_8"
              v-model="form.param_8"
              placeholder="Parameter 8 key"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label="Parameter 8 value"
            label-for="mc-sms-param_val_8"
          >
            <b-form-input
              id="mc-sms-param_val_8"
              v-model="form.param_val_8"
              placeholder="Parameter 8 value"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="3">
          <b-form-group
            label="Parameter 9 key"
            label-for="mc-sms-param_9"
          >
            <b-form-input
              id="mc-sms-param_9"
              v-model="form.param_9"
              placeholder="Parameter 9 key"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label="Parameter 9 value"
            label-for="mc-sms-param_val_9"
          >
            <b-form-input
              id="mc-sms-param_val_9"
              v-model="form.param_val_9"
              placeholder="Parameter 9 value"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="3">
          <b-form-group
            label="Parameter 10 key"
            label-for="mc-sms-param_10"
          >
            <b-form-input
              id="mc-sms-param_10"
              v-model="form.param_10"
              placeholder="Parameter 10 key"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label="Parameter 10 value"
            label-for="mc-sms-param_val_10"
          >
            <b-form-input
              id="mc-sms-param_val_10"
              v-model="form.param_val_10"
              placeholder="Parameter 10 value"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="d-flex justify-content-end">
        <b-form-input
          v-model="testPhone"
          placeholder="Test phone number"
          class="w-25 mr-1"
        />
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          type="submit"
          variant="success"
          class="mr-1"
          @click="sendTestSMS"
        >
          Send Test SMS
        </b-button>
        <b-button
          v-can="'business-profile-manage'"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          type="submit"
          variant="primary"
          class="mr-1"
          @click="setSMSConfig"
        >
          Submit
        </b-button>
      </b-row>
    </b-form>
  </b-modal>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BModal, BFormInput, BButton, VBModal,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import SuccessMessage from '@core/mixins/SuccessMessage'
import ErrorMessage from '@core/mixins/ErrorMessage'
import { RepositoryFactory } from '@/repository/RepositoryFactory'

const SMSSettingsRepository = RepositoryFactory.get('smsSetting')

function initialState() {
  return {
    open: false,
    form: {},
    serviceOptions: [
      { name: 'Other', value: 'other' },
    ],
    requestMethodOptions: [
      { name: 'GET', value: 'GET' },
      { name: 'POST', value: 'POST' },
    ],
    request_method: '',
    testPhone: '',
  }
}

export default {
  components: {
    BRow,
    BCol,
    BModal,
    BFormGroup,
    BFormInput,
    BButton,
    'v-select': vSelect,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  mixins: [SuccessMessage, ErrorMessage],
  data() {
    return initialState()
  },
  methods: {
    async openModal() {
      Object.assign(this.$data, initialState())
      this.open = true
      this.readSMSConfig()
    },
    async readSMSConfig() {
      try {
        const { data } = (await SMSSettingsRepository.getSMSConfigResource()).data
        this.form = data
      } catch (e) {
        this.convertAndNotifyError(e)
      }
    },
    async setSMSConfig() {
      try {
        const res = await SMSSettingsRepository.updateSMSConfigResource(this.form)
        if (res.status === 200) {
          this.showSuccessMessage('SMS Configuration Updated Successfully')
          this.open = false
        } else {
          this.showErrorMessage('Cannot Update SMS Config')
        }
      } catch (e) {
        this.convertAndNotifyError(e)
      }
    },
    async sendTestSMS() {
      try {
        const payload = {
          phone: this.testPhone,
        }
        const res = await SMSSettingsRepository.sendTestSMS(payload)
        if (res.status === 200) {
          this.showSuccessMessage('SMS Sent Successfully')
        } else {
          this.showErrorMessage('Cannot Send SMS')
        }
      } catch (e) {
        this.convertAndNotifyError(e)
      }
    },
  },
}
</script>

<style lang="scss">
@import '../../../@core/scss/vue/libs/vue-select.scss';
</style>
