<template>
  <b-card title="Update Order Settings">
    <b-overlay
      :show="loading"
      blur="10px"
      opacity="0.30"
      rounded="sm"
      variant="light"
    >
      <b-form
        @submit.prevent
        @submit="setOrderSetting"
      >
        <!-- <b-row>
          <b-card
            title="Waybill Setting"
            class="mb-0 w-100"
          >
            <b-row>
              <b-col md="3">
                <b-form-group
                  label="Auto Waybill Prefix"
                  label-for="auto-waybill-prefix"
                >
                  <b-form-input
                    id="auto-waybill-prefix"
                    v-model="form.waybill_setting.auto_waybill_prefix"
                    placeholder="Xx"
                    pattern="[A-Za-z]+"
                    title="Auto waybill prefix can only contain characters"
                    required
                    autocomplete="nope"
                  />
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group
                  label="Manual Waybill Prefix"
                  label-for="manual-waybill-prefix"
                >
                  <b-form-input
                    id="manual-waybill-prefix"
                    v-model="form.waybill_setting.manual_waybill_prefix"
                    placeholder="Xx"
                    pattern="[A-Za-z]+"
                    title="Prefix can only contain characters"
                    required
                    autocomplete="nope"
                  />
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group
                  label="Number of Digits"
                  label-for="digit-count"
                >
                  <b-form-input
                    id="digit-count"
                    v-model="form.waybill_setting.digit_count"
                    placeholder="7"
                    type="number"
                    required
                    autocomplete="nope"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-card>
        </b-row> -->
        <!-- <b-row>
          <div class="ml-2 mb-1">
            These settings specify the allowed waybill number format on your placed orders
          </div>
        </b-row> -->
        <!-- <b-row>
          <b-card
            title="Waybill Suffix"
            class="mb-0 mt-0"
          >
            <b-form-radio-group
              v-model="form.waybill_setting.suffix_enabled"
              :options="radioOptions"
              class="demo-inline-spacing mb-1"
              value-field="value"
              text-field="text"
              disabled-field="disabled"
            />
          </b-card>
        </b-row> -->
        <!-- <b-row class="">
          <b-card
            class="mb-0 w-100"
          >
            <b-row>
              <b-col md="3">
                <b-form-group
                  label="Auto Waybill Suffix"
                  label-for="auto-waybill-suffix"
                >
                  <b-form-input
                    id="auto-waybill-suffix"
                    v-model="form.waybill_setting.auto_waybill_suffix"
                    placeholder="Xx"
                    pattern="[A-Za-z]+"
                    title="Suffix can only contain characters"
                    :required="form.waybill_setting.suffix_enabled"
                    :disabled="!form.waybill_setting.suffix_enabled"
                    autocomplete="nope"
                  />
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group
                  label="Manual Waybill Suffix"
                  label-for="manual-waybill-suffix"
                >
                  <b-form-input
                    id="manual-waybill-suffix"
                    v-model="form.waybill_setting.manual_waybill_suffix"
                    placeholder="Xx"
                    pattern="[A-Za-z]+"
                    title="Suffix can only contain characters"
                    :required="form.waybill_setting.suffix_enabled"
                    :disabled="!form.waybill_setting.suffix_enabled"
                    autocomplete="nope"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-card>
        </b-row> -->
        <b-tabs>
          <b-tab title="Origin City Freeze">
            <!-- Origin City Freeze content -->
            <b-row>
              <b-card
                title="Origin City Freeze"
                class="mb-0 mt-0"
              >
                <b-form-radio-group
                  v-model="form.origin_city_freeze"
                  :options="radioOptions"
                  class="demo-inline-spacing mb-1"
                  value-field="value"
                  text-field="text"
                  disabled-field="disabled"
                />
              </b-card>
            </b-row>
            <b-row>
              <div class="ml-2">
                If this mode is ON, origin city cannot be selected on order create and will be set based on the merchant's default origin city
              </div>
            </b-row>
          </b-tab>
          <b-tab title="Same Day Reschedule">
            <!-- Same Day Reschedule content -->
            <b-row>
              <b-card
                title="Same Day Reschedule"
                class="mb-0 mt-0"
              >
                <b-form-radio-group
                  v-model="form.same_day_reschedule"
                  :options="radioOptions"
                  class="demo-inline-spacing mb-1"
                  value-field="value"
                  text-field="text"
                  disabled-field="disabled"
                />
              </b-card>
            </b-row>
            <b-row>
              <div class="ml-2">
                If this mode is ON, orders can be rescheduled to the same day
              </div>
            </b-row>
          </b-tab>
          <b-tab title="Destination Truck Driver">
            <!-- Destination Truck Driver content -->
            <b-row>
              <b-card
                title="Destination Truck Driver"
                class="mb-0 mt-0"
              >
                <b-form-radio-group
                  v-model="form.truck_driver_enabled"
                  :options="radioOptions"
                  class="demo-inline-spacing mb-3"
                  value-field="value"
                  text-field="text"
                  disabled-field="disabled"
                />
                <b-form-group
                  label="Truck Driver Role"
                >
                  <v-select
                    v-model="form.truck_driver_role"
                    :options="roleoptions"
                    :reduce="option => option.id"
                    label="name"
                    placeholder="Select Role"
                    :disabled="!form.truck_driver_enabled"
                  >
                    <template v-slot:option="option">
                      {{ option.name }}
                    </template>
                    <template #search="{attributes, events}">
                      <input
                        class="vs__search"
                        style="pointer-events:none"
                        :required="form.truck_driver_enabled && !form.truck_driver_role"
                        v-bind="attributes"
                        v-on="events"
                      >
                    </template>
                  </v-select>
                </b-form-group>
              </b-card>
            </b-row>
            <b-row>
              <div class="ml-2">
                If this option is enabled, a truck driver can be assigned to the order while dispatching from the origin to destination warehouses
              </div>
            </b-row>
          </b-tab>
          <b-tab title="Delivered By Pickup Up Rider">
            <!-- Delivered By Pickup Up Rider content -->
            <b-row>
              <b-card
                title="Delivered By Pickup Up Rider"
                class="mb-0 mt-0"
              >
                <b-form-radio-group
                  v-model="form.deliver_by_pickup_rider"
                  :options="radioOptions"
                  class="demo-inline-spacing mb-1"
                  value-field="value"
                  text-field="text"
                  disabled-field="disabled"
                />
              </b-card>
            </b-row>
            <b-row>
              <div class="ml-2">
                {{ $t('When this setting is enabled the rider can update the delivered by pickup rider status from the riders app') }}
              </div>
            </b-row>
          </b-tab>
          <b-tab title="Weight Range Setting">
            <b-row class="mt-0">
              <b-card
                title="Weight Range Setting"
                class="mb-0 mt-0"
              >
                <b-form-radio-group
                  v-model="form.weight_range_setting.enabled"
                  :options="radioOptions"
                  class="demo-inline-spacing mb-1"
                  value-field="value"
                  text-field="text"
                  disabled-field="disabled"
                />
              </b-card>
            </b-row>

            <b-row class="">
              <b-card class="mb-0 w-100">
                <b-row>
                  <b-col md="3">
                    <b-form-group
                      label="Minimum Weight (KG)"
                      label-for="min-weight"
                    >
                      <b-form-input
                        id="min-weight"
                        v-model="form.weight_range_setting.min_weight"
                        placeholder="1"
                        :required="form.weight_range_setting.enabled"
                        :disabled="!form.weight_range_setting.enabled"
                        pattern="[0-9]*[.]?[0-9]+"
                        title="Please enter only numbers and decimal values"
                        autocomplete="nope"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="3">
                    <b-form-group
                      label="Maximum Weight (KG)"
                      label-for="max-weight"
                    >
                      <b-form-input
                        id="max-weight"
                        v-model="form.weight_range_setting.max_weight"
                        placeholder="100"
                        :required="form.weight_range_setting.enabled"
                        :disabled="!form.weight_range_setting.enabled"
                        pattern="[0-9]*[.]?[0-9]+"
                        title="Please enter only numbers and decimal values"
                        autocomplete="nope"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
                <div
                  v-if="error"
                  class="text-danger"
                >{{ error }}</div>
              </b-card>
            </b-row>

            <b-row>
              <div class="ml-2">
                {{ $t('When this setting is enabled, maximum weight and minimum weight can be added for validations in order upload and operations.') }}
              </div>
            </b-row>
            <!-- End of: Weight Range -->
          </b-tab>
          <b-tab title="Second Phone Number Setting">
            <!-- Second Phone Number Setting content -->
            <b-row>
              <b-card
                title="Second Phone Number Setting"
                class="mb-0 mt-0"
              >
                <b-form-radio-group
                  v-model="form.second_phone_number"
                  :options="radioOptions"
                  class="demo-inline-spacing mb-3"
                  value-field="value"
                  text-field="text"
                  disabled-field="disabled"
                />
              </b-card>
            </b-row>
            <b-row>
              <div class="ml-2">
                When this setting is enabled the merchant can upload order with a second phone number.
              </div>
            </b-row>
          </b-tab>
        </b-tabs>
        <!-- submit -->
        <b-row>
          <b-col class="d-flex justify-content-end">
            <b-button
              v-can="'business-profile-manage'"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              class="mt-1 custom-button-color"
            >
              Submit
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-overlay>
  </b-card>
</template>

<script>
import {
  BRow, BCol, BForm, BFormGroup, BFormInput, BButton, BCard, BOverlay, BFormRadioGroup, BTabs, BTab,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import SuccessMessage from '@core/mixins/SuccessMessage'
import ErrorMessage from '@core/mixins/ErrorMessage'
import { RepositoryFactory } from '@/repository/RepositoryFactory'

const OrderSettingsRepository = RepositoryFactory.get('orderSetting')
const RoleRepository = RepositoryFactory.get('role')

export default {
  components: {
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    BCard,
    BOverlay,
    BFormRadioGroup,
    BTabs,
    BTab,
  },
  directives: {
    Ripple,
  },
  mixins: [SuccessMessage, ErrorMessage],
  data() {
    return {
      loading: false,
      form: {
        waybill_setting: {
          auto_waybill_prefix: null,
          manual_waybill_prefix: null,
          digit_count: null,
        },
        weight_range_setting: {
          min_weight: '',
          max_weight: '',
          enabled: true,
        },
      },
      radioOptions: [
        { text: 'On', value: true },
        { text: 'Off', value: false },
      ],
      roleoptions: [],
      error: '',
    }
  },
  watch: {
    // eslint-disable-next-line func-names
    'form.weight_range_setting.min_weight': function (newMinWeight) {
      this.validateWeights(newMinWeight, this.form.weight_range_setting.max_weight)
    },

    // eslint-disable-next-line func-names
    'form.weight_range_setting.max_weight': function (newMaxWeight) {
      this.validateWeights(this.form.weight_range_setting.min_weight, newMaxWeight)
    },
  },
  mounted() {
    this.readOrderSetting()
    this.readDataforRole()
  },
  methods: {
    async readOrderSetting() {
      this.loading = true
      try {
        const { data } = (await OrderSettingsRepository.getOrderSettingsResource()).data
        this.form = data
        if (localStorage.getItem('order_setting') !== JSON.stringify(data)) localStorage.setItem('order_setting', JSON.stringify(data))
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.loading = false
    },
    validateWeights(newMinWeight, newMaxWeight) {
      const minWeight = parseFloat(newMinWeight)
      const maxWeight = parseFloat(newMaxWeight)

      // eslint-disable-next-line no-restricted-globals
      if (!isNaN(minWeight) && !isNaN(maxWeight) && minWeight === maxWeight) {
        this.error = 'Minimum and maximum weight cannot be same'
      } else {
        this.error = ''
      }
    },
    async setOrderSetting() {
      this.loading = true
      try {
        const data = {
          waybill_setting: {
            auto_waybill_prefix: this.form.waybill_setting.auto_waybill_prefix,
            manual_waybill_prefix: this.form.waybill_setting.manual_waybill_prefix,
            digit_count: this.form.waybill_setting.digit_count,
            suffix_enabled: this.form.waybill_setting.suffix_enabled,
            auto_waybill_suffix: this.form.waybill_setting.auto_waybill_suffix,
            manual_waybill_suffix: this.form.waybill_setting.manual_waybill_suffix,
          },
          origin_city_freeze: this.form.origin_city_freeze,
          same_day_reschedule: this.form.same_day_reschedule,
          truck_driver_enabled: this.form.truck_driver_enabled,
          truck_driver_role: this.form.truck_driver_role,
          deliver_by_pickup_rider: this.form.deliver_by_pickup_rider,
          second_phone_number: this.form.second_phone_number,
          weight_range_setting: {
            enabled: this.form.weight_range_setting.enabled,
            max_weight: parseFloat(this.form.weight_range_setting.max_weight),
            min_weight: parseFloat(this.form.weight_range_setting.min_weight),
          },
        }
        if (localStorage.getItem('truck_driver_enabled')) {
          localStorage.removeItem('truck_driver_enabled')
        }
        const res = await OrderSettingsRepository.updateOrderSettingsResource(data)
        localStorage.setItem('truck_driver_enabled', this.form.truck_driver_enabled)
        if (res.status === 200) {
          this.showSuccessMessage('Order Settings Updated Successfully')
          localStorage.setItem('order_setting', JSON.stringify(data))
          localStorage.removeItem('driver_list')
        } else {
          this.showErrorMessage('Cannot Update Order Settings')
        }
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.loading = false
    },
    async readDataforRole() {
      try {
        const { data } = (await RoleRepository.getRoleListNoPagination()).data
        this.roleoptions = data
      } catch (e) {
        this.convertAndNotifyError(e)
      }
    },
  },
}
</script>
<style scoped>
.custom-button-color {
  background-color: #4186f5 !important;
  border-color: #4186f5 !important;
}
</style>
