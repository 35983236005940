<template>
  <div class="color-picker">
    <div class="input-group mb-2">
      <!-- <input
        v-model="currentColor"
        type="text"
        class="form-control"
        @click="showPicker"
      > -->
      <label
        for="colorPicker"
        class="label"
      >
        Pick a color
        <feather-icon
          v-b-tooltip.hover.v-primary="'Currently Displays the Selected Color'"
          icon="HelpCircleIcon"
          size="15"
          class="cursor-pointer text-primary mb-1 ml-1 mt-1"
        />
      </label>
      <span
        class="input-group-addon"
        :style="{ backgroundColor: color }"
        @click="showPicker"
      />
    </div>
    <ChromePicker
      v-if="displayPicker"
      v-model="color"
    />
    <Swatches
      v-model="color"
      :colors="swatches"
      @select="hidePicker"
    />
  </div>
</template>

<script>
import { Chrome, Swatches } from 'vue-color'
import Ripple from 'vue-ripple-directive'
import {
  VBTooltip,
} from 'bootstrap-vue'

export default {
  components: {
    ChromePicker: Chrome,
    Swatches,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  props: {
    value: {
      type: String,
    },
  },
  data() {
    return {
      color: this.value,
      displayPicker: false,
      swatches: [],
    }
  },
  computed: {
    colorHex() {
      return this.color.hex
    },
  },
  watch: {
    color(val) {
      this.$emit('input', val)
    },
    value(val) {
      this.color = val
    },
  },
  methods: {
    showPicker() {
      if (this.displayPicker) {
        this.hidePicker()
      } else {
        this.displayPicker = true
      }
    },
    hidePicker() {
      this.displayPicker = false
    },
  },
}
</script>

<style scoped>
.color-picker {
  position: relative;
}
.input-group {
  display: flex;
}
.input-group-addon {
  width: 38px;
  height: 38px;
  margin-top: 9px;
  margin-left: -1px;
  border: 5px solid #d6d5d5f6;
  border-radius: 2px 0 0 2px;
  cursor: pointer;
}
.vc-chrome {
  position: absolute;
  top: 35px;
  right: 0;
  z-index: 9;
}
.label {
  font-size: 15px; /* Increase label font size */
  margin-right: 1rem;
  margin-top: 0.5rem; /* Add margin below the label */
}
</style>
