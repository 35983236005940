<template>
  <b-row class="d-flex justify-content-end">
    <b-col
      md="4"
      class="my-1"
    >
      <b-input-group size="sm">
        <b-form-select
          id="sortBySelect"
          v-model="sortBy"
          :options="sortOptions"
          class="w-75"
        >
          <template v-slot:first>
            <option value="">
              -- Sort --
            </option>
          </template>
        </b-form-select>
        <b-form-select
          v-model="sortDesc"
          size="sm"
          :disabled="!sortBy"
          class="w-25"
        >
          <option :value="false">
            Asc
          </option>
          <option :value="true">
            Desc
          </option>
        </b-form-select>
      </b-input-group>
    </b-col>
    <b-col
      md="4"
      class="my-1"
    >
      <b-input-group size="sm">
        <b-form-input
          id="filterInput"
          v-model="filter"
          type="search"
          placeholder="Type to Search"
        />
        <b-input-group-append>
          <b-button
            :disabled="!filter"
            @click="filter = ''"
          >
            Clear
          </b-button>
        </b-input-group-append>
      </b-input-group>
    </b-col>

    <b-col cols="12">
      <b-table
        striped
        hover
        responsive
        show-empty
        :items="items"
        :fields="fields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        :filter="filter"
        :filter-included-fields="filterOn"
      >
        <template #empty>
          <TableDataFetching
            :rows="items"
            :data-loading="dataLoading"
          />
        </template>
        <template #cell(name)="data">
          <div class="d-flex align-items-start">
            <div>
              <div class="ml-2">
                {{ data.value }}
              </div>
            </div>
          </div>
        </template>
        <!--        Portal Notification Active-->
        <template #cell(is_push_notification_active)="data">
          <div v-if="data.value">
            <b-badge variant="light-success">
              Active
            </b-badge>
          </div>
          <div v-if="!data.value">
            <b-badge variant="light-danger">
              Inactive
            </b-badge>
          </div>
        </template>
        <template #cell(is_customer_sms_active)="data">
          <div v-if="data.value">
            <b-badge variant="light-success">
              Active
            </b-badge>
          </div>
          <div v-if="!data.value">
            <b-badge variant="light-danger">
              Inactive
            </b-badge>
          </div>
        </template>
        <template #cell(is_customer_email_active)="data">
          <div v-if="data.value">
            <b-badge variant="light-success">
              Active
            </b-badge>
          </div>
          <div v-if="!data.value">
            <b-badge variant="light-danger">
              Inactive
            </b-badge>
          </div>
        </template>
        <template #cell(is_merchant_sms_active)="data">
          <div v-if="data.value">
            <b-badge variant="light-success">
              Active
            </b-badge>
          </div>
          <div v-if="!data.value">
            <b-badge variant="light-danger">
              Inactive
            </b-badge>
          </div>
        </template>
        <template #cell(is_merchant_email_active)="data">
          <div v-if="data.value">
            <b-badge variant="light-success">
              Active
            </b-badge>
          </div>
          <div v-if="!data.value">
            <b-badge variant="light-danger">
              Inactive
            </b-badge>
          </div>
        </template>

        <template #cell(is_rider_sms_active)="data">
          <div v-if="data.value">
            <b-badge variant="light-success">
              Active
            </b-badge>
          </div>
          <div v-if="!data.value">
            <b-badge variant="light-danger">
              Inactive
            </b-badge>
          </div>
        </template>

        <template #cell(is_rider_email_active)="data">
          <div v-if="data.value">
            <b-badge variant="light-success">
              Active
            </b-badge>
          </div>
          <div v-if="!data.value">
            <b-badge variant="light-danger">
              Inactive
            </b-badge>
          </div>
        </template>
        <template #cell(is_reverse_status_active)="data">
          <div v-if="data.value">
            <b-badge variant="light-success">
              Active
            </b-badge>
          </div>
          <div v-if="!data.value">
            <b-badge variant="light-danger">
              Inactive
            </b-badge>
          </div>
        </template>
        <!--        Edit Action-->
        <template #cell(Action)="data">
          <b-button
            v-can="'edit-status-notification'"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            class="btn-icon rounded-circle"
            @click="statusAction(data.item)"
          >
            <feather-icon icon="Edit3Icon" />
          </b-button>
        </template>
      </b-table>

      <b-modal
        id="modal-edit"
        ref="modal"
        size="ov-custom"
        hide-footer
        title="Status Notifications"
      >
        <b-overlay
          :show="modalLoading"
          blur="10px"
          opacity="0.30"
          rounded="sm"
          variant="light"
        >
          <ValidationObserver
            ref="statusNotificationForm"
            v-slot="{ handleSubmit }"
            slim
          >
            <b-form @submit.prevent="handleSubmit(handleStatusSubmit)">
              <b-row>
                <b-col md="6">
                  <b-form-group
                    label="Status"
                    label-for="mc-first-name"
                  >
                    <b-form-input
                      id="mc-first-name"
                      v-model="form.name"
                      disabled
                    />
                  </b-form-group>
                </b-col>
                <b-col md="6" />
                <b-col md="6">
                  <b-form-group
                    label="Notification Body"
                  >
                    <b-form-textarea
                      id="push_notification_body"
                      ref="email_content"
                      v-model="form.push_notification_body"
                      placeholder="Write Email Content"
                      rows="3"
                      :state="form.push_notification_body.length <= emailBodyMaxChar"
                      class="char-textarea"
                      :class="form.push_notification_body.length >= emailBodyMaxChar ? 'text-danger' : ''"
                      :maxlength="emailBodyMaxChar"
                      no-resize
                      :required="form.is_push_notification_active"
                      @focus="handleOnFocus"
                    />
                    <small
                      class="textarea-counter-value float-right"
                      :class="form.push_notification_body.length >= emailBodyMaxChar ? 'bg-danger' : ''"
                    >
                      <span class="char-count">{{ form.push_notification_body.length }}</span> / {{ emailBodyMaxChar }}
                    </small>
                  </b-form-group>
                </b-col>
                <b-col md="6" />
                <b-col md="6">
                  <!--              email subject-->
                  <b-form-group
                    label="Email Subject"
                  />
                  <b-form-input
                    id="subject"
                    v-model="form.subject"
                    placeholder="Enter subject"
                    class="mb-1"
                    :required="form.is_email_active"
                    @focus="handleOnFocus"
                  />
                </b-col>
                <b-col md="6" />
                <b-col md="6">
                  <b-form-group
                    label="Email Body"
                  >
                    <b-form-textarea
                      id="email_body"
                      ref="email_content"
                      v-model="form.email_body"
                      placeholder="Write Email Content"
                      rows="3"
                      :state="form.email_body.length <= emailBodyMaxChar"
                      class="char-textarea"
                      :class="form.email_body.length >= emailBodyMaxChar ? 'text-danger' : ''"
                      :maxlength="emailBodyMaxChar"
                      no-resize
                      :required="form.is_email_active"
                      @focus="handleOnFocus"
                    />
                    <small
                      class="textarea-counter-value float-right"
                      :class="form.email_body.length >= emailBodyMaxChar ? 'bg-danger' : ''"
                    >
                      <span class="char-count">{{ form.email_body.length }}</span> / {{ emailBodyMaxChar }}
                    </small>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="SMS Content"
                  >
                    <b-form-textarea
                      id="sms_body"
                      ref="sms_content"
                      v-model="form.sms_body"
                      placeholder="Write SMS Content"
                      rows="3"
                      :state="form.sms_body.length <= smsBodyMaxChar"
                      class="char-textarea"
                      :class="form.sms_body.length >= smsBodyMaxChar ? 'text-danger' : ''"
                      :maxlength="smsBodyMaxChar"
                      no-resize
                      :required="form.is_sms_active"
                      @focus="handleOnFocus"
                    />
                    <small
                      class="textarea-counter-value float-right"
                      :class="form.sms_body.length >= smsBodyMaxChar ? 'bg-danger' : ''"
                    >
                      <span class="char-count">{{ form.sms_body.length }}</span> / {{ smsBodyMaxChar }}
                    </small>
                    <feather-icon
                      v-b-tooltip.hover.v-primary="'Click to view user guide'"
                      icon="AlertCircleIcon"
                      size="15"
                      class="cursor-pointer text-primary"
                    />
                    <small class="textarea-counter-massage-tooltip">
                      The maximum character count for sms content is 160. If the character count <br>
                      exceeds the max count the sms charge will be doubled, but the sms will be sent.
                    </small>
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <b-form-group
                    label="Available Tags"
                  >
                    <b-button-group
                      v-for="(tag, index) in tagOptions"
                      :key="index"
                      size="sm"
                    >
                      <b-button
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        variant="outline-primary"
                        class="rounded-0"
                        @click="addTag(`{${tag.value}}`)"
                      >
                        {{ tag.text }}
                      </b-button>
                    </b-button-group>
                  </b-form-group>
                </b-col>
                <b-col
                  md="12"
                  style="margin-top:5px;"
                >
                  <b-form-checkbox
                    v-model="form.is_push_notification_active"
                    :value="true"
                    class="custom-control-secondary mb-1"
                  >
                    Portal Notification
                  </b-form-checkbox>
                  <b-form-group
                    v-any="['active-status-notification', 'deactivate-status-notification']"
                    label="Customer Options"
                  >
                    <b-form-checkbox
                      v-model="form.is_customer_sms_active"
                      :value="true"
                      class="custom-control-secondary"
                    >
                      Customer SMS
                    </b-form-checkbox>
                    <b-form-checkbox
                      v-model="form.is_customer_email_active"
                      :value="true"
                      class="custom-control-secondary"
                    >
                      Customer Email
                    </b-form-checkbox>
                  </b-form-group>
                  <b-form-group
                    v-any="['active-status-notification', 'deactivate-status-notification']"
                    label="Merchant Options"
                  >
                    <b-form-checkbox
                      v-model="form.is_merchant_sms_active"
                      :value="true"
                      class="custom-control-secondary"
                    >
                      Merchant SMS
                    </b-form-checkbox>
                    <b-form-checkbox
                      v-model="form.is_merchant_email_active"
                      :value="true"
                      class="custom-control-secondary"
                    >
                      Merchant Email
                    </b-form-checkbox>
                  </b-form-group>
                  <b-form-group
                    v-if="form.key === 'key_5' || form.key === 'key_12'"
                    v-any="['active-status-notification', 'deactivate-status-notification']"
                    label="Rider Options"
                  >
                    <b-form-checkbox
                      v-if="form.key === 'key_5' || form.key === 'key_12'"
                      v-model="form.is_rider_sms_active"
                      :value="true"
                      class="custom-control-secondary"
                    >
                      Rider SMS
                    </b-form-checkbox>
                    <b-form-checkbox
                      v-if="form.key === 'key_5' || form.key === 'key_12'"
                      v-model="form.is_rider_email_active"
                      :value="true"
                      class="custom-control-secondary"
                    >
                      Rider Email
                    </b-form-checkbox>
                  </b-form-group>
                  <b-form-checkbox
                    v-model="form.is_reverse_status_active"
                    :value="true"
                    class="custom-control-secondary mb-1"
                  >
                    Reversal Notification
                  </b-form-checkbox>
                </b-col>
                <!-- submit and reset -->
                <b-col class="d-flex justify-content-end">
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    type="submit"
                    variant="primary"
                    class="mr-1"
                  >
                    Submit
                  </b-button>
                  <b-button
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    variant="outline-secondary"
                    @click="reset()"
                  >
                    Reset
                  </b-button>
                </b-col>
              </b-row>
            </b-form>
          </ValidationObserver>
        </b-overlay>
      </b-modal>

    </b-col>
  </b-row>
</template>

<script>
import {
  BTable,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BForm,
  BBadge,
  BFormCheckbox,
  BFormTextarea,
  BButtonGroup,
  BOverlay,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import SuccessMessage from '@core/mixins/SuccessMessage'
import ErrorMessage from '@core/mixins/ErrorMessage'
// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'
import { RepositoryFactory } from '@/repository/RepositoryFactory'

const NotificationSettingRepository = RepositoryFactory.get('notificationSetting')

export default {
  components: {
    BTable,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BForm,
    BBadge,
    BFormCheckbox,
    BFormTextarea,
    BButtonGroup,
    BOverlay,
  },
  directives: {
    Ripple,
  },
  mixins: [SuccessMessage, ErrorMessage],
  data() {
    return {
      smsBodyMaxChar: 200,
      emailBodyMaxChar: 200,
      tagOptions: [
        { text: 'Waybill Number', value: 'waybill_number' },
        { text: 'Customer Name', value: 'customer_name' },
        { text: 'Client Name', value: 'client_name' },
        { text: 'COD', value: 'cod' },
        { text: 'Delivery Charge', value: 'delivery_charge' },
        { text: 'Client Contact Number', value: 'client_number' },
        { text: 'Customer Contact Number', value: 'customer_number' },
        { text: 'Customer Address', value: 'customer_address' },
        { text: 'Origin City', value: 'origin_city' },
        { text: 'Origin Warehouse', value: 'origin_warehouse' },
        { text: 'Destination City', value: 'destination_city' },
        { text: 'Destination Warehouse', value: 'destination_warehouse' },
        { text: 'Destination Warehouse Contact', value: 'destination_warehouse_contact' },
        { text: 'Status Reason', value: 'status_reason' },
        { text: 'Latest Remark', value: 'latest_remark' },
        { text: 'Latest Rider', value: 'latest_rider' },
        { text: 'Customer Feedback Link', value: 'feedback_url' },
      ],
      sortBy: '',
      sortDesc: false,
      modalLoading: false,
      dataLoading: true,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      fields: [
        { key: 'name', label: 'Status', sortable: true },
        { key: 'is_push_notification_active', label: 'Portal Notification', sortable: false },
        { key: 'is_customer_sms_active', label: 'Active Customer SMS', sortable: false },
        { key: 'is_customer_email_active', label: 'Active Customer Email', sortable: false },
        { key: 'is_merchant_sms_active', label: 'Active Merchant SMS', sortable: false },
        { key: 'is_merchant_email_active', label: 'Active Merchant Email', sortable: false },
        { key: 'is_rider_sms_active', label: 'Active Rider SMS', sortable: false },
        { key: 'is_rider_email_active', label: 'Active Rider Email', sortable: false },
        { key: 'is_reverse_status_active', label: 'Reversal Notification', sortable: false },
        { key: 'Action', label: 'Action', sortable: false },
      ],
      items: [],
      form: {},
      selectedTextfieldId: '',
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  watch: {
    options: {
      handler() {
        this.fetchDataForStatusNotification()
      },
    },
  },
  mounted() {
    this.fetchDataForStatusNotification()
  },
  methods: {
    async fetchDataForStatusNotification() {
      try {
        const { data } = (await NotificationSettingRepository.getStatusNotificationResource())
        this.items = data.data
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.dataLoading = false
    },
    reset() {
      this.form.sms_body = ''
      this.form.email_body = ''
      this.form.subject = ''
      this.form.push_notification_body = ''
      this.form.is_customer_sms_active = false
      this.form.is_customer_email_active = false
      this.form.is_merchant_sms_active = false
      this.form.is_merchant_email_active = false
      this.form.is_rider_sms_active = false
      this.form.is_rider_email_active = false
      this.form.is_push_notification_active = false
      this.form.is_reverse_status_active = false
    },
    handleStatusSubmit() {
      this.modalLoading = true
      const payload = {
        key: this.form.key,
        is_customer_sms_active: this.form.is_customer_sms_active,
        is_customer_email_active: this.form.is_customer_email_active,
        is_merchant_sms_active: this.form.is_merchant_sms_active,
        is_merchant_email_active: this.form.is_merchant_email_active,
        is_rider_sms_active: this.form.is_rider_sms_active,
        is_rider_email_active: this.form.is_rider_email_active,
        is_push_notification_active: this.form.is_push_notification_active,
        is_reverse_status_active: this.form.is_reverse_status_active,
        sms_body: this.form.sms_body,
        email_body: this.form.email_body,
        subject: this.form.subject,
        push_notification_body: this.form.push_notification_body,
      }
      this.commitPayload(payload)
    },
    async commitPayload(payload) {
      try {
        const res = (await NotificationSettingRepository.updateStatusNotificationResource(payload))
        if (res.status === 200) {
          this.showSuccessMessage('Data Updated Successfully')
          this.fetchDataForStatusNotification()
          this.$bvModal.hide('modal-edit')
        } else {
          this.showErrorMessage('Data Update Failed')
        }
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.modalLoading = false
      await this.window.reload()
    },
    addTag(tag) {
      if (tag !== false) {
        const field = document.getElementById(this.selectedTextfieldId)
        this.form[this.selectedTextfieldId] = this.form[this.selectedTextfieldId] || ''
        this.form[this.selectedTextfieldId] = `${this.form[this.selectedTextfieldId].substring(0, field.selectionStart)} ${tag} ${this.form[this.selectedTextfieldId].substring(field.selectionStart)}`
        field.focus()
      }
    },
    handleOnFocus(e) {
      this.selectedTextfieldId = e.target.id
    },
    statusAction(data) {
      this.form = { ...data }
      this.form.email_body = this.form.email_body || ''
      this.form.sms_body = this.form.sms_body || ''
      this.form.push_notification_body = this.form.push_notification_body || ''
      this.$bvModal.show('modal-edit')
    },
  },
}
</script>
