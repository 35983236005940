<template>
  <div>
    <b-card
      no-body
    >
      <b-tabs
        ref="notificationTabBar"
      >
        <b-tab>
          <template #title>
            <span>Merchant Notification</span>
          </template>
          <ClientNotificationForm />
        </b-tab>
        <b-tab>
          <template #title>
            <span>Status Notification</span>&nbsp;
          </template>
          <StatusNotificationTable />
        </b-tab>
        <b-tab>
          <template #title>
            <span>Reminder Notification</span>
          </template>
          <ReminderNotification />
        </b-tab>
        <b-tab>
          <template #title>
            <span>Finance Notification</span>&nbsp;
          </template>

          <FinanceNotification />

        </b-tab>
        <b-tab>
          <template #title>
            <span>Banner Notification</span>
          </template>
          <BannerNotification />
        </b-tab>
        <b-tab>
          <template #title>
            <span>Other Notification</span>&nbsp;
          </template>

          <OtherNotification />

        </b-tab>
      </b-tabs>
      <b-dropdown
        variant="link"
        toggle-class="text-decoration-none"
        class="position-absolute top-0"
        style="right:0px;"
        right
        no-caret
      >
        <template v-slot:button-content>
          <feather-icon
            icon="MoreVerticalIcon"
            size="20"
            class="text-body align-middle"
          />
        </template>
        <b-dropdown-item
          @click="onClickConfigureEmail()"
        >
          Configure Email
        </b-dropdown-item>
        <b-dropdown-item
          @click="onClickConfigureSMS()"
        >
          Configure SMS
        </b-dropdown-item>
      </b-dropdown>
    </b-card>
    <email-setting ref="email_setting" />
    <SMS-setting ref="sms_setting" />
  </div>
</template>

<script>
import {
  BTabs,
  BTab,
  BCard,
  BDropdown,
  BDropdownItem,
} from 'bootstrap-vue'
import StatusNotificationTable from '@/components/Notification/StatusNotificationTable.vue'
import ClientNotificationForm from '@/components/Notification/ClientNotificationForm.vue'
import ReminderNotification from '@/components/Notification/ReminderNotification.vue'
import FinanceNotification from '@/components/Notification/FinanceNotification.vue'
import BannerNotification from '@/components/Notification/BannerNotificationForm.vue'
// eslint-disable-next-line import/extensions
import OtherNotification from '@/components/Notification/OtherNotification'
import EmailSetting from '@/components/setting/configuration/EmailSetting.vue'
import SMSSetting from '@/components/setting/configuration/SMSSetting.vue'

export default {
  components: {
    ReminderNotification,
    ClientNotificationForm,
    StatusNotificationTable,
    FinanceNotification,
    BannerNotification,
    OtherNotification,
    EmailSetting,
    SMSSetting,
    BTabs,
    BTab,
    BCard,
    BDropdown,
    BDropdownItem,
  },
  mounted() {
    // eslint-disable-next-line no-underscore-dangle
    this.__preserveTabOnRefresh('notificationTabBar', 'notificationTabIndex')
  },
  methods: {
    onClickConfigureEmail() {
      this.$refs.email_setting.openModal()
    },
    onClickConfigureSMS() {
      this.$refs.sms_setting.openModal()
    },
  },
}
</script>
