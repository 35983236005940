<template>
  <b-card title="Update Print Settings">
    <b-row>
      <b-card
        title="Waybill Layout"
      >
        <b-row>
          <b-col
            md="12"
          >
            <b-overlay
              :opacity="0.5"
              :show="loading"
              rounded="sm"
              blur="10px"
            >
              <b-table
                striped
                hover
                responsive
                :items="items"
                :fields="fields"
              >
                <template #cell(added_date)="data">
                  <div class="d-flex align-items-start">
                    <div>
                      <div class="ml-2">
                        {{ data.value }}
                      </div>
                    </div>
                  </div>
                </template>
                <template #cell(name)="data">
                  <div class="d-flex align-items-start">
                    <div>
                      <div class="ml-2">
                        {{ data.value }}
                      </div>
                    </div>
                  </div>
                </template>
                <template #cell(action)="data">
                  <div class="d-flex align-items-center">
                    <b-button
                      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                      variant="success"
                      class="mt-0"
                      block
                      :to="{ name: 'waybill-customizer', params: { id: data.item.id } }"
                    >
                      Customize
                    </b-button>
                    <b-button
                      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                      :variant="data.item.status == 1 ? 'danger' : 'success'"
                      class="m-0 ml-1"
                      block
                      @click="changeLayoutStatus(data.item.id)"
                    >
                      {{ data.item.status ? 'Deactivate' : 'Activate' }}
                    </b-button>
                  </div>
                </template>
              </b-table>
            </b-overlay>
          </b-col>
        </b-row>
      </b-card>
    </b-row>
    <b-row>
      <div class="ml-2">
        This customzer will help you define your own fixed format for printed waybills
      </div>
    </b-row>
  </b-card>
</template>

<script>
import {
  BRow, BButton, BCard, BCol, BTable, BOverlay,
} from 'bootstrap-vue'
import SuccessMessage from '@core/mixins/SuccessMessage'
import ErrorMessage from '@core/mixins/ErrorMessage'
import { RepositoryFactory } from '@/repository/RepositoryFactory'

const LayoutRepository = RepositoryFactory.get('layout')

export default {
  components: {
    BRow,
    BButton,
    BCard,
    BCol,
    BTable,
    BOverlay,
  },
  mixins: [SuccessMessage, ErrorMessage],
  data() {
    return {
      fields: [
        {
          key: 'added_date',
          label: 'Added Date',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        { key: 'name', label: 'Waybill Name', sortable: false },
        {
          key: 'Action',
          label: 'Action',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
      ],
      items: [],
      loading: false,
    }
  },
  async mounted() {
    await this.getWaybillLayout()
  },
  methods: {
    async getWaybillLayout() {
      try {
        this.loading = true
        const { data } = (await LayoutRepository.getWaybillLayout())
        this.items = data.data.waybills
      } catch (e) {
        this.showErrorMessage('Couldn\'t fetch waybill layout!')
      }
      this.loading = false
    },
    async changeLayoutStatus(id) {
      try {
        const payload = {
          layoutId: id,
        }
        this.loading = true
        const res = (await LayoutRepository.updateWaybillLayoutStatus(payload))
        if (res.status === 200) {
          this.getWaybillLayout()
          this.showSuccessMessage('Waybill Layout Updated Successfully!')
        } else {
          this.showErrorMessage('Couldn\'t update waybill layout!')
        }
      } catch (e) {
        this.showErrorMessage('Couldn\'t update waybill layout!')
      }
      this.loading = false
    },
  },
}
</script>
