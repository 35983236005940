import { Vue2TinymceEditor } from 'vue2-tinymce-editor'

export default {
  components: {
    Vue2TinymceEditor,
  },

  data() {
    return {
      content: '<h1>Some initial content</h1>',
    }
  },
}
