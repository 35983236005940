<template>
  <div>
    <b-card
      title="Update Waybill Management Settings"
      class="custom-card-header"
    />
    <b-card-actions
      title="Filters"
      class="waybill-series-filter"
      action-collapse
      collapsed
    >
      <b-row>
        <b-col md="3">
          <b-form-group
            label="Name"
            label-for="name"
          >
            <b-form-input
              id="name"
              v-model="filters['series_name']"
              placeholder="Search"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label="Prefix"
            label-for="prefix"
          >
            <b-form-input
              id="prefix"
              v-model="filters['prefix']"
              placeholder="Search"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label="Suffix"
            label-for="suffix"
          >
            <b-form-input
              id="suffix"
              v-model="filters['suffix']"
              placeholder="Search"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label="Status"
            label-for="status"
          >
            <v-select
              id="status"
              v-model="filters['status']"
              :options="statusOptions"
              :reduce="option => option.status"
              label="name"
              autocomplete="nope"
              placeholder="Select Status"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <!-- Apply and Clear Filters -->
      <b-row class="mt-1">
        <b-col>
          <filter-buttons
            :filter-data="filters"
            :apply-filters="applyFilters"
            :clear-filters="clearFilters"
          />
        </b-col>
      </b-row>
    </b-card-actions>
    <b-card
      no-body
      class="mt-2"
    >
      <!-- Waybill Series List Card -->
      <div>
        <b-row>
          <b-col
            cols="12"
            md="8"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0 mt-1 ml-1"
          >
            <b-button
              v-if="store.getters.permissions.filter(permission => permission === 'edit-waybill-setting').length > 0"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              class="custom-button-color add-new-button"
              @click="onClickOpenAddNewDetailModal"
            >
              <feather-icon icon="PlusIcon" />
              Add New
            </b-button>
          </b-col>
          <b-col
            class="d-flex align-items-center justify-content-end mb-1 mb-md-0  mt-1 mr-1"
          >
            <ReportTableButtons
              :items="rows"
              :json_fieldz="json_fields"
              :name="`WaybillSeries - Page ${page}.xls`"
              :bulkname="`WaybillSeries.xls`"
              :fetch="getWaybillSeriesListNoPagination"
              @refresh="onClickRefresh"
            />
          </b-col>
        </b-row>
      </div>
      <b-overlay
        id="overlay-background"
        class="mt-2"
        blur="10px"
        opacity="0.30"
        rounded="sm"
        variant="light"
      >
        <b-table
          hover
          responsive
          striped
          show-empty
          :per-page="perPage"
          :items="rows"
          :fields="fields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          sticky-header="100vh"
          class="hide-vertical-scroll"
        >
          <template #empty>
            <TableDataFetching
              :rows="rows"
              :data-loading="dataLoading"
            />
          </template>
          <template #cell(number_of_digits)="data">
            <div class="content-body-cell">
              {{ `${data.value >= 10 ? '' : 0}${data.value}` }}
            </div>
          </template>
          <template #cell(is_auto)="data">
            <div v-if="data.value === true">
              <b-badge variant="light-primary">
                Auto
              </b-badge>
            </div>
            <div v-if="data.value === false">
              <b-badge variant="light-warning">
                Manual
              </b-badge>
            </div>
          </template>
          <template #cell(status)="data">
            <div v-if="data.value === 'pending'">
              <b-badge variant="light-warning">
                Pending
              </b-badge>
            </div>
            <div v-if="data.value === 'active'">
              <b-badge variant="light-success">
                Active
              </b-badge>
            </div>
            <div v-if="data.value === 'inactive'">
              <b-badge variant="light-danger">
                Inactive
              </b-badge>
            </div>
          </template>
          <template #cell(action)="data">
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              :class="rows.length !== 1 ? 'dropdown-no-scroll':''"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="12"
                  class="text-body align-middle mr-25"
                />
              </template>
              <b-dropdown-item
                v-if="data.item.status === 'pending'"
                @click="onClickEditWaybillSeries(data.item.id)"
              >
                <feather-icon icon="EditIcon" />
                Edit
              </b-dropdown-item>
              <b-dropdown-item
                @click="onClickStatusUpdate(data.item.id , data.item.status === 'pending' || data.item.status === 'inactive' ? 'active' : 'inactive')"
              >
                <feather-icon icon="ToolIcon" />
                {{ data.item.status === 'pending' || data.item.status === 'inactive' ? 'Activate' : 'Deactivate' }}
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </b-table>
      </b-overlay>
      <div class="mx-2 mb-2">
        <b-row
          cols="12"
        >
          <b-col
            class="d-flex align-items-center justify-content-sm-start"
            md="3"
          >
            <label>Per page</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="pageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block ml-1 width-100 mr-1"
            />
          </b-col>
          <b-col
            md="9"
            class="d-flex align-items-center justify-content-end"
          >
            <span class="text-muted mr-1">Showing {{ meta.from }} to {{ meta.to }} of {{ meta.total }} entries</span>
            <b-pagination
              v-model="page"
              :per-page="perPage"
              :total-rows="(meta.total)? meta.total : 0"
              class="mb-0 mt-1 mt-sm-0"
              first-number
              last-number
              next-class="next-item"
              prev-class="prev-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <add-new-detail-modal ref="add_new_detail_modal" />
    <update-waybill-series-modal ref="edit_waybill_series_modal" />
  </div>
</template>

<script>
import {
  BOverlay,
  BTable,
  BButton,
  BRow,
  BCard,
  BCol,
  BFormGroup,
  BFormInput,
  BDropdown,
  BDropdownItem,
  BPagination,
  BBadge,
} from 'bootstrap-vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import SuccessMessage from '@core/mixins/SuccessMessage'
import ErrorMessage from '@core/mixins/ErrorMessage'
import { RepositoryFactory } from '@/repository/RepositoryFactory'
import FilterButtons from '@/components/Filter/FilterButtons.vue'
import Filter from '@core/mixins/filter'
import AddNewDetailModal from '@/components/waybillManagement/AddNewDetailModal.vue'
import UpdateWaybillSeriesModal from '@/components/waybillManagement/UpdateWaybillSeriesModal.vue'

const waybillSettingsRepository = RepositoryFactory.get('waybillSetting')

export default {
  components: {
    BOverlay,
    BTable,
    BButton,
    BCard,
    BCardActions,
    BCol,
    BFormGroup,
    BFormInput,
    FilterButtons,
    BRow,
    BDropdown,
    BDropdownItem,
    BPagination,
    AddNewDetailModal,
    UpdateWaybillSeriesModal,
    BBadge,
  },
  directives: {
    Ripple,
  },
  mixins: [SuccessMessage, ErrorMessage, Filter],
  data() {
    return {
      store,
      dataLoading: false,
      form: {
        waybill_setting: {
          auto_waybill_prefix: null,
          manual_waybill_prefix: null,
          digit_count: null,
        },
      },
      radioOptions: [
        { text: 'On', value: true },
        { text: 'Off', value: false },
      ],
      roleoptions: [],
      sort: '',
      meta: {},
      perPage: 20,
      pageOptions: [10, 20, 50],
      totalRows: 1,
      page: 1,
      filters: {},
      statusOptions: [
        { name: 'Pending', status: 'pending' },
        { name: 'Active', status: 'active' },
        { name: 'Inactive', status: 'inactive' },
      ],
      filterOn: [],
      fields: [
        {
          key: 'series_name', label: 'Name', sortable: true, thClass: ' text-left', tdClass: ' text-left',
        },
        {
          key: 'prefix', label: 'Prefix', sortable: true, thClass: ' text-left', tdClass: ' text-left',
        },
        {
          key: 'start_from', label: 'Start From', sortable: true, thClass: ' text-left', tdClass: ' text-left',
        },
        {
          key: 'suffix', label: 'Suffix', sortable: true, thClass: ' text-left', tdClass: ' text-left',
        },
        {
          key: 'number_of_digits', label: 'Number of Digits', sortable: true, thClass: ' text-right', tdClass: ' text-right',
        },
        {
          key: 'is_auto', label: 'Waybill Method', sortable: true, thClass: ' text-center', tdClass: ' text-center',
        },
        {
          key: 'preview', label: 'Preview', sortable: true, thClass: ' text-left', tdClass: ' text-left',
        },
        {
          key: 'customer_type.display_name', label: 'Customer Type', sortable: true, thClass: ' text-left', tdClass: ' text-left',
        },
        {
          key: 'status', label: 'Status', sortable: true, thClass: ' text-center', tdClass: ' text-center',
        },
        store.getters.permissions.filter(permission => permission === 'edit-waybill-setting').length > 0 ? {
          key: 'action', label: 'Action', sortable: false, thClass: ' text-center', tdClass: ' text-center',
        } : {},
      ],
      json_fields: {
        Name: 'series_name',
        Prefix: 'prefix',
        'Start From': 'start_from',
        Suffix: 'suffix',
        'Number Of Digits': 'number_of_digits',
        'Waybill Method': {
          field: 'is_auto',
          callback: value => `"${value === true ? 'Auto' : 'Manual'}"`,
        },
        Preview: 'preview',
        Status: 'status',
      },
      rows: [],
      items: [],
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  watch: {
    page() {
      this.getWaybillSeriesList()
    },
    perPage() {
      this.getWaybillSeriesList()
    },
  },
  mounted() {
    this.getWaybillSeriesList()
  },
  methods: {
    onClickOpenAddNewDetailModal() {
      this.$refs.add_new_detail_modal.openModal()
    },
    async getWaybillSeriesList() {
      this.dataLoading = true
      this.rows = []
      try {
        // eslint-disable-next-line no-underscore-dangle
        this.filterQuery = this.__refineDateQuery(this.filterQuery, [this.filters])
        const { data } = (await waybillSettingsRepository.getWaybillSeriesList(this.page, this.filterQuery, this.perPage))
        this.rows = data.data
        this.meta = data.meta
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.dataLoading = false
    },
    async getWaybillSeriesListNoPagination() {
      try {
        // eslint-disable-next-line no-underscore-dangle
        this.filterQuery = this.__refineDateQuery(this.filterQuery, [this.filters])
        const { data } = (await waybillSettingsRepository.getWaybillSeriesListNoPagination(this.filterQuery))
        return data.data
      } catch (e) {
        this.convertAndNotifyError(e)
        return []
      }
    },
    async onClickStatusUpdate(id, newStatus) {
      try {
        const payload = {
          status: newStatus,
        }
        const res = await waybillSettingsRepository.updateWaybillSeriesStatus(id, payload)
        if (res.status === 200) {
          this.showSuccessMessage(`Waybill Status ${newStatus === 'active' ? 'Activated' : 'Deactivated'} Successfully`)
          this.getWaybillSeriesList()
        } else {
          this.showErrorMessage(`Couldn't ${newStatus ? 'Activate' : 'Deactivate'} Waybill Status`)
        }
      } catch (e) {
        this.convertAndNotifyError(e)
      }
    },
    onClickEditWaybillSeries(id) {
      this.$refs.edit_waybill_series_modal.openModal(id)
    },
    applyFilters() {
      this.getWaybillSeriesList()
    },
    clearFilters() {
      this.filters = {}
      this.$nextTick(() => {
        this.getWaybillSeriesList()
      })
    },
    handleChangePage(page) {
      this.page = page
      this.getWaybillSeriesList()
    },
    changePage(value) {
      this.perPage = value
    },
    onClickRefresh() {
      this.getWaybillSeriesList()
    },
  },
}
</script>
<style>
.content-body-cell {
  /* Limit the text to 3 lines */
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
  /* Add ellipsis for overflow */
  text-overflow: ellipsis;
  /* Adjust width as needed */
  max-width: 300px; /* Set the maximum width */
}
@media (max-width: 767px) {
  .b-table thead th {
    position: sticky;
    top: 0;
  }
}
</style>
