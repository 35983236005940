<template>
  <div>
    <b-card
      no-body
    >
      <b-tabs
        ref="statusSettingTabBar"
        pills
      >
        <b-tab
          v-if="store.getters.permissions.filter(permission => permission === 'edit-finance-status-setting').length > 0"
        >
          <template #title>
            <span>Finance Statuses</span>
          </template>
          <hr>
          <status-form type="Finance" />
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import {
  BTabs,
  BTab,
  BCard,
} from 'bootstrap-vue'
import StatusForm from '@/components/setting/statuses/StatusForm.vue'
import store from '@/store'

export default {
  components: {
    StatusForm,
    BTabs,
    BTab,
    BCard,
  },
  data() {
    return {
      store,
    }
  },
  mounted() {
    // eslint-disable-next-line no-underscore-dangle
    this.__preserveTabOnRefresh('statusSettingTabBar', 'statusSettingTabIndex')
  },
}
</script>
