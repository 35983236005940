<template>
  <div>
    <b-card>
      <b-form @submit.prevent="handleSubmit()">
        <b-row>

          <!-- username -->
          <b-col cols="12">
            <b-form-group
              label="Add Your Message here"
              label-for="h-message"
            >
              <b-form-textarea
                id="h-message"
                v-model="form.notification_body"
                :state="form.notification_body.length <= 200"
                placeholder="Message"
                rows="3"
                required
              />

            </b-form-group>
          </b-col>

          <!-- email -->
          <b-col cols="12">
            <b-form-group
              label="Email Subject"
              label-for="d-subject"
            >
              <b-form-input
                id="d-subject"
                v-model="form.subject"
                type="subject"
                placeholder="Enter the subject to the email here"
                autocomplete="nope"
                :required="form.is_email_active"
              />
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            md="6"
          >
            <b-form-group
              label="Merchants"
              label-for="merchant"
            >
              <v-select
                v-model="form.merchant_ids"
                :options="merchantOptions"
                :reduce="option => option.id"
                multiple
                deselect-from-dropdown
                label="name"
                placeholder="Select Merchants"
                @input="onMerchantChange"
              >
                <template v-slot:option="option">
                  {{ option.name }}
                </template>
                <template #search="{attributes, events}">
                  <input
                    class="vs__search"
                    style="pointer-events:none"
                    :required="!form.merchant_ids || form.merchant_ids.length <= 0"
                    v-bind="attributes"
                    v-on="events"
                  >
                </template>
              </v-select>
            </b-form-group>
          </b-col>

          <!-- checkbox -->
          <b-col cols="12">
            <b-form-group>

              <b-form-group
                label="Broadcast Type"
                label-for="d-broadcast-type"
              >

                <div class="d-flex flex-wrap mt-1">
                  <b-form-checkbox
                    v-model="form.is_push_notification_active"
                    :value="true"
                    class="custom-control-secondary mr-2"
                  >
                    Send Portal Notification
                  </b-form-checkbox>
                  <b-form-checkbox
                    v-model="form.is_sms_active"
                    :value="true"
                    class="custom-control-secondary mr-2"
                  >
                    Send SMS
                  </b-form-checkbox>
                  <b-form-checkbox
                    v-model="form.is_email_active"
                    :value="true"
                    class="custom-control-secondary"
                  >
                    Send Email
                  </b-form-checkbox>
                </div>
              </b-form-group>
            </b-form-group></b-col>

          <!-- submit and reset -->
          <b-col
            v-can="'create-merchant-notification'"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              class="mr-1 custom-button-color"
            >
              Submit
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary"
              @click="clearForm"
            >
              Reset
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-card>
    <b-card>
      <ClientNotificationTable
        :key="childKey"
        :merchant-options="merchantOptions"
      />
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BButton,
  BFormTextarea,
  BFormCheckbox,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import SuccessMessage from '@core/mixins/SuccessMessage'
import ErrorMessage from '@core/mixins/ErrorMessage'
import ClientNotificationTable from '@/components/Notification/ClientNotificationTable.vue'
import { RepositoryFactory } from '@/repository/RepositoryFactory'

const NotificationRepository = RepositoryFactory.get('notification')
const MerchantRepository = RepositoryFactory.get('merchant')

export default {
  components: {
    ClientNotificationTable,
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    BFormTextarea,
    BFormCheckbox,
  },
  directives: {
    Ripple,
  },
  mixins: [SuccessMessage, ErrorMessage],
  data() {
    return {
      childKey: 1,
      merchantOptions: [],
      form: {
        is_sms_active: false,
        is_email_active: false,
        is_push_notification_active: false,
        notification_body: '',
        subject: '',
        merchant_ids: [],
      },
    }
  },
  mounted() {
    this.getMerchantList()
  },
  methods: {
    async getMerchantList() {
      this.merchantOptions = localStorage.getItem('merchant_list') ? JSON.parse(localStorage.getItem('merchant_list')) : (await MerchantRepository.getMerchantListForDropdown()).data.data
      if (!localStorage.getItem('merchant_list')) localStorage.setItem('merchant_list', JSON.stringify(this.merchantOptions))
      this.merchantOptions.unshift({ id: -1, name: 'All' })
    },
    async handleSubmit() {
      try {
        this.modalLoading = true
        const payload = {
          is_sms_active: this.form.is_sms_active,
          is_email_active: this.form.is_email_active,
          is_push_notification_active: this.form.is_push_notification_active,
          notification_body: this.form.notification_body,
          subject: this.form.subject,
        }
        if (this.form.merchant_ids.includes(-1)) payload.merchant_ids = 'all'
        else payload.merchant_ids = this.form.merchant_ids
        const res = (await NotificationRepository.createMerchantNotification(payload))
        if (res.status === 200) {
          this.showSuccessMessage('Notification Sent Successfully')
          this.childKey += 1
          this.clearForm()
        } else {
          this.showErrorMessage('Failed to send notification')
        }
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.modalLoading = false
    },
    clearForm() {
      this.form.is_sms_active = false
      this.form.is_email_active = false
      this.form.is_push_notification_active = false
      this.form.notification_body = ''
      this.form.subject = ''
      this.form.merchant_ids = []
    },
    onMerchantChange() {
      if (this.form.merchant_ids.includes(-1)) this.form.merchant_ids = [-1]
    },
  },
}
</script>
<style scoped>
.custom-button-color {
  background-color: #4186f5 !important;
  border-color: #4186f5 !important;
}
</style>
