<template>
  <b-modal
    id="upload-terms-and-condition-pdf"
    ref="modal"
    v-model="open"
    size="md"
    no-close-on-backdrop
    hide-footer
    title="UPLOAD TERMS & CONDITIONS PDF"
  >
    <b-overlay
      :show="modalLoading"
      blur="10px"
      opacity="0.30"
      rounded="sm"
      variant="light"
    >
      <b-form @submit.prevent="storeTermsAndConditionPdf">
        <b-row>
          <b-col
            cols="12"
            class="mb-1 mt-2"
          >
            <!-- File Upload -->
            <b-form-group
              label="Select PDF"
              label-for="pdf-upload"
            >
              <b-form-file
                v-model="Pdffile"
                accept=".pdf"
                required
                :state="Boolean(Pdffile)"
                placeholder="Choose a file or drop it here..."
                drop-placeholder="Drop file here..."
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <!-- Submit -->
          <b-col
            cols="12"
            class="d-flex justify-content-end"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              class="mt-1 custom-button-color"
            >
              Submit
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-overlay>
  </b-modal>
</template>
<script>
import {
  BModal,
  VBModal,
  BRow,
  BCol,
  BForm,
  BButton,
  BOverlay,
  BFormFile,
  BFormGroup,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import SuccessMessage from '@core/mixins/SuccessMessage'
import ErrorMessage from '@core/mixins/ErrorMessage'
import { RepositoryFactory } from '@/repository/RepositoryFactory'

const MerchantSettingsRepository = RepositoryFactory.get('merchantSetting')

function initialState() {
  return {
    open: false,
    modalLoading: false,
    loading: true,
    Pdffile: null,
    content_type: 2,
  }
}

export default {
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  components: {
    BModal,
    BRow,
    BCol,
    BForm,
    BButton,
    BOverlay,
    BFormFile,
    BFormGroup,
  },
  mixins: [SuccessMessage, ErrorMessage],
  data() {
    return initialState()
  },
  methods: {
    async openModal() {
      this.open = true
    },
    async storeTermsAndConditionPdf(event) {
      event.preventDefault()
      if (!this.Pdffile) return

      this.modalLoading = true
      try {
        const formData = new FormData()
        formData.append('content_type', 2)
        formData.append('file', this.Pdffile)

        const res = await MerchantSettingsRepository.storeTermsAndConditionPdf(formData)
        if (res.status === 200) {
          this.showSuccessMessage('Terms and Conditions PDF Upload Successfully')
        } else {
          this.showErrorMessage('Terms and Conditions Created PDF Upload Fail')
        }
        this.$parent.fetchTermsAndConditionsVersions()
        this.open = false
      } catch (error) {
        this.showErrorMessage('Terms and Conditions Created PDF Upload Fail')
      } finally {
        this.modalLoading = false
      }
    },
  },
}
</script>
<style scoped>
</style>
