<template>
  <div>
    <b-card
      no-body
    >
      <b-tabs>
        <b-tab>
          <template #title>
            <span>Profile Update</span>&nbsp;
          </template>
          <ProfileUpdate />
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import {
  BTabs,
  BTab,
  BCard,
} from 'bootstrap-vue'
import ProfileUpdate from '@/components/Approvals/ProfileUpdate.vue'

export default {
  components: {
    ProfileUpdate,
    BTabs,
    BTab,
    BCard,
  },
}
</script>
