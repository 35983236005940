<template>
  <b-card>
    <b-overlay
      :show="loading"
      blur="10px"
      opacity="0.30"
      rounded="sm"
      variant="light"
    >
      <b-form
        @submit.prevent
        @submit="setProfileUpdateSetting"
      >
        <b-row>
          <b-card>
            <b-form-radio-group
              v-model="form.approvalGroup"
              :options="radioOptions"
              class="demo-inline-spacing mb-1"
              value-field="value"
              text-field="text"
              disabled-field="disabled"
            />
          </b-card>
        </b-row>
        <b-row>
          <div class="ml-2">
            When this setting is enabled, the admin can manage and approve any merchant profile updates submitted by merchants.
          </div>
        </b-row>
        <b-row>
          <b-col class="d-flex justify-content-end">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              v-can="'edit-profile-update-approval'"
              type="submit"
              class="mb-2 custom-button-color"
            >
              Submit
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-overlay>
    <b-row
      v-if="groupStatus"
      class="d-flex justify-content-end"
    >
      <b-col cols="12">
        <b-table
          striped
          hover
          responsive
          show-empty
          :items="items"
          :fields="fields"
        >
          <template #empty>
            <TableDataFetching
              :rows="items"
              :data-loading="dataLoading"
            />
          </template>
          <template #cell(type)="data">
            <div class="d-flex align-items-start">
              {{ data.value }}
            </div>
          </template>
          <template #cell(status)="data">
            <div v-if="data.value">
              <b-badge variant="light-success">
                Active
              </b-badge>
            </div>
            <div v-if="!data.value">
              <b-badge variant="light-danger">
                Inactive
              </b-badge>
            </div>
          </template>
          <template #cell(Action)="data">
            <b-dropdown
              v-can="'edit-profile-update-approval'"
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="10"
                  class="text-body align-middle "
                />
              </template>
              <b-dropdown-item
                v-if="data.item.status === 1"
                @click="statusAction(data.item.id , 0)"
              >
                <feather-icon icon="ToolIcon" />
                Inactive
              </b-dropdown-item>
              <b-dropdown-item
                v-else
                @click="statusAction(data.item.id , 1)"
              >
                <feather-icon icon="ToolIcon" />
                Active
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </b-table>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BTable,
  BRow,
  BCol,
  BButton,
  BFormRadioGroup,
  BCard,
  BForm,
  BOverlay,
  BBadge,
  BDropdown,
  BDropdownItem,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import SuccessMessage from '@core/mixins/SuccessMessage'
import ErrorMessage from '@core/mixins/ErrorMessage'
import { RepositoryFactory } from '@/repository/RepositoryFactory'

const ApprovalSettingsRepository = RepositoryFactory.get('approvalSettings')

export default {
  components: {
    BTable,
    BRow,
    BCol,
    BButton,
    BFormRadioGroup,
    BCard,
    BForm,
    BOverlay,
    BBadge,
    BDropdown,
    BDropdownItem,
  },
  directives: {
    Ripple,
  },
  mixins: [SuccessMessage, ErrorMessage],
  data() {
    return {
      loading: false,
      dataLoading: false,
      fields: [
        {
          key: 'type', label: 'Setting Type', sortable: false, thClass: ' text-left', tdClass: ' text-left',
        },
        {
          key: 'status', label: 'Is Active', sortable: false, thClass: ' text-left', tdClass: ' text-left',
        },
        {
          key: 'Action', label: 'Action', sortable: false, thClass: ' text-left', tdClass: ' text-left',
        },
      ],
      form: {
        approvalGroup: 0,
      },
      radioOptions: [
        { text: 'On', value: 1 },
        { text: 'Off', value: 0 },
      ],
      items: [],
      groupStatus: false,
    }
  },
  mounted() {
    this.fetchProfileUpdateSetting()
    this.getGroupStatus()
  },
  methods: {
    async setProfileUpdateSetting() {
      this.loading = true
      try {
        const payload = {
          group_id: 2,
          status: this.form.approvalGroup,
        }
        const response = await ApprovalSettingsRepository.changeProfileUpdateStatus(payload)
        if (response.status === 200) {
          this.showSuccessMessage(response.data.message)
          this.groupStatus = this.form.approvalGroup
        } else {
          this.showErrorMessage(response.data.message)
        }
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.loading = false
    },
    async fetchProfileUpdateSetting() {
      this.dataLoading = true
      try {
        const groupId = 2
        const response = await ApprovalSettingsRepository.getProfileUpdateSettingTypes(groupId)
        this.items = response.data.data
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.dataLoading = false
    },
    async statusAction(id, status) {
      this.loading = true
      try {
        const payload = {
          type_id: id,
          status,
        }
        const response = await ApprovalSettingsRepository.statusAction(payload)
        if (response.status === 200) {
          this.showSuccessMessage(response.data.message)
          this.fetchProfileUpdateSetting()
        } else {
          this.showErrorMessage(response.data.message)
        }
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.loading = false
    },

    async getGroupStatus() {
      this.loading = true
      try {
        const groupId = 2
        const response = await ApprovalSettingsRepository.getGroupStatus(groupId)
        this.groupStatus = response.data.data.status
        this.form.approvalGroup = this.groupStatus
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.loading = false
    },
  },
}
</script>
