<template>
  <validation-observer
    ref="changePassword"
    v-slot="{ handleSubmit }"
    slim
  >
    <b-overlay
      :show="loading"
      blur="10px"
      opacity="0.30"
      rounded="sm"
      variant="light"
    >
      <b-form
        ref="password-reset-form"
        @submit.prevent="handleSubmit(changePassword)"
      >
        <b-row>
          <!-- Current Password -->
          <b-col cols="12">
            <b-form-group
              class="required"
              label="Current Password"
              label-for="currPass"
            >
              <validation-provider
                #default="{ errors }"
                name="Current Password"
                rules="required"
              >
                <b-form-input
                  id="currPass"
                  v-model="form.currPass"
                  :state="errors.length > 0 ? false:null"
                  placeholder="*********"
                  type="password"
                  autocomplete="nope"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <!-- New Password -->
          <b-col cols="12">
            <b-form-group
              class="required"
              label="New Password"
              label-for="newPassword"
            >
              <validation-provider
                #default="{ errors }"
                name="New Password"
                rules="required"
              >
                <b-form-input
                  id="newPassword"
                  v-model="form.newPass"
                  type="password"
                  :state="errors.length > 0 ? false:null"
                  placeholder="*********"
                  autocomplete="nope"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <!-- Confirm New Password -->
          <b-col cols="12">
            <b-form-group
              class="required"
              label="Confirm New Password"
              label-for="cnfrmNewPass"
            >
              <validation-provider
                #default="{ errors }"
                name="New Password"
                rules="required"
              >
                <b-form-input
                  id="cnfrmNewPass"
                  v-model="form.cnfrmNewPass"
                  type="password"
                  :state="errors.length > 0 ? false:null"
                  placeholder="*********"
                  autocomplete="nope"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <!-- login button -->
          <b-col
            v-can="'password-rest'"
            cols="12"
            class="d-flex flex-wrap"
          >
            <b-button
              variant="success"
              type="submit"
              class="mr-1"
            >
              Change Password
            </b-button>
            <b-button
              variant="danger"
              @click="clearForm"
            >
              Clear
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-overlay>
  </validation-observer>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BFormInput, BFormGroup, BForm, BRow, BCol, BButton, BOverlay,
} from 'bootstrap-vue'
import { required } from '@validations'
import SuccessMessage from '@core/mixins/SuccessMessage'
import ErrorMessage from '@core/mixins/ErrorMessage'
import { RepositoryFactory } from '@/repository/RepositoryFactory'

const StaffRepository = RepositoryFactory.get('staff')

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BOverlay,
  },
  mixins: [SuccessMessage, ErrorMessage],
  data() {
    return {
      form: {},
      required,
      loading: false,
    }
  },
  methods: {
    async changePassword() {
      this.loading = true
      try {
        const data = {
          old_password: this.form.currPass,
          password: this.form.newPass,
          password_confirmation: this.form.cnfrmNewPass,
        }
        const res = await StaffRepository.staffUserChangePassword(data)
        if (res.status === 200) {
          this.showSuccessMessage('Password Changed Successfully')
          this.$refs['password-reset-form'].reset()
        } else {
          this.showErrorMessage('Cannot Change Password')
        }
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.loading = false
    },
    clearForm() {
      this.form = {}
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/required.scss';
</style>
