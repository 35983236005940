<template>
  <b-row class="d-flex justify-content-end">
    <b-col
      md="4"
      class="my-1"
    >
      <b-input-group size="sm">
        <b-form-select
          id="sortBySelect"
          v-model="sortBy"
          :options="sortOptions"
          class="w-75"
        >
          <template v-slot:first>
            <option value="">
              -- Sort --
            </option>
          </template>
        </b-form-select>
        <b-form-select
          v-model="sortDesc"
          size="sm"
          :disabled="!sortBy"
          class="w-25"
        >
          <option :value="false">
            Asc
          </option>
          <option :value="true">
            Desc
          </option>
        </b-form-select>
      </b-input-group>
    </b-col>
    <b-col
      md="4"
      class="my-1"
    >
      <b-input-group size="sm">
        <b-form-input
          id="filterInput"
          v-model="filter"
          type="search"
          placeholder="Type to Search"
        />
        <b-input-group-append>
          <b-button
            :disabled="!filter"
            @click="filter = ''"
          >
            Clear
          </b-button>
        </b-input-group-append>
      </b-input-group>
    </b-col>

    <b-col cols="12">
      <b-table
        striped
        hover
        responsive
        show-empty
        :items="items"
        :fields="fields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        :filter="filter"
        :filter-included-fields="filterOn"
      >
        <template #empty>
          <TableDataFetching
            :rows="items"
            :data-loading="dataLoading"
          />
        </template>
        <template #cell(name)="data">
          <div class="d-flex align-items-start">
            <div>
              <div class="ml-2">
                {{ data.value }}
              </div>
              <div class="font-small-2 ml-2">
                {{ data.item.subject }}
              </div>
            </div>
          </div>
        </template>

        <template #cell(staff_notification)="data">
          <div v-if="data.value.is_notification_enable">
            <b-badge variant="light-success">
              True
            </b-badge>
          </div>
          <div v-else>
            <b-badge variant="light-danger">
              False
            </b-badge>
          </div>
        </template>
        <template #cell(warehouse_notification)="data">
          <div v-if="data.value.is_notification_enable">
            <b-badge variant="light-success">
              True
            </b-badge>
          </div>
          <div v-else>
            <b-badge variant="light-danger">
              False
            </b-badge>
          </div>
        </template>
        <template #cell(invoice_notification)="data">
          <div v-if="data.value.is_notification_enable">
            <b-badge variant="light-success">
              True
            </b-badge>
          </div>
          <div v-else>
            <b-badge variant="light-danger">
              False
            </b-badge>
          </div>
        </template>
        <!--        Edit Action-->
        <template #cell(Action)="data">
          <b-button
            v-can="'edit-finance-notification'"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            class="btn-icon rounded-circle"
            @click="statusAction(data.item)"
          >
            <feather-icon icon="Edit3Icon" />
          </b-button>
        </template>
      </b-table>

      <!--      pending-->
      <b-modal
        id="modal-finance-notification"
        ref="modal"
        size="lg"
        hide-footer
        title="Finance Notification"
        @hide="reset()"
      >
        <b-overlay
          :show="modalLoading"
          blur="10px"
          opacity="0.30"
          rounded="sm"
          variant="light"
        >
          <b-form @submit.prevent="handleSubmit()">
            <b-row>
              <b-col md="6">
                <b-form-group
                  label="Status"
                  label-for="mc-first-name"
                >
                  <b-form-input
                    id="mc-first-name"
                    :placeholder="form.name"
                    disabled
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-tabs>
              <div
                v-for="(notificationType) in notificationTypes"
                :key="notificationType"
              >
                <b-tab>
                  <template #title>
                    <span>{{ notificationType.name }}</span>
                  </template>
                  <div>
                    <b-card-text
                      v-if="form.name === 'Pending'"
                      class="mt-2"
                    >
                      Notification Conditions
                    </b-card-text>
                    <b-row v-if="form.name === 'Pending'">
                      <b-col md="3">
                        <b-form-group
                          label="Pending Days"
                          label-for="mc-pending-days"
                        >
                          <cleave
                            id="mc-pending-days"
                            v-model="form[notificationType.key].pending_days"
                            class="form-control"
                            :raw="false"
                            :options="options.time"
                            placeholder="dd"
                            required
                          />
                        </b-form-group>
                      </b-col>
                      <b-col
                        v-if="form.name === 'Pending'"
                        md="3"
                      >
                        <b-form-group
                          :label="`Amount more than (${businessSettings.company_currency})`"
                          label-for="mc-amount"
                        >
                          <b-form-input
                            id="mc-amount"
                            v-model="form[notificationType.key].pending_amount"
                            type="number"
                            placeholder="50"
                          />
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-card-text
                      v-if="form.name === 'Pending'"
                      class="mt-1 mb-0"
                    >
                      Condition Rule
                    </b-card-text>
                    <b-row v-if="form.name === 'Pending'">
                      <b-col md="3">
                        <b-form-radio-group
                          v-model="form[notificationType.key].rule"
                          :options="ruleOptions"
                          class="demo-inline-spacing mb-2"
                          value-field="value"
                          text-field="text"
                          disabled-field="disabled"
                        />
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col v-if="form.name === 'Deposited' || form.name === 'Approved'">
                        <b-form-group
                          label="Send To:"
                          label-for="h-state"
                        >
                          <v-select
                            v-model="form[notificationType.key].user_roles['staff-api']"
                            :options="roleoptions"
                            :reduce="option => option.id"
                            label="name"
                            multiple
                            placeholder="Select Role"
                          >
                            <template v-slot:option="option">
                              {{ option.name }}
                            </template>
                            <template #search="{attributes, events}">
                              <input
                                class="vs__search"
                                style="pointer-events:none"
                                :required="(form[notificationType.key].user_roles? form[notificationType.key].user_roles['staff-api'].length === 0 : true ) && (form[notificationType.key].is_email_active || form[notificationType.key].is_sms_active || form[notificationType.key].is_push_notification_active)"
                                v-bind="attributes"
                                v-on="events"
                              >
                            </template>
                          </v-select>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-card-text>
                      Notification Content
                    </b-card-text>
                    <b-row>
                      <b-col>
                        <b-form-textarea
                          :id="`notification_content_${notificationType.key}`"
                          v-model="form[notificationType.key].notification_content"
                          placeholder="Write Notification Content"
                          rows="3"
                          :state="( form[notificationType.key].notification_content ? form[notificationType.key].notification_content.length : 0 ) <= maxChar"
                          class="char-textarea"
                          :class="( form[notificationType.key].notification_content ? form[notificationType.key].notification_content.length : 0 ) >= maxChar ? 'text-danger' : ''"
                          :maxlength="maxChar"
                          :required="form[notificationType.key].is_email_active || form[notificationType.key].is_sms_active || form[notificationType.key].is_push_notification_active"
                          @focus="handleOnFocus"
                        />
                        <small
                          class="textarea-counter-value float-right"
                          :class="( form[notificationType.key].notification_content ? form[notificationType.key].notification_content.length : 0 ) >= maxChar ? 'bg-danger' : ''"
                        >
                          <span class="char-count">{{ form[notificationType.key].notification_content ? form[notificationType.key].notification_content.length : 0 }}</span> / {{ maxChar }}
                        </small>

                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col md="12">
                        <b-form-group
                          label="Email Subject"
                        >
                          <b-form-input
                            :id="`email_subject_${notificationType.key}`"
                            v-model="form[notificationType.key].subject"
                            placeholder="Enter subject"
                            class="mb-1"
                            :required="form[notificationType.key].is_email_active"
                            @focus="handleOnFocus"
                          />
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col md="6">
                        <b-form-group
                          label="Email Body"
                        >
                          <b-form-textarea
                            :id="`email_content_${notificationType.key}`"
                            :ref="`email_content_${notificationType.key}`"
                            v-model="form[notificationType.key].email_body"
                            placeholder="Write Email Content"
                            rows="3"
                            :state="( form[notificationType.key].email_body ? form[notificationType.key].email_body.length : 0 ) <= emailBodyMaxChar"
                            class="char-textarea"
                            :class="(form[notificationType.key].email_body ? form[notificationType.key].email_body.length : 0 ) >= emailBodyMaxChar ? 'text-danger' : ''"
                            :maxlength="emailBodyMaxChar"
                            no-resize
                            :required="form[notificationType.key].is_email_active"
                            @focus="handleOnFocus"
                          />
                          <small
                            class="textarea-counter-value float-right"
                            :class="(form[notificationType.key].email_body ? form[notificationType.key].email_body.length : 0 ) >= emailBodyMaxChar ? 'bg-danger' : ''"
                          >
                            <span class="char-count">{{ form[notificationType.key].email_body ? form[notificationType.key].email_body.length : 0 }}</span> / {{ emailBodyMaxChar }}
                          </small>
                        </b-form-group>
                      </b-col>
                      <b-col md="6">
                        <b-form-group
                          label="SMS Content"
                        >
                          <b-form-textarea
                            :id="`sms_content_${notificationType.key}`"
                            :ref="`sms_content_${notificationType.key}`"
                            v-model="form[notificationType.key].sms_body"
                            placeholder="Write SMS Content"
                            rows="3"
                            :state="(form[notificationType.key].sms_body ? form[notificationType.key].sms_body.length : 0 ) <= smsBodyMaxChar"
                            class="char-textarea"
                            :class="(form[notificationType.key].sms_body ? form[notificationType.key].sms_body.length : 0 ) >= smsBodyMaxChar ? 'text-danger' : ''"
                            :maxlength="smsBodyMaxChar"
                            no-resize
                            :required="form[notificationType.key].is_sms_active"
                            @focus="handleOnFocus"
                          />
                          <small
                            class="textarea-counter-value float-right"
                            :class="( form[notificationType.key].sms_body ? form[notificationType.key].sms_body.length : 0 ) >= smsBodyMaxChar ? 'bg-danger' : ''"
                          >
                            <span class="char-count">{{ form[notificationType.key].sms_body ? form[notificationType.key].sms_body.length : 0 }}</span> / {{ smsBodyMaxChar }}
                          </small>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col md="12">
                        <b-form-group
                          label="Available Tags"
                        >
                          <!-- eslint-disable vue/valid-v-for -->
                          <b-button-group
                            v-for="(tag) in tagOptions"
                            size="sm"
                          >
                            <b-button
                              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                              variant="outline-primary"
                              class="rounded-0"
                              @click="addTag(`{${tag.value}}`)"
                            >
                              {{ tag.text }}
                            </b-button>
                          </b-button-group>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col
                        style="margin-top:5px;"
                      >
                        <b-form-group
                          label="Broadcast Options"
                        >
                          <b-form-checkbox
                            v-model="form[notificationType.key].is_push_notification_active"
                            :value="true"
                            class="custom-control-secondary"
                          >
                            Portal Notification
                          </b-form-checkbox>
                          <b-form-checkbox
                            v-model="form[notificationType.key].is_sms_active"
                            :value="true"
                            class="custom-control-secondary"
                          >
                            SMS
                          </b-form-checkbox>
                          <b-form-checkbox
                            v-model="form[notificationType.key].is_email_active"
                            :value="true"
                            class="custom-control-secondary"
                          >
                            Email
                          </b-form-checkbox>
                        </b-form-group>
                        <b-form-group
                          v-if="notificationType.key === 'warehouse_notification'"
                          label="Warehouse Status"
                        >
                          <b-form-checkbox
                            v-model="form[notificationType.key].is_agency_active"
                            :value="true"
                            class="custom-control-secondary"
                          >
                            Agency
                          </b-form-checkbox>
                          <b-form-checkbox
                            v-model="form[notificationType.key].is_branch_active"
                            :value="true"
                            class="custom-control-secondary"
                          >
                            Branch
                          </b-form-checkbox>
                        </b-form-group>
                        <b-form-group
                          label="Core Status"
                        >
                          <b-form-checkbox
                            v-model="form[notificationType.key].is_notification_enable"
                            :value="true"
                            class="custom-control-secondary"
                          >
                            Active
                          </b-form-checkbox>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </div>
                </b-tab>
              </div>
            </b-tabs>
            <b-row>
              <!-- submit and reset -->
              <b-col class="d-flex justify-content-end">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="submit"
                  variant="primary"
                  class="mr-1"
                >
                  Submit
                </b-button>
                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  variant="outline-secondary"
                  @click="reset"
                >
                  Reset
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </b-overlay>
      </b-modal>
    </b-col>
  </b-row>
</template>

<script>
import {
  BTable,
  BRow,
  BCol,
  BTabs,
  BTab,
  BFormGroup,
  BFormSelect,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BButtonGroup,
  BForm,
  BBadge,
  BCardText,
  BFormTextarea,
  BFormCheckbox,
  BFormRadioGroup,
  BOverlay,
} from 'bootstrap-vue'
import SuccessMessage from '@core/mixins/SuccessMessage'
import ErrorMessage from '@core/mixins/ErrorMessage'
import Ripple from 'vue-ripple-directive'
// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'
import Cleave from 'vue-cleave-component'
import { RepositoryFactory } from '@/repository/RepositoryFactory'

const NotificationSettingRepository = RepositoryFactory.get('notificationSetting')
const RoleRepository = RepositoryFactory.get('role')

export default {
  components: {
    BTable,
    BRow,
    BCol,
    BTabs,
    BTab,
    BFormGroup,
    BFormSelect,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BButtonGroup,
    Cleave,
    BForm,
    BBadge,
    BCardText,
    BFormTextarea,
    BFormCheckbox,
    BFormRadioGroup,
    BOverlay,
  },
  directives: {
    Ripple,
  },
  mixins: [SuccessMessage, ErrorMessage],
  data() {
    return {
      roleoptions: [],
      maxChar: 120,
      smsBodyMaxChar: 150,
      emailBodyMaxChar: 200,
      notificationTypes: [
        {
          name: 'Rider',
          key: 'staff_notification',
        },
        {
          name: 'Warehouse',
          key: 'warehouse_notification',
        },
        {
          name: 'Invoice',
          key: 'invoice_notification',
        },
      ],
      tagOptions: [
        { text: 'Ref No', value: 'ref_no' },
        { text: 'Invoice No', value: 'invoice_no' },
        { text: 'Deposit amount', value: 'amount' },
        { text: 'Transaction No', value: 'transaction_number' },
        { text: 'Staff Name', value: 'staff_name' },
        { text: 'Warehouse Name', value: 'warehouse_name' },
        { text: 'Merchant Name', value: 'merchant_name' },
      ],
      ruleOptions: [
        { text: 'And', value: 'and' },
        { text: 'Or', value: 'or' },
      ],
      options: {
        time: {
          time: true,
          timePattern: ['d'],
        },
      },
      sortBy: '',
      sortDesc: false,
      modalLoading: false,
      dataLoading: true,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      fields: [
        { key: 'name', label: 'Status', sortable: true },
        { key: 'description', label: 'Description', sortable: false },
        { key: 'staff_notification', label: 'Rider Core Status', sortable: false },
        { key: 'warehouse_notification', label: 'Warehouse Core Status', sortable: false },
        { key: 'invoice_notification', label: 'Invoice Core Status', sortable: false },
        { key: 'Action', label: 'Action', sortable: false },
      ],
      items: [],
      form: {},
      selectedTextfieldId: '',
      businessSettings: {},
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  watch: {
    options: {
      handler() {
        this.fetchDataForFinanceNotification()
      },
    },
  },
  mounted() {
    this.fetchDataForFinanceNotification()
    this.readDataforRole()
    this.businessSettings = JSON.parse(localStorage.getItem('businessSettings'))
  },
  methods: {
    async fetchDataForFinanceNotification() {
      try {
        const { data } = (await NotificationSettingRepository.getfinanceNotificationResource())
        data.data.forEach(item => {
          this.notificationTypes.forEach(notificationType => {
            // eslint-disable-next-line no-param-reassign
            item[notificationType.key].user_roles = item[notificationType.key].user_roles || { 'staff-api': [], 'merchant-api': [] }
          })
        })
        this.items = data.data
        this.form = { ...this.items[0] }
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.dataLoading = false
    },
    async readDataforRole() {
      try {
        const { data } = (await RoleRepository.getRoleListDropdown()).data
        this.roleoptions = data
      } catch (e) {
        this.convertAndNotifyError(e)
      }
    },
    async handleSubmit() {
      try {
        this.modalLoading = true
        const payload = {
          key: this.form.key,
          deposit_data: {
            staff_deposit: this.form.staff_notification,
            warehouse_deposit: this.form.warehouse_notification,
            invoice: this.form.invoice_notification,
          },
        }
        const res = (await NotificationSettingRepository.updateFinanceNotificationResource(payload))
        if (res.status === 200) {
          this.showSuccessMessage('Data Updated Successfully')
          this.$bvModal.hide('modal-finance-notification')
          this.fetchDataForFinanceNotification()
        } else {
          this.showErrorMessage('Data Update Failed')
        }
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.modalLoading = false
    },
    addTag(tag) {
      let key
      let inputField
      if (tag !== false) {
        if (this.selectedTextfieldId.includes('notification_content')) {
          key = this.selectedTextfieldId.replaceAll('notification_content_', '')
          inputField = 'notification_content'
        }
        if (this.selectedTextfieldId.includes('email_subject')) {
          key = this.selectedTextfieldId.replaceAll('email_subject_', '')
          inputField = 'subject'
        }
        if (this.selectedTextfieldId.includes('email_content')) {
          key = this.selectedTextfieldId.replaceAll('email_content_', '')
          inputField = 'email_body'
        }
        if (this.selectedTextfieldId.includes('sms_content')) {
          key = this.selectedTextfieldId.replaceAll('sms_content_', '')
          inputField = 'sms_body'
        }
        const field = document.getElementById(this.selectedTextfieldId)
        this.form[key][inputField] = this.form[key][inputField] || ''
        this.form[key][inputField] = `${this.form[key][inputField].substring(0, field.selectionStart)} ${tag} ${this.form[key][inputField].substring(field.selectionStart)}`
        field.focus()
      }
    },
    handleOnFocus(e) {
      this.selectedTextfieldId = e.target.id
    },
    async statusAction(data) {
      await this.fetchDataForFinanceNotification()
      this.form = { ...data }
      this.$bvModal.show('modal-finance-notification')
    },
    reset() {
      this.notificationTypes.forEach(notificationType => {
        this.form[notificationType.key].user_roles = { 'staff-api': [], 'merchant-api': [] }
        // eslint-disable-next-line no-restricted-syntax
        for (const [key] of Object.entries(this.form[notificationType.key])) {
          this.form[notificationType.key][key] = ''
        }
        this.form[notificationType.key].is_email_active = false
        this.form[notificationType.key].is_sms_active = false
        this.form[notificationType.key].is_push_notification_active = false
        this.form[notificationType.key].is_notification_enable = false
        this.form[notificationType.key].is_agency_active = false
        this.form[notificationType.key].is_branch_active = false
      })
    },
  },
}
</script>
