<template>
  <b-card :title="`Customize ${type} Statuses`">
    <b-overlay
      :show="loading"
      blur="10px"
      opacity="0.30"
      rounded="sm"
      variant="light"
    >
      <b-form
        @submit.prevent
        @submit="updateStatuses"
      >
        <b-row>
          <b-card
            class="mb-0 mt-0 w-100"
          >
            <div
              v-for="(statusOption, index) in statusOptions"
              :key="index"
            >
              <b-row>
                <div class="d-flex align-items-center ml-1 mr-1">
                  <b-avatar
                    size="32"
                    :variant="'light-'+statusOption.color"
                  >
                    <feather-icon
                      size="20"
                      :icon="statusOption.icon"
                      color="danger"
                    />
                  </b-avatar>
                  <div />
                </div>
                <b-col md="4">
                  <b-form-group
                    label="Original Status"
                    label-for="original-status-name"
                  >
                    <b-form-input
                      v-model="statusOption.original_name"
                      disabled
                      autocomplete="nope"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group
                    label="Custom Name"
                    label-for="custom-status-name"
                  >
                    <b-form-input
                      v-model="statusOption.name"
                      autocomplete="nope"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </div>
          </b-card>
        </b-row>
        <b-row>
          <div class="ml-2">
            Applied status names will reflect throughout your entire business
          </div>
        </b-row>
        <b-row>
          <!-- submit -->
          <b-col class="d-flex justify-content-end">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="danger"
              class="mt-1 mr-1"
              @click="reset"
            >
              Reset
            </b-button>
            <b-button
              v-can="`edit-${type.toLowerCase()}-status-setting`"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              class="mt-1 custom-button-color"
            >
              Submit
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-overlay>
  </b-card>
</template>

<script>
import {
  BRow, BCol, BForm, BFormGroup, BFormInput, BButton, BCard, BOverlay, BAvatar,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import SuccessMessage from '@core/mixins/SuccessMessage'
import ErrorMessage from '@core/mixins/ErrorMessage'
import { RepositoryFactory } from '@/repository/RepositoryFactory'

const OrderRepository = RepositoryFactory.get('order')
const FinanceRepository = RepositoryFactory.get('finance')
const StatusSettingRepository = RepositoryFactory.get('statusSetting')

export default {
  components: {
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    BCard,
    BOverlay,
    BAvatar,
  },
  directives: {
    Ripple,
  },
  mixins: [SuccessMessage, ErrorMessage],
  props: {
    type: {
      type: String,
      default: 'Order',
    },
  },
  data() {
    return {
      loading: false,
      statusOptions: [],
    }
  },
  mounted() {
    this.readStatuses()
  },
  methods: {
    async readStatuses() {
      this.statusOptions = localStorage.getItem(`${this.type.toLowerCase()}_statuses`) ? JSON.parse(localStorage.getItem(`${this.type.toLowerCase()}_statuses`)) : (this.type === 'Order' ? await OrderRepository.getFullStatusList() : await FinanceRepository.getFinanceStatusList()).data.data
      if (!localStorage.getItem(`${this.type.toLowerCase()}_statuses`)) localStorage.setItem(`${this.type.toLowerCase()}_statuses`, JSON.stringify(this.statusOptions))
    },
    async updateStatuses() {
      this.loading = true
      try {
        const data = {
          status_data: this.statusOptions,
        }
        const res = await StatusSettingRepository.updateFinanceStatuses(data)
        if (res.status === 200) {
          this.showSuccessMessage(`${this.type} Status Settings Updated Successfully`)
          localStorage.setItem(`${this.type.toLowerCase()}_statuses`, JSON.stringify(data.status_data))
        } else {
          this.showErrorMessage(`Cannot Update ${this.type} Status Settings`)
        }
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.loading = false
    },
    reset() {
      this.statusOptions = this.statusOptions.map(status => {
        // eslint-disable-next-line no-param-reassign
        status.name = status.original_name
        return status
      })
    },
  },
}
</script>
<style scoped>
.custom-button-color {
  background-color: #4186f5 !important;
  border-color: #4186f5 !important;
}
</style>
