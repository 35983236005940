<template>
  <b-modal
    id="add-terms-and-conditions-modal"
    ref="modal"
    v-model="open"
    size="xl"
    no-close-on-backdrop
    hide-footer
    title="ADD TERMS & CONDITIONS"
    no-enforce-focus
    @shown="onModalShown"
  >
    <b-overlay
      :show="modalLoading"
      blur="10px"
      opacity="0.30"
      rounded="sm"
      variant="light"
    >
      <b-form
        @submit.prevent
        @submit="storeTermsAndCondition"
      >
        <b-row>
          <b-col
            cols="12"
            class="mb-1 mt-2"
          >
            <div>
              <vue2-tinymce-editor
                v-if="!loading && !modalLoading"
                ref="tinymceEditor"
                v-model="content"
              />
            </div>
          </b-col>
        </b-row>
        <b-row>
          <!-- submit -->
          <b-col
            cols="12"
            class="d-flex justify-content-end"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              class="mt-1 custom-button-color"
            >
              Submit
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-overlay>
  </b-modal>
</template>
<script>
import {
  BModal,
  VBModal,
  BRow,
  BCol,
  BForm,
  BButton,
  BOverlay,
} from 'bootstrap-vue'
import Vue2TinymceEditor from '@core/mixins/TinymceEditor'
import Ripple from 'vue-ripple-directive'
import { RepositoryFactory } from '@/repository/RepositoryFactory'
import SuccessMessage from '@core/mixins/SuccessMessage'
import ErrorMessage from '@core/mixins/ErrorMessage'

const MerchantSettingsRepository = RepositoryFactory.get('merchantSetting')

function initialState() {
  return {
    open: false,
    modalLoading: false,
    loading: true,
    content: '',
  }
}

export default {
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  components: {
    BModal,
    BRow,
    BCol,
    BForm,
    BButton,
    BOverlay,
  },
  mixins: [Vue2TinymceEditor, SuccessMessage, ErrorMessage],
  data() {
    return initialState()
  },
  methods: {
    async openModal() {
      this.open = true
      this.modalLoading = true
      this.content = ''
    },

    onModalShown() {
      this.modalLoading = false
      this.loading = false
    },
    async storeTermsAndCondition() {
      this.modalLoading = true
      try {
        const payload = {
          content_type: 1,
          content: this.content,
        }
        const res = await MerchantSettingsRepository.storeTermsAndCondition(payload)
        if (res.status === 200) {
          this.showSuccessMessage('Terms and Conditions Created Successfully')
        } else {
          this.showErrorMessage('Terms and Conditions Created Fail')
        }
        this.open = false
        this.$parent.fetchTermsAndConditionsVersions()
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.modalLoading = false
    },
  },
}
</script>
<style scoped>
</style>
