<template>
  <b-card title="Update Finance Settings">
    <b-overlay
      :show="loading"
      blur="10px"
      opacity="0.30"
      rounded="sm"
      variant="light"
    >
      <b-form
        @submit.prevent
        @submit="setFinanceSetting"
      >
        <b-row>
          <b-card
            title="Strict Mode"
          >
            <b-form-radio-group
              v-model="form.strict.mode"
              :options="radioOptions"
              class="demo-inline-spacing mb-1"
              value-field="value"
              text-field="text"
              disabled-field="disabled"
            />
          </b-card>
        </b-row>
        <b-row>
          <div class="ml-2">
            If this mode is on, invoices cannot be created until the Agent, Branch or rider deposits are fully settled and approved.
          </div>
        </b-row>
        <b-row class="mt-2">
          <b-card
            title="Finance Cron Job"
          >
            <b-form-radio-group
              v-model="form.cronjob.enabled"
              :options="radioOptions"
              class="demo-inline-spacing mb-1"
              value-field="value"
              text-field="text"
              disabled-field="disabled"
            />
            <b-form-group
              label="Runtime (HH:MM)"
              label-for="runtime"
              class="mt-3"
            >
              <cleave
                id="runtime"
                v-model="form.cronjob.runtime"
                :required="form.cronjob.enabled"
                :disabled="!form.cronjob.enabled"
                :raw="false"
                :options="cleaveOptions.time"
                placeholder="Enter runtime"
                class="form-control"
              />
            </b-form-group>
          </b-card>
        </b-row>
        <b-row>
          <div class="ml-2">
            Here you can specify whether you want the system to auto generate deposits and invoices and if yes, the runtime at which it should occur
          </div>
        </b-row>
        <!-- Start of : Weight Roundup   -->
        <b-row>
          <b-card
            title="Weight Roundup Option"
          >
            <b-form-radio-group
              v-model="form.round_up.enabled"
              :options="roundupOptions"
              class="mb-3"
              value-field="item"
              text-field="name"
              disabled-field="notEnabled"
            />
            <b-form-group
              label="Roundup Margin"
              label-for="weight"
              class="mt-4"
            >
              <b-form-input
                v-model="form.round_up.value"
                placeholder="Enter Weight Margin"
                :required="form.round_up.enabled"
                :disabled="!form.round_up.enabled"
                min="0.1"
                max="0.9"
              />
            </b-form-group>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary"
              class="mt-1"
              @click="roundupReset"
            >
              Reset
            </b-button>
          </b-card>
        </b-row>
        <b-row>
          <div class="ml-2">
            If this option is enabled, the Weight will be rounded up for the delivery charge calculation. The default roundup margin is 0.5.
          </div>
        </b-row>
        <!-- End of : Weight Roundup   -->
        <!-- Start of : Override Actual Weight   -->
        <b-row
          v-if="form.round_up.enabled"
        >
          <b-card
            title="Override Actual Weight Roundup"
          >
            <b-form-radio-group
              v-model="form.override_round_up.enabled"
              :options="roundupOptions"
              class="mb-3"
              value-field="item"
              text-field="name"
              disabled-field="notEnabled"
            />
          </b-card>
        </b-row>
        <b-row
          v-if="form.round_up.enabled"
        >
          <div class="ml-2">
            If this option is enabled the rounded up weight will be saved overriding the actual weight. if the setting is disabled the actual weight will be saved but the Weight will be rounded up for the delivery charge calculation as per the roundup margin provided.
          </div>
        </b-row>
        <b-row class="mt-2">
          <b-col cols="12">
            <h4 class="setting-title">
              Commission Invoice Setting
            </h4>
          </b-col>
          <b-card
            title="Enable Rider Commission Invoice Setting"
          >
            <b-form-radio-group
              v-model="form.commission_invoice.rider_commission_invoice.enabled"
              :options="radioOptions"
              class="demo-inline-spacing mb-1"
              value-field="value"
              text-field="text"
              disabled-field="disabled"
            />
          </b-card>
        </b-row>
        <b-row>
          <div class="ml-2">
            When this setting is enabled to allows the admins to mark rider commissions as paid
            for riders with deposits that are in both deposited and approved status.
          </div>
        </b-row>
        <b-row>
          <b-card
            title="Enable Agent Commission Invoice Setting"
          >
            <b-form-radio-group
              v-model="form.commission_invoice.agency_commission_invoice.enabled"
              :options="radioOptions"
              class="demo-inline-spacing mb-1"
              value-field="value"
              text-field="text"
              disabled-field="disabled"
            />
          </b-card>
        </b-row>
        <b-row>
          <div class="ml-2">
            When this setting is enabled to allows the admins to mark agent commissions as paid
            for agents with deposits that are in both deposited and approved status.
          </div>
        </b-row>
        <!-- End of : Override Actual Weight   -->
        <b-row>
          <!-- submit -->
          <b-col class="d-flex justify-content-end">
            <b-button
              v-can="'business-profile-manage'"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              class="mt-1 custom-button-color"
            >
              Submit
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-overlay>
  </b-card>
</template>

<script>
import {
  BRow, BCol, BForm, BFormGroup, BButton, BCard, BOverlay, BFormRadioGroup, BFormInput,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import SuccessMessage from '@core/mixins/SuccessMessage'
import ErrorMessage from '@core/mixins/ErrorMessage'
import Cleave from 'vue-cleave-component'
import { RepositoryFactory } from '@/repository/RepositoryFactory'

const FinanceSettingsRepository = RepositoryFactory.get('financeSetting')

export default {
  components: {
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BButton,
    BCard,
    BOverlay,
    BFormRadioGroup,
    Cleave,
    BFormInput,
    // BFormRadio,
  },
  directives: {
    Ripple,
  },
  mixins: [SuccessMessage, ErrorMessage],
  data() {
    return {
      loading: false,
      form: {
        strict: {
          mode: false,
        },
        cronjob: {
          enabled: false,
          runtime: '',
        },
        round_up: {
          value: 0.5,
          enabled: false,
        },
        override_round_up: {
          enabled: true,
        },
        commission_invoice: {
          rider_commission_invoice: {
            enabled: false,
          },
          agency_commission_invoice: {
            enabled: false,
          },
        },
      },
      radioOptions: [
        { text: 'On', value: true },
        { text: 'Off', value: false },
      ],
      roundupOptions: [
        { item: true, name: 'On' },
        { item: false, name: 'Off' },
      ],
      overrideOptions: [
        { item: true, name: 'On' },
        { item: false, name: 'Off' },
      ],
      cleaveOptions: {
        time: {
          time: true,
          timePattern: ['h', 'm'],
        },
      },
    }
  },
  watch: {
    // eslint-disable-next-line func-names
    'form.round_up.enabled': function (roundupValue) {
      if (!roundupValue) {
        this.turnOffOverRide()
      }
    },
  },
  mounted() {
    this.readFinanceSetting()
  },
  methods: {
    async readFinanceSetting() {
      this.loading = true
      try {
        const { data } = (await FinanceSettingsRepository.getFinanceSettingsResource()).data
        this.form = data
        if (!this.form.round_up) {
          this.form.round_up = {
            value: 0.5,
            enabled: '',
          }
        }
        if (!this.form.override_round_up) {
          this.form.override_round_up = {
            enabled: '',
          }
        }
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.loading = false
    },
    async setFinanceSetting() {
      this.loading = true
      try {
        const res = await FinanceSettingsRepository.updateFinanceSettingsResource(this.form)
        if (res.status === 200) {
          this.showSuccessMessage('Finance Settings Updated Successfully')
          window.location.reload()
        } else {
          this.showErrorMessage('Cannot Update Finance Settings')
        }
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.loading = false
    },
    roundupReset() {
      this.form.round_up.value = 0.5
    },
    turnOffOverRide() {
      this.form.override_round_up.enabled = false
    },
  },
}
</script>
<style scoped>
.custom-button-color {
  background-color: #4186f5 !important;
  border-color: #4186f5 !important;
}
</style>
