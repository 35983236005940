<template>
  <b-modal
    id="add-new-detail-modal"
    ref="modal"
    v-model="open"
    size="lg"
    no-close-on-backdrop
    hide-footer
    title="ADD DETAILS OF THE NEW SERIES"
  >
    <b-overlay
      :show="modalLoading"
      blur="10px"
      opacity="0.30"
      rounded="sm"
      variant="light"
    >
      <ValidationObserver
        ref="createWaybillSeriesForm"
        v-slot="{ handleSubmit }"
        slim
      >
        <b-form
          class="my-8"
          @submit.prevent="handleSubmit(onClickCreateWaybillSeries)"
        >
          <b-row>
            <!-- Left side -->
            <b-col md="7">
              <b-form-group
                class="required waybill-label"
                label="Name"
                label-for="h-name"
              >
                <b-form-input
                  id="name"
                  v-model="seriesName"
                  placeholder="Enter Name"
                  type="text"
                />
              </b-form-group>
              <b-form-group
                class="required waybill-label"
                label="Customer Type"
                label-for="h-customer-type"
              >
                <v-select
                  v-model="customerType"
                  :options="customerTypeOption"
                  :reduce="option => option.id"
                  label="display_name"
                  autocomplete="nope"
                  placeholder="Select Customer Type"
                  :clearable="false"
                >
                  <template v-slot:option="option">
                    {{ option.display_name }}
                  </template>
                </v-select>
              </b-form-group>
              <b-row class="waybill-content">
                <b-col
                  md="3"
                  class="mt-2 required"
                >
                  Prefix
                </b-col>
                <b-col class="mobile-full-width">
                  <b-form-group
                    class="waybill-label"
                    label="Value"
                    label-for="h-value"
                  >
                    <ValidationProvider
                      #default="{ errors }"
                      name="h-c-prefix"
                      vid="h-c-prefix"
                      rules="required"
                    >
                      <b-form-input
                        id="floating-label1"
                        ref="waybillPrefix"
                        v-model="inputValuePrefix"
                        placeholder="Value"
                        @input="updateLengthPrefix"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </ValidationProvider>
                  </b-form-group>
                </b-col>

                <b-col>
                  <b-form-group
                    class="waybill-label"
                    label="Length"
                    label-for="h-length"
                  >
                    <b-form-input
                      v-model="charCountPrefix"
                      placeholder="Length"
                      disabled
                    />
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row class="waybill-content">
                <b-col
                  md="3"
                  class="required"
                >
                  Start From
                </b-col>
                <b-col class="mobile-full-width">
                  <b-form-group>
                    <b-form-input
                      id="floating-label1"
                      v-model="inputValueStartFrom"
                      placeholder="Value"
                      @input="updateLengthStartFrom"
                    />
                  </b-form-group>
                </b-col>

                <b-col>
                  <b-form-group>
                    <b-form-input
                      v-model="charCountStartFrom"
                      placeholder="Length"
                      disabled
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col
                  md="3"
                >
                  Suffix
                </b-col>
                <b-col class="mobile-full-width">
                  <b-form-group>
                    <ValidationProvider
                      #default="{ errors }"
                      name="h-c-suffix"
                      vid="h-c-suffix"
                    >
                      <b-form-input
                        id="floating-label1"
                        ref="waybillSuffix"
                        v-model="inputValueSuffix"
                        placeholder="Value"
                        @input="updateLengthSuffix"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </ValidationProvider>
                  </b-form-group>
                </b-col>

                <b-col>
                  <b-form-group>
                    <b-form-input
                      v-model="charCountSuffix"
                      placeholder="Length"
                      disabled
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>

            <!-- Right side -->
            <b-col md="5">
              <b-form-group>
                <div class="demo-inline-spacing mb-1 waybill-content waybill-label">
                  <label class="required">Is Auto-waybill</label>
                  <b-form-checkbox
                    v-model="is_auto"
                    checked="true"
                    name="is_auto_waybill"
                    switch
                    inline
                    :disabled="disableAutoWaybill"
                  />
                </div>
              </b-form-group>
              <b-form-group
                class="waybill-label"
                label="Separator"
                label-for="h-separator"
              >
                <v-select
                  v-model="separator"
                  :options="separatorOptions"
                  :reduce="option => option.value"
                  label="name"
                  autocomplete="nope"
                  placeholder="Select separator"
                  :clearable="false"
                >
                  <template v-slot:option="option">
                    {{ option.name }}
                  </template>
                </v-select>
              </b-form-group>
              <b-form-group
                class="waybill-label"
                label="Preview"
                label-for="readOnlyInput"
              >
                <b-form-input
                  id="readOnlyInput"
                  v-model="preview"
                  placeholder="Preview"
                  :value="displayPreview"
                  readonly
                />
              </b-form-group>
            </b-col>
          </b-row>
          <!-- submit and reset -->
          <b-row>
            <b-col cols="12 mb-1">
              <div class="d-flex justify-content-end">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="submit"
                  variant="primary"
                  class="mr-1"
                >
                  Submit
                </b-button>
                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  type="reset"
                  variant="outline-secondary"
                >
                  Clear
                </b-button>
              </div>
            </b-col>
          </b-row>
        </b-form>
      </ValidationObserver>
    </b-overlay>
  </b-modal>
</template>

<script>
import {
  BButton,
  BModal,
  BCol,
  BFormGroup,
  BForm,
  BFormInput,
  BRow,
  BOverlay,
  VBModal,
  BFormCheckbox,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import SuccessMessage from '@core/mixins/SuccessMessage'
import ErrorMessage from '@core/mixins/ErrorMessage'
import vSelect from 'vue-select'
import { RepositoryFactory } from '@/repository/RepositoryFactory'

const waybillSettingsRepository = RepositoryFactory.get('waybillSetting')

function initialState() {
  return {
    open: false,
    modalLoading: false,
    loading: false,
    separator: '',
    separatorOptions: [
      { name: 'Blank ()', value: '' },
      { name: 'Dash (-)', value: '-' },
    ],
    inputValuePrefix: '',
    charCountPrefix: 0,
    inputValueStartFrom: '',
    charCountStartFrom: 0,
    inputValueSuffix: '',
    charCountSuffix: 0,
    is_auto: true,
    seriesName: '',
    preview: '',
    customerType: '',
    customerTypeOption: [],
    prefixValidation: '',
    suffixValidation: '',
  }
}

export default {
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  components: {
    BButton,
    BModal,
    BCol,
    BFormGroup,
    BForm,
    BFormInput,
    BRow,
    BOverlay,
    BFormCheckbox,
    'v-select': vSelect,
  },
  mixins: [SuccessMessage, ErrorMessage],
  data() {
    return initialState()
  },
  computed: {
    displayPreview() {
      return this.getPreview()
    },
    disableAutoWaybill() {
      return this.customerType === 2
    },
  },
  watch: {
    customerType(newValue) {
      if (newValue === 2) {
        this.is_auto = true
      }
    },
  },
  methods: {
    getPreview() {
      const preview = this.inputValuePrefix + (this.charCountPrefix > 0 ? this.separator : '') + this.inputValueStartFrom + (this.charCountSuffix > 0 ? this.separator : '') + this.inputValueSuffix
      this.preview = preview || ''
    },
    validateAlphabeticInput(value, validationField) {
      const regex = /^[a-zA-Z]+$/
      const isValid = regex.test(value) || value === ''
      this[validationField] = isValid ? '' : 'Only letters can be used'
      return this[validationField]
    },
    updateLengthPrefix() {
      this.charCountPrefix = this.inputValuePrefix.length
      this.prefixValidation = this.validateAlphabeticInput(this.inputValuePrefix, 'prefixValidation')
      this.$refs.waybillPrefix.setCustomValidity(this.prefixValidation)
    },
    updateLengthSuffix() {
      this.charCountSuffix = this.inputValueSuffix.length
      this.suffixValidation = this.validateAlphabeticInput(this.inputValueSuffix, 'suffixValidation')
      this.$refs.waybillSuffix.setCustomValidity(this.suffixValidation)
    },
    updateLengthStartFrom() {
      this.charCountStartFrom = this.inputValueStartFrom.length
    },
    async openModal() {
      await Object.assign(this.$data, initialState())
      this.open = true
      await this.getCustomerTypes()
    },
    async onClickCreateWaybillSeries() {
      this.modalLoading = true
      try {
        const startFrom = +this.inputValueStartFrom
        if (startFrom < 1) {
          this.showErrorMessage('The start from value should be greater than 0.')
        } else {
          const payload = {
            series_name: this.seriesName,
            prefix: this.inputValuePrefix,
            prefix_length: this.charCountPrefix,
            start_from: this.inputValueStartFrom,
            suffix: this.inputValueSuffix,
            suffix_length: this.charCountSuffix,
            number_of_digits: this.charCountStartFrom,
            is_auto: this.is_auto,
            separator: this.separator,
            preview: this.preview,
            status: 'pending',
            customer_type_id: this.customerType,
          }
          await waybillSettingsRepository.createWaybillSeries(payload)
          this.showSuccessMessage('Waybill series created successfully')
          this.open = false
          this.$parent.getWaybillSeriesList()
        }
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.modalLoading = false
    },
    async getCustomerTypes() {
      try {
        const res = await waybillSettingsRepository.getCustomerTypes()
        this.customerTypeOption = res.data.data
      } catch (e) {
        this.convertAndNotifyError(e)
      }
    },
  },
}
</script>
<style scoped>
@import "../../../node_modules/@syncfusion/ej2-base/styles/fabric.css";
@import "../../../node_modules/@syncfusion/ej2-vue-navigations/styles/fabric.css";
@import "../../../node_modules/@syncfusion/ej2-inputs/styles/fabric.css";
@import "../../../node_modules/@syncfusion/ej2-buttons/styles/fabric.css";
</style>
<style lang="scss">
@import '@core/scss/vue/required.scss';

.waybill-label label {
  font-size: 1rem !important; /* This will apply to all labels, adjust if needed */
}

.waybill-content {
  .required::after {
  content: "*";
  color: #ea5455;
}
}

@media screen and (max-width: 767px) {
    /* Add the following styles for mobile view */
    .mobile-stack {
      flex-direction: column !important;
    }
    .mobile-full-width {
      width: 100% !important;
    }
  }
</style>
