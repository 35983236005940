<template>
  <b-modal
    id="modal-edit-banner"
    ref="modal"
    v-model="open"
    size="ov-custom"
    no-close-on-backdrop
    hide-footer
    title="Edit Banner"
  >
    <b-overlay
      :show="modalLoading"
      blur="10px"
      opacity="0.30"
      rounded="sm"
      variant="light"
    >
      <ValidationObserver
        ref="statusNotificationForm"
        v-slot="{ handleSubmit }"
        slim
      >
        <b-form @submit.prevent="handleSubmit(handleBannerSubmit)">
          <b-row>
            <b-col md="12">
              <b-form-group
                label="Banner Subject (Optional)"
              >
                <b-form-input
                  id="subject_body"
                  ref="subject_body"
                  v-model="form.subject"
                  placeholder="Write Banner Subject"
                  rows="3"
                  :state="form.subject.length < subjectBodyMaxChar"
                  class="char-textarea"
                  :class="form.subject.length >= subjectBodyMaxChar ? 'text-danger' : ''"
                  :maxlength="subjectBodyMaxChar"
                  no-resize
                  @focus="handleOnFocus"
                />
                <small
                  class="textarea-counter-value float-right"
                  :class="form.subject.length >= subjectBodyMaxChar ? 'bg-danger' : ''"
                >
                  <span class="char-count">{{ form.subject.length }}</span> / {{ subjectBodyMaxChar }}
                </small>
                <feather-icon
                  v-b-tooltip.hover.v-primary="'Click to view user guide'"
                  icon="AlertCircleIcon"
                  size="15"
                  class="cursor-pointer text-primary"
                />
                <small class="textarea-counter-massage-tooltip">
                  The maximum character count for subject is 100.
                </small>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              class="mt-1"
            >
              <b-form-group label="Banner Content">
                <div class="example">
                  <quill-editor
                    id="content_body"
                    ref="content_body"
                    v-model="form.notification_body"
                    class="editor"
                    :options="editorOption"
                    :maxlength="subjectBodyMaxChar"
                    placeholder="Write Banner Subject"
                    :state="form.notification_body.length <= contentBodyMaxChar"
                    :class="filteredContentBodyLength >= contentBodyMaxChar ? 'text-danger' : ''"
                    no-resize
                    :required="form.is_content_active"
                    @keydown.native="handleKeyDown"
                  />
                  <quillEmoji />
                  <small
                    class="textarea-counter-value float-right"
                    :class="filteredContentBodyLength >= contentBodyMaxChar ? 'bg-danger' : ''"
                  >
                    <span class="char-count">{{ filteredContentBodyLength }}</span> / {{ contentBodyMaxChar }}
                  </small>
                  <feather-icon
                    v-b-tooltip.hover.v-primary="'Click to view user guide'"
                    icon="AlertCircleIcon"
                    size="15"
                    class="cursor-pointer text-primary"
                  />
                  <small class="textarea-counter-massage-tooltip">
                    The maximum character count for content is 170.
                  </small>
                </div>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                label="Show in Login Page"
              >
                <div class=" d-flex flex-wrap mt-1">
                  <b-form-radio-group
                    v-model="form.is_login_enable"
                    :options="radioOptions"
                    class="custom-control-secondary"
                    value-field="value"
                    text-field="text"
                    disabled-field="disabled"
                    @change="clearNotificationParties()"
                  />
                </div>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              class="mt-1"
            >
              <b-form-group>
                <b-form-group
                  label="Notification Parties"
                >
                  <div class="d-flex flex-wrap mt-1">
                    <b-form-checkbox
                      v-model="form.is_staff_active"
                      :value="true"
                      class="custom-control-secondary mr-2"
                      @change="handleStaffChange"
                    >
                      Staff
                    </b-form-checkbox>
                    <b-form-checkbox
                      v-model="form.is_merchant_active"
                      :value="true"
                      class="custom-control-secondary"
                      @change="handleMerchantChange"
                    >
                      Merchant
                    </b-form-checkbox>
                  </div>
                </b-form-group>
              </b-form-group></b-col>
            <b-col
              v-show="form.is_staff_active && !form.is_login_enable"
              cols="6"
              md="6"
            >
              <b-form-group
                label="Applicable Staff Roles"
              >
                <v-select
                  v-model="form.user_roles['staff-api']"
                  :options="roleoptions"
                  :reduce="option => option.id"
                  label="name"
                  multiple
                  placeholder="Select Role"
                  @input="onStaffChange"
                >
                  <template v-slot:option="option">
                    {{ option.name }}
                  </template>
                  <template #search="{attributes, events}">
                    <input
                      class="vs__search"
                      style="pointer-events:none"
                      v-bind="attributes"
                      v-on="events"
                    >
                  </template>
                </v-select>
              </b-form-group>
            </b-col>
            <b-col
              v-show="form.is_merchant_active && !form.is_login_enable"
              cols="6"
              md="6"
            >
              <b-form-group
                label="Merchants"
              >
                <v-select
                  v-model="form.merchant_ids"
                  :options="merchantOptions"
                  :reduce="option => option.id"
                  multiple
                  deselect-from-dropdown
                  label="name"
                  placeholder="Select Merchants"
                  :disabled="!form.is_merchant_active"
                  @input="onMerchantChange"
                >
                  <template v-slot:option="option">
                    {{ option.name }}
                  </template>
                  <template #search="{attributes, events}">
                    <input
                      class="vs__search"
                      style="pointer-events:none"
                      v-bind="attributes"
                      v-on="events"
                    >
                  </template>
                </v-select>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class=" mt-1">
            <b-col
              cols="6"
            >
              <b-form-group>

                <b-form-group
                  label="Status Type"
                >

                  <div class="d-flex flex-wrap mt-1">
                    <b-form-checkbox
                      v-model="form.is_active"
                      :value="true"
                      class="custom-control-secondary mr-2"
                    >
                      Active
                    </b-form-checkbox>
                  </div>
                </b-form-group>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group
                label="Select Banner Color"
              >
                <v-select
                  v-model="form.banner_data.color"
                  :options="colors"
                  :reduce="(option) => option.color"
                  deselect-from-dropdown
                  label="name"
                  placeholder="Select Banner Color"
                  :disabled="!form.is_staff_active && !form.is_merchant_active"
                  @input="onColorChange"
                >
                  <template v-slot:option="option">
                    {{ option.name }}
                  </template>
                  <template #search="{attributes, events}">
                    <input
                      class="vs__search"
                      style="pointer-events:none"
                      :required="!form.banner_data.color || form.banner_data.color.length <= 0"
                      v-bind="attributes"
                      v-on="events"
                    >
                  </template>
                </v-select>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Select an Expiry Date"
              >
                <ValidationProvider
                  #default="{ errors }"
                  name="expiredDate"
                  vid="expiredDate"
                  rules="required"
                >
                  <flat-pickr
                    v-model="form.banner_data.expiray_date"
                    class="form-control"
                    :config="{ enableTime: true, dateFormat: 'Y-m-d H:i', minDate: 'today', minTime: new Date().toLocaleTimeString('en-US', {hour12: false, hour: '2-digit', minute: '2-digit'})}"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    id="expireDateValidation"
                    class="text-danger"
                  />
                </ValidationProvider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class=" mt-1">
            <b-col
              cols="12"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="primary"
                class="mr-1"
              >
                Submit
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="outline-secondary"
                @click="clearForm"
              >
                Reset
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </ValidationObserver>
    </b-overlay>
  </b-modal>
</template>

<script>
import {
  BRow, BFormRadioGroup, BFormCheckbox, BCol, BFormGroup, BFormInput, BButton, BOverlay, VBModal, BForm,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import SuccessMessage from '@core/mixins/SuccessMessage'
import ErrorMessage from '@core/mixins/ErrorMessage'
import { RepositoryFactory } from '@/repository/RepositoryFactory'
// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'
import quillEmoji from 'quill-emoji'
import 'quill-emoji/dist/quill-emoji.css'
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'

const BannerRepository = RepositoryFactory.get('banner')
const MerchantRepository = RepositoryFactory.get('merchant')
const RoleRepository = RepositoryFactory.get('role')

function initialState() {
  return {
    open: false,
    id: null,
    subjectBodyMaxChar: 100,
    contentBodyMaxChar: 170,
    editorOption: {
      theme: 'snow',
      modules: {
        'emoji-toolbar': true,
        'emoji-shortname': true,
        toolbar: [
          ['bold', 'italic', 'underline', 'link', 'emoji'],
          [{ script: 'sub' }, { script: 'super' }],
        ],
      },
    },
    modalLoading: false,
    loading: false,
    merchantOptions: [],
    roleoptions: [],
    colors: [
      { name: 'Red', color: '#B93160' },
      { name: 'Yellow', color: '#E7AB79' },
      { name: 'Green', color: '#5FD068' },
      { name: 'Blue', color: '#4688f5' },
    ],
    form: {},
    selectedTextfieldId: '',
    radioOptions: [
      { text: 'Enable', value: true },
      { text: 'Disable', value: false },
    ],
  }
}

export default {
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  components: {
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    BOverlay,
    BFormCheckbox,
    quillEditor,
    quillEmoji,
    flatPickr,
    BFormRadioGroup,
    vSelect,
  },
  mixins: [SuccessMessage, ErrorMessage],
  data() {
    return initialState()
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
    filteredContentBodyLength() {
      const strippedContent = this.form.notification_body.replace(/(<([^>]+)>)/gi, '')
      return strippedContent.length
    },
  },
  methods: {
    handleKeyDown(event) {
      const { keyCode } = event
      const isBackspace = keyCode === 8
      if (this.filteredContentBodyLength >= this.contentBodyMaxChar && !isBackspace) {
        event.preventDefault() // Prevent typing when character count exceeds the limit
      }
    },
    async openModal(id) {
      Object.assign(this.$data, initialState())
      this.open = true
      this.modalLoading = true
      this.id = id
      await this.fetchBannerDetails()
      await this.getRoleList()
      await this.getMerchantList()
      this.modalLoading = false
    },
    async fetchBannerDetails() {
      try {
        const { data } = await BannerRepository.getBannerById(this.id)
        this.form = { ...data.data } // Ensure to copy data properly
        // Check if subject is null, if so, set it to an empty string
        if (this.form.subject === null) {
          this.form.subject = ''
        }
      } catch (e) {
        this.convertAndNotifyError(e)
      }
    },
    async getRoleList() {
      try {
        const { data } = (await RoleRepository.getRoleListNoPagination()).data
        this.roleoptions = data
        this.roleoptions.unshift({
          id: -1,
          name: 'All',
        })
      } catch (e) {
        this.convertAndNotifyError(e)
      }
    },
    async getMerchantList() {
      this.merchantOptions = localStorage.getItem('merchant_list') ? JSON.parse(localStorage.getItem('merchant_list')) : (await MerchantRepository.getMerchantListForDropdown()).data.data
      if (!localStorage.getItem('merchant_list')) localStorage.setItem('merchant_list', JSON.stringify(this.merchantOptions))
      this.merchantOptions.unshift({ id: -1, name: 'All' })
    },
    async handleBannerSubmit() {
      try {
        const currentDate = new Date()
        const currentDateTime = `${currentDate.getFullYear()}-${
          (currentDate.getMonth() + 1).toString().padStart(2, '0')}-${
          currentDate.getDate().toString().padStart(2, '0')} ${
          currentDate.getHours().toString().padStart(2, '0')}:${
          currentDate.getMinutes().toString().padStart(2, '0')}`
        if (this.form.banner_data.expiray_date < currentDateTime) {
          document.getElementById('expireDateValidation').innerHTML = 'Expiration time has passed'
          return
        }
        this.modalLoading = true
        const payload = {
          is_staff_active: this.form.is_staff_active,
          is_merchant_active: this.form.is_merchant_active,
          notification_body: this.form.notification_body,
          subject: this.form.subject,
          user_roles: JSON.parse(JSON.stringify(this.form.user_roles)),
          merchant_ids: this.form.merchant_ids,
          is_active: this.form.is_active,
          is_admin_create: true,
          is_login_enable: this.form.is_login_enable,
          banner_data: {
            color: this.form.banner_data.color,
            expiray_date: this.form.banner_data.expiray_date,
          },
        }
        if (this.filteredContentBodyLength > this.contentBodyMaxChar) {
          this.showErrorMessage('Banner content has exceeded the maximum character limit')
          this.modalLoading = false
          return
        }

        const res = await BannerRepository.updateBanner(this.id, payload)
        if (res.status === 200) {
          this.showSuccessMessage('Banner Updated Successfully')
          this.open = false
          this.$parent.getAllBannerList()
        } else {
          this.showErrorMessage('Banner Update Failed')
        }
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.modalLoading = false
      await this.window.reload()
    },
    clearForm() {
      this.form.is_merchant_active = false
      this.form.is_staff_active = false
      this.form.is_active = false
      this.form.merchant_ids = []
      this.form.staff_ids = []
      this.form.dateNtime = ''
      this.form.color_ids = []
      this.form.subject = ''
      this.form.notification_body = ''
      this.form.user_roles = {
        'staff-api': [],
      }
    },
    onMerchantChange() {
      if (this.form.merchant_ids.includes(-1)) this.form.merchant_ids = [-1]
    },
    onStaffChange() {
      if (this.form.user_roles['staff-api'].includes(-1)) this.form.user_roles['staff-api'] = [-1]
    },
    handleOnFocus(e) {
      this.selectedTextfieldId = e.target.id
    },
    handleStaffChange() {
      if (this.form.is_staff_active) {
        this.form.user_roles['staff-api'] = [-1]
      } else {
        this.form.user_roles['staff-api'] = []
      }
    },
    handleMerchantChange() {
      if (this.form.is_merchant_active) {
        this.form.merchant_ids = [-1]
      } else {
        this.form.merchant_ids = []
      }
    },
    clearNotificationParties() {
      if (this.form.is_login_enable) {
        this.form.is_staff_active = false
        this.form.is_merchant_active = false
      } else {
        this.form.is_staff_active = false
        this.form.is_merchant_active = false
        this.form.user_roles['staff-api'] = []
        this.form.merchant_ids = []
      }
    },
  },
}
</script>

<style>
.content-body-cell {
  /* Limit the text to 3 lines */
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
  /* Add ellipsis for overflow */
  text-overflow: ellipsis;
  /* Adjust width as needed */
  max-width: 300px; /* Set the maximum width */
}

</style>
