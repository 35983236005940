<template>
  <b-tabs
    ref="businessSettingTabBar"
    :vertical="verticalTabBar.status"
    :nav-wrapper-class="verticalTabBar.wrapper"
  >
    <b-tab
      v-if="store.getters.permissions.filter(permission => permission === 'business-profile-manage').length > 0"
      title-item-class="d-flex justify-content-start"
    >
      <template #title>
        <feather-icon icon="UsersIcon" />
        <span>Business Profile</span>
      </template>
      <b-card>
        <BusinessProfile />
      </b-card>
    </b-tab>
    <b-tab
      title-item-class="d-flex justify-content-start"
    >
      <template #title>
        <feather-icon icon="ToolIcon" />
        <span>General Settings</span>
      </template>
      <b-card>
        <General />
      </b-card>
    </b-tab>
    <b-tab
      v-if="store.getters.permissions.filter(permission => permission === 'show-waybill-setting').length > 0"
      title-item-class="d-flex justify-content-start"
    >
      <template #title>
        <feather-icon icon="FileMinusIcon" />
        <span>Waybill Management</span>
      </template>
      <WaybillManagement />
    </b-tab>
    <b-tab
      v-if="store.getters.permissions.filter(permission => permission === 'edit-order-setting').length > 0"
      title-item-class="d-flex justify-content-start"
    >
      <template #title>
        <feather-icon icon="BoxIcon" />
        <span>Orders</span>
      </template>
      <b-card>
        <Orders />
      </b-card>
    </b-tab>
    <b-tab
      v-if="store.getters.permissions.filter(permission => permission === 'edit-order-status-setting' || permission === 'edit-finance-status-setting').length > 0"
      title-item-class="d-flex justify-content-start"
    >
      <template #title>
        <feather-icon icon="OctagonIcon" />
        <span>Statuses</span>
      </template>
      <b-card>
        <Statuses />
      </b-card>
    </b-tab>
    <b-tab
      v-if="store.getters.permissions.filter(permission => permission === 'edit-waybill-layout').length > 0"
      title-item-class="d-flex justify-content-start"
    >
      <template #title>
        <feather-icon icon="PrinterIcon" />
        <span>Print Layouts</span>
      </template>
      <b-card>
        <Print />
      </b-card>
    </b-tab>
    <b-tab
      v-if="store.getters.permissions.filter(permission => permission === 'edit-finance-setting').length > 0"
      title-item-class="d-flex justify-content-start"
    >
      <template #title>
        <feather-icon icon="DollarSignIcon" />
        <span>Finance</span>
      </template>
      <b-card>
        <Finance />
      </b-card>
    </b-tab>
    <b-tab
      v-if="store.getters.permissions.filter(permission => permission === 'view-tax').length > 0"
      title-item-class="d-flex justify-content-start"
    >
      <template #title>
        <feather-icon icon="PercentIcon" />
        <span>Tax Settings</span>
      </template>
      <b-card>
        <Tax />
      </b-card>
    </b-tab>
    <b-tab
      title-item-class="d-flex justify-content-start"
    >
      <template #title>
        <feather-icon icon="MonitorIcon" />
        <span>Tracking Settings</span>
      </template>
      <b-card>
        <TrackImage />
      </b-card>
    </b-tab>
    <!--Merchant Settings-->
    <b-tab
      title-item-class="d-flex justify-content-start"
    >
      <template #title>
        <feather-icon icon="BriefcaseIcon" />
        <span>Merchant Settings</span>
      </template>
      <Merchant />
    </b-tab>
    <!--Approval Settings-->
    <b-tab
      title-item-class="d-flex justify-content-start"
    >
      <template #title>
        <feather-icon icon="CheckCircleIcon" />
        <span>Approval Settings</span>
      </template>
      <b-card>
        <ApprovalSetting />
      </b-card>
    </b-tab>
    <b-tab
      v-if="store.getters.permissions.filter(permission => ( permission === 'web-theme-setting' || permission === 'mobile-theme-setting' )).length > 0"
      title-item-class="d-flex justify-content-start"
    >
      <template #title>
        <feather-icon icon="ApertureIcon" />
        <span>Theme Settings</span>
      </template>
      <b-card>
        <ThemeSetting />
      </b-card>
    </b-tab>
    <b-tab
      v-if="store.getters.permissions.filter(permission => ( permission === 'create-merchant-notification' || permission === 'edit-status-notification' || permission === 'edit-reminder-notification' || permission === 'edit-finance-notification' || permission === 'edit-other-notification' )).length > 0"
      title-item-class="d-flex justify-content-start"
    >
      <template #title>
        <feather-icon icon="BellIcon" />
        <span>Notification</span>
      </template>
      <b-card>
        <Notification />
      </b-card>
    </b-tab>
    <b-tab
      v-if="store.getters.permissions.filter(permission => permission === 'password-rest').length > 0"
      title-item-class="d-flex justify-content-start"
    >
      <template #title>
        <feather-icon icon="LockIcon" />
        <span>Security & Privacy Settings</span>
      </template>
      <b-card>
        <ChangePassword />
      </b-card>
    </b-tab>
  </b-tabs>
</template>

<script>
import {
  BTabs, BTab, BCard,
} from 'bootstrap-vue'
import BusinessProfile from '@/components/setting/BusinessProfile.vue'
import Orders from '@/components/setting/Orders.vue'
import WaybillManagement from '@/components/setting/WaybillManagement.vue'
import Statuses from '@/components/setting/Statuses.vue'
import Finance from '@/components/setting/Finance.vue'
import Print from '@/components/setting/Print.vue'
import Notification from '@/components/setting/Notification.vue'
import ChangePassword from '@/components/setting/ChangePassword.vue'
import General from '@/components/setting/General.vue'
import Tax from '@/components/setting/Tax.vue'
import store from '@/store'
import { $themeBreakpoints } from '@themeConfig'
import TrackImage from '@/components/setting/TrackImage.vue'
import Merchant from '@/components/setting/Merchant.vue'
import ThemeSetting from '@/components/setting/ThemeSetting.vue'
import ApprovalSetting from '@/components/setting/ApprovalSetting.vue'

export default {
  components: {
    Notification,
    BusinessProfile,
    WaybillManagement,
    ChangePassword,
    Finance,
    Print,
    Orders,
    Statuses,
    General,
    Tax,
    TrackImage,
    Merchant,
    ThemeSetting,
    BTabs,
    BTab,
    BCard,
    ApprovalSetting,
  },
  data() {
    return {
      store,
    }
  },
  computed: {
    verticalTabBar() {
      if (this.$store.state.app.windowWidth > $themeBreakpoints.lg) {
        return {
          status: true,
          wrapper: 'nav-vertical',
        }
      }
      return {
        status: false,
        wrapper: 'nav-horizontal',
      }
    },
  },
  mounted() {
    // eslint-disable-next-line no-underscore-dangle
    this.__preserveTabOnRefresh('businessSettingTabBar', 'businessSettingTabIndex')
  },
}
</script>
