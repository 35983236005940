<template>
  <b-modal
    id="edit-waybill-series-modal"
    ref="modal"
    v-model="open"
    size="lg"
    no-close-on-backdrop
    hide-footer
    title="UPDATE WAYBILL SERIES SERIES"
  >
    <b-overlay
      :show="modalLoading"
      blur="10px"
      opacity="0.30"
      rounded="sm"
      variant="light"
    >
      <ValidationObserver
        ref="createWaybillSeriesForm"
        v-slot="{ handleSubmit }"
        slim
      >
        <b-form
          class="my-8"
          @submit.prevent="handleSubmit(onClickUpdateWaybillSeries)"
        >
          <b-row>
            <!-- Left side -->
            <b-col md="7">
              <b-form-group
                class="required waybill-label"
                label="Name"
                label-for="h-name"
              >
                <b-form-input
                  id="name"
                  v-model="form.series_name"
                  placeholder="Enter Name"
                  type="text"
                />
              </b-form-group>
              <b-form-group
                class="required waybill-label"
                label="Customer Type"
                label-for="h-customer-type"
              >
                <v-select
                  v-model="form.customer_type_id"
                  :options="customerTypeOption"
                  :reduce="option => option.id"
                  label="display_name"
                  autocomplete="nope"
                  placeholder="Select Customer Type"
                  :clearable="false"
                  @input="onChangeCustomerType()"
                >
                  <template v-slot:option="option">
                    {{ option.display_name }}
                  </template>
                </v-select>
              </b-form-group>
              <b-row class="waybill-content">
                <b-col
                  md="3"
                  class="mt-2 required"
                >
                  Prefix
                </b-col>
                <b-col class="mobile-full-width">
                  <b-form-group
                    class="waybill-label"
                    label="Value"
                    label-for="h-value"
                  >
                    <ValidationProvider
                      #default="{ errors }"
                      name="h-c-prefix"
                      vid="h-c-prefix"
                      rules="required"
                    >
                      <b-form-input
                        id="floating-label1"
                        ref="waybillPrefix"
                        v-model="form.prefix"
                        placeholder="Value"
                        @input="updateLengthPrefix"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </ValidationProvider>
                  </b-form-group>
                </b-col>

                <b-col>
                  <b-form-group
                    class="waybill-label"
                    label="Length"
                    label-for="h-length"
                  >
                    <b-form-input
                      v-model="form.prefix_length"
                      placeholder="Length"
                      disabled
                    />
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row class="waybill-content">
                <b-col
                  md="3"
                  class="required"
                >
                  Start From
                </b-col>
                <b-col class="mobile-full-width">
                  <b-form-group>
                    <b-form-input
                      id="floating-label1"
                      v-model="form.start_from"
                      placeholder="Value"
                      @input="updateLengthStartFrom"
                    />
                  </b-form-group>
                </b-col>

                <b-col>
                  <b-form-group>
                    <b-form-input
                      v-model="form.number_of_digits"
                      placeholder="Length"
                      disabled
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col
                  md="3"
                >
                  Suffix
                </b-col>
                <b-col class="mobile-full-width">
                  <b-form-group>
                    <ValidationProvider
                      #default="{ errors }"
                      name="h-c-suffix"
                      vid="h-c-suffix"
                    >
                      <b-form-input
                        id="floating-label1"
                        ref="waybillSuffix"
                        v-model="form.suffix"
                        placeholder="Value"
                        @input="updateLengthSuffix"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </ValidationProvider>
                  </b-form-group>
                </b-col>

                <b-col>
                  <b-form-group>
                    <b-form-input
                      v-model="form.suffix_length"
                      placeholder="Length"
                      disabled
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>

            <!-- Right side -->
            <b-col md="5">
              <b-form-group>
                <div class="demo-inline-spacing mb-1 waybill-content waybill-label">
                  <label class="required">Is Auto-waybill</label>
                  <b-form-checkbox
                    v-model="form.is_auto"
                    checked="true"
                    name="is_auto_waybill"
                    switch
                    inline
                    :disabled="disableAutoWaybill"
                  />
                </div>
              </b-form-group>
              <b-form-group
                class="waybill-label"
                label="Separator"
                label-for="h-separator"
              >
                <v-select
                  v-model="form.separator"
                  :options="separatorOptions"
                  :reduce="option => option.value"
                  label="name"
                  autocomplete="nope"
                  placeholder="Select separator"
                  :clearable="false"
                >
                  <template v-slot:option="option">
                    {{ option.name }}
                  </template>
                </v-select>
              </b-form-group>
              <b-form-group
                class="waybill-label"
                label="Preview"
                label-for="readOnlyInput"
              >
                <b-form-input
                  id="readOnlyInput"
                  v-model="form.preview"
                  placeholder="Preview"
                  :value="displayPreview"
                  readonly
                />
              </b-form-group>
            </b-col>
          </b-row>
          <!-- submit and reset -->
          <b-row>
            <b-col cols="12 mb-1 ">
              <div class="d-flex justify-content-end">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="submit"
                  variant="primary"
                  class="mr-1"
                >
                  Submit
                </b-button>
                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  type="reset"
                  variant="outline-secondary"
                >
                  Clear
                </b-button>
              </div>
            </b-col>
          </b-row>
        </b-form>
      </ValidationObserver>
    </b-overlay>
  </b-modal>
</template>

<script>
import {
  BButton,
  BModal,
  BCol,
  BFormGroup,
  BForm,
  BFormInput,
  BRow,
  BOverlay,
  VBModal,
  BFormCheckbox,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import SuccessMessage from '@core/mixins/SuccessMessage'
import ErrorMessage from '@core/mixins/ErrorMessage'
import vSelect from 'vue-select'
import { RepositoryFactory } from '@/repository/RepositoryFactory'

const waybillSettingsRepository = RepositoryFactory.get('waybillSetting')

function initialState() {
  return {
    open: false,
    id: null,
    modalLoading: false,
    loading: false,
    separatorOptions: [
      { name: 'Blank ()', value: '' },
      { name: 'Dash (-)', value: '-' },
    ],
    form: {
      customer_type_id: null,
      prefixValidation: '',
      suffixValidation: '',
    },
    customerTypeOption: [],
  }
}

export default {
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  components: {
    BButton,
    BModal,
    BCol,
    BFormGroup,
    BForm,
    BFormInput,
    BRow,
    BOverlay,
    BFormCheckbox,
    'v-select': vSelect,
  },
  mixins: [SuccessMessage, ErrorMessage],
  data() {
    return initialState()
  },
  computed: {
    displayPreview() {
      return this.getPreview()
    },
    disableAutoWaybill() {
      return this.form.customer_type_id === 2
    },
  },
  methods: {
    onChangeCustomerType() {
      if (this.form.customer_type_id === 2) {
        this.form.is_auto = true
      }
    },
    getPreview() {
      const preview = (this.form.prefix ? this.form.prefix : '') + (this.form.prefix_length > 0 ? this.form.separator : '') + this.form.start_from + (this.form.suffix_length > 0 ? this.form.separator : '') + (this.form.suffix ? this.form.suffix : '')
      this.form.preview = preview || ''
    },
    validateAlphabeticInput(value, validationField) {
      const regex = /^[a-zA-Z]+$/
      const isValid = regex.test(value) || value === ''
      this[validationField] = isValid ? '' : 'Only letters can be used'
      return this[validationField]
    },
    updateLengthPrefix() {
      this.form.prefix_length = this.form.prefix.length
      this.prefixValidation = this.validateAlphabeticInput(this.form.prefix, 'prefixValidation')
      this.$refs.waybillPrefix.setCustomValidity(this.prefixValidation)
    },
    updateLengthSuffix() {
      this.form.suffix_length = this.form.suffix.length
      this.suffixValidation = this.validateAlphabeticInput(this.form.suffix, 'suffixValidation')
      this.$refs.waybillSuffix.setCustomValidity(this.suffixValidation)
    },
    updateLengthStartFrom() {
      this.form.number_of_digits = this.form.start_from.length
    },
    async openModal(id) {
      Object.assign(this.$data, initialState())
      this.open = true
      this.modalLoading = true
      this.id = id
      await this.fetchWaybillSeriesDetails()
      await this.getCustomerTypes()
      this.modalLoading = false
    },
    async fetchWaybillSeriesDetails() {
      try {
        const { data } = await waybillSettingsRepository.getWaybillSeriesByID(this.id)
        this.form = data

        if (this.form.customer_type_id === 2) {
          this.disableAutoWaybill()
        }
      } catch (e) {
        this.convertAndNotifyError(e)
      }
    },
    async onClickUpdateWaybillSeries() {
      this.modalLoading = true
      try {
        const startFrom = +this.form.start_from
        if (startFrom < 1) {
          this.showErrorMessage('The start from value should be greater than 0.')
        } else {
          const payload = {
            series_name: this.form.series_name,
            prefix: this.form.prefix,
            prefix_length: this.form.prefix_length,
            start_from: this.form.start_from,
            suffix: this.form.suffix,
            suffix_length: this.form.suffix_length,
            number_of_digits: this.form.number_of_digits,
            is_auto: this.form.is_auto,
            separator: this.form.separator,
            preview: this.form.preview,
            status: 'pending',
            customer_type_id: this.form.customer_type_id,
          }
          await waybillSettingsRepository.updateWaybillSeries(this.id, payload)
          this.showSuccessMessage('Waybill Series Updated Successfully')
          this.open = false
          this.$parent.getWaybillSeriesList()
        }
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.modalLoading = false
    },
    async getCustomerTypes() {
      try {
        const res = await waybillSettingsRepository.getCustomerTypes()
        this.customerTypeOption = res.data.data
      } catch (e) {
        this.convertAndNotifyError(e)
      }
    },
  },
}
</script>
<style scoped>
@import "../../../node_modules/@syncfusion/ej2-base/styles/fabric.css";
@import "../../../node_modules/@syncfusion/ej2-vue-navigations/styles/fabric.css";
@import "../../../node_modules/@syncfusion/ej2-inputs/styles/fabric.css";
@import "../../../node_modules/@syncfusion/ej2-buttons/styles/fabric.css";
</style>
<style lang="scss">
@import '@core/scss/vue/required.scss';

.waybill-label label {
  font-size: 1rem !important; /* This will apply to all labels, adjust if needed */
}

@media screen and (max-width: 767px) {
    /* Add the following styles for mobile view */
    .mobile-stack {
      flex-direction: column !important;
    }
    .mobile-full-width {
      width: 100% !important;
    }
  }
</style>
