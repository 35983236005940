<template>
  <b-row class="d-flex justify-content-end">
    <b-col
      md="4"
      class="my-1"
    >
      <b-input-group size="sm">
        <b-form-select
          id="sortBySelect"
          v-model="sortBy"
          :options="sortOptions"
          class="w-75"
        >
          <template v-slot:first>
            <option value="">
              -- Sort --
            </option>
          </template>
        </b-form-select>
        <b-form-select
          v-model="sortDesc"
          size="sm"
          :disabled="!sortBy"
          class="w-25"
        >
          <option :value="false">
            Asc
          </option>
          <option :value="true">
            Desc
          </option>
        </b-form-select>
      </b-input-group>
    </b-col>
    <b-col
      md="4"
      class="my-1"
    >
      <b-input-group size="sm">
        <b-form-input
          id="filterInput"
          v-model="filter"
          type="search"
          placeholder="Type to Search"
        />
        <b-input-group-append>
          <b-button
            :disabled="!filter"
            @click="filter = ''"
          >
            Clear
          </b-button>
        </b-input-group-append>
      </b-input-group>
    </b-col>

    <b-col cols="12">
      <b-table
        striped
        hover
        responsive
        show-empty
        :items="items"
        :fields="fields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        :filter="filter"
        :filter-included-fields="filterOn"
      >
        <template #empty>
          <TableDataFetching
            :rows="items"
            :data-loading="dataLoading"
          />
        </template>
        <template #cell(name)="data">
          <div class="d-flex align-items-start">
            <div>
              <div class="ml-2">
                {{ data.value }}
              </div>
            </div>
          </div>
        </template>
        <!--        Portal Notification Active-->
        <template #cell(is_push_notification_active)="data">
          <div v-if="data.value">
            <b-badge variant="light-success">
              Active
            </b-badge>
          </div>
          <div v-if="!data.value">
            <b-badge variant="light-danger">
              Inactive
            </b-badge>
          </div>
        </template>
        <!--        Sms Active-->
        <template #cell(is_sms_active)="data">
          <div v-if="data.value">
            <b-badge variant="light-success">
              Active
            </b-badge>
          </div>
          <div v-if="!data.value">
            <b-badge variant="light-danger">
              Inactive
            </b-badge>
          </div>
        </template>
        <!--        Email Active-->
        <template #cell(is_email_active)="data">
          <div v-if="data.value">
            <b-badge variant="light-success">
              Active
            </b-badge>
          </div>
          <div v-if="!data.value">
            <b-badge variant="light-danger">
              Inactive
            </b-badge>
          </div>
        </template>
        <template #cell(is_merchant_active)="data">
          <div v-if="data.value">
            <b-badge variant="light-success">
              Active
            </b-badge>
          </div>
          <div v-if="!data.value">
            <b-badge variant="light-danger">
              Inactive
            </b-badge>
          </div>
        </template>
        <!--       Reminder Active-->
        <template #cell(is_reminder_active)="data">
          <div v-if="data.value">
            <b-badge variant="light-success">
              Active
            </b-badge>
          </div>
          <div v-else>
            <b-badge variant="light-danger">
              Inactive
            </b-badge>
          </div>
        </template>
        <!--        Edit Action-->
        <template #cell(Action)="data">
          <b-button
            v-can="'edit-reminder-notification'"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            class="btn-icon rounded-circle"
            @click="statusAction(data.item)"
          >
            <feather-icon icon="Edit3Icon" />
          </b-button>
        </template>
      </b-table>

      <b-modal
        id="modal-set-reminder"
        ref="modal"
        size="ov-custom"
        hide-footer
        title="Status Reminder Notification"
      >
        <b-overlay
          :show="modalLoading"
          blur="10px"
          opacity="0.30"
          rounded="sm"
          variant="light"
        >
          <b-form @submit.prevent="handleSubmit()">
            <b-row>
              <b-col md="6">
                <b-form-group
                  label="Status"
                  label-for="mc-first-name"
                >
                  <b-form-input
                    id="mc-first-name"
                    :placeholder="form.name"
                    disabled
                  />
                </b-form-group>
              </b-col>
              <b-col md="6" />
              <b-col cols="6">
                <!--              email subject-->
                <b-form-group
                  label="Notification Content"
                >
                  <b-form-textarea
                    id="reminder_body"
                    v-model="form.reminder_body"
                    placeholder="Write Notification Content"
                    rows="3"
                    :state="form.reminder_body.length <= maxChar"
                    class="char-textarea"
                    :class="form.reminder_body.length >= maxChar ? 'text-danger' : ''"
                    :maxlength="maxChar"
                    :required="form.is_push_notification_active"
                    @focus="handleOnFocus"
                  />
                  <small
                    class="textarea-counter-value float-right"
                    :class="form.reminder_body.length >= maxChar ? 'bg-danger' : ''"
                  >
                    <span class="char-count">{{ form.reminder_body.length }}</span> / {{ maxChar }}
                  </small>
                </b-form-group>
              </b-col>
              <b-col md="6" />
              <b-col cols="12">
                <b-form-group
                  label="Send To:"
                >
                  <v-select
                    v-model="form.user_roles['staff-api']"
                    :options="roleoptions"
                    :reduce="option => option.id"
                    label="name"
                    multiple
                    placeholder="Select Role"
                  >
                    <template v-slot:option="option">
                      {{ option.name }}
                    </template>
                    <template #search="{attributes, events}">
                      <input
                        class="vs__search"
                        style="pointer-events:none"
                        v-bind="attributes"
                        v-on="events"
                      >
                    </template>
                  </v-select>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  label="Reminder Period (Hour)"
                >
                  <cleave
                    id="time"
                    v-model="form.reminder_period"
                    class="form-control"
                    :raw="false"
                    :options="options.time"
                    placeholder="Hours"
                    :required="form.is_push_notification_active"
                  />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <!--              email subject-->
                <b-form-group
                  label="Email Subject"
                >
                  <b-form-input
                    id="subject"
                    v-model="form.subject"
                    placeholder="Enter subject"
                    class="mb-1"
                    :required="form.is_email_active"
                    @focus="handleOnFocus"
                  />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  label="SMS Content"
                >
                  <b-form-textarea
                    id="sms_body"
                    ref="sms_content"
                    v-model="form.sms_body"
                    placeholder="Write SMS Content"
                    rows="3"
                    :state="form.sms_body.length <= smsBodyMaxChar"
                    class="char-textarea"
                    :class="form.sms_body.length >= smsBodyMaxChar ? 'text-danger' : ''"
                    :maxlength="smsBodyMaxChar"
                    no-resize
                    :required="form.is_sms_active"
                    @focus="handleOnFocus"
                  />
                  <small
                    class="textarea-counter-value float-right"
                    :class="form.sms_body.length >= smsBodyMaxChar ? 'bg-danger' : ''"
                  >
                    <span class="char-count">{{ form.sms_body.length }}</span> / {{ smsBodyMaxChar }}
                  </small>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  label="Email Body"
                >
                  <b-form-textarea
                    id="email_body"
                    ref="email_content"
                    v-model="form.email_body"
                    placeholder="Write Email Content"
                    rows="3"
                    :state="form.email_body.length <= emailBodyMaxChar"
                    class="char-textarea"
                    :class="form.email_body.length >= emailBodyMaxChar ? 'text-danger' : ''"
                    :maxlength="emailBodyMaxChar"
                    no-resize
                    :required="form.is_email_active"
                    @focus="handleOnFocus"
                  />
                  <small
                    class="textarea-counter-value float-right"
                    :class="form.email_body.length >= emailBodyMaxChar ? 'bg-danger' : ''"
                  >
                    <span class="char-count">{{ form.email_body.length }}</span> / {{ emailBodyMaxChar }}
                  </small>
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group
                  label="Available Tags"
                >
                  <b-button-group
                    v-for="(tag, index) in tagOptions"
                    :key="index"
                    size="sm"
                  >
                    <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      variant="outline-primary"
                      class="rounded-0"
                      @click="addTag(`{${tag.value}}`)"
                    >
                      {{ tag.text }}
                    </b-button>
                  </b-button-group>
                </b-form-group>
              </b-col>
              <b-col
                md="12"
                style="margin-top:5px;"
              >
                <b-form-group
                  label="Broadcast Options"
                >
                  <b-form-checkbox
                    v-model="form.is_push_notification_active"
                    :value="true"
                    class="custom-control-secondary"
                  >
                    Portal Notification
                  </b-form-checkbox>
                  <b-form-checkbox
                    v-model="form.is_sms_active"
                    :value="true"
                    class="custom-control-secondary"
                  >
                    SMS
                  </b-form-checkbox>
                  <b-form-checkbox
                    v-model="form.is_email_active"
                    :value="true"
                    class="custom-control-secondary"
                  >
                    Email
                  </b-form-checkbox>
                </b-form-group>
                <b-form-group
                  label="Receiver Options"
                >
                  <b-form-checkbox
                    v-model="form.is_merchant_active"
                    :value="true"
                    class="custom-control-secondary"
                  >
                    Merchant
                  </b-form-checkbox>
                </b-form-group>
                <b-form-group
                  label="Core Status"
                >
                  <b-form-checkbox
                    v-model="form.is_reminder_active"
                    :value="true"
                    class="custom-control-secondary"
                  >
                    Active
                  </b-form-checkbox>
                </b-form-group>
              </b-col>
              <b-col md="4" />

              <!-- submit and reset -->
              <b-col class="d-flex justify-content-end">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="submit"
                  variant="primary"
                  class="mr-1"
                >
                  Submit
                </b-button>
                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  variant="outline-secondary"
                  @click="clearForm()"
                >
                  Reset
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </b-overlay>
      </b-modal>

    </b-col>
  </b-row>
</template>

<script>
import {
  BTable,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BForm,
  BBadge,
  BFormTextarea,
  BFormCheckbox,
  BButtonGroup,
  BOverlay,
} from 'bootstrap-vue'
import SuccessMessage from '@core/mixins/SuccessMessage'
import ErrorMessage from '@core/mixins/ErrorMessage'
import Ripple from 'vue-ripple-directive'
// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'
import Cleave from 'vue-cleave-component'
import StatusNotificationTable from '@/components/Notification/StatusNotificationTable.vue'
import { RepositoryFactory } from '@/repository/RepositoryFactory'

const NotificationSettingRepository = RepositoryFactory.get('notificationSetting')
const RoleRepository = RepositoryFactory.get('role')

export default {
  components: {
    BTable,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BForm,
    BBadge,
    BFormTextarea,
    BOverlay,
    BFormCheckbox,
    BButtonGroup,
    Cleave,
  },
  directives: {
    Ripple,
  },
  mixins: [SuccessMessage, ErrorMessage],
  data() {
    return {
      maxChar: 120,
      smsBodyMaxChar: 150,
      emailBodyMaxChar: 200,
      options: {
        time: {
          time: true,
          timePattern: ['h'],
        },
      },
      roleoptions: [],
      tagOptions: StatusNotificationTable.data().tagOptions,
      // datatable
      sortBy: '',
      sortDesc: false,
      modalLoading: false,
      dataLoading: true,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      fields: [
        { key: 'name', label: 'Status', sortable: true },
        { key: 'is_push_notification_active', label: 'Portal Notification', sortable: false },
        { key: 'is_sms_active', label: 'Active SMS', sortable: false },
        { key: 'is_email_active', label: 'Active Email', sortable: false },
        { key: 'is_merchant_active', label: 'Active Merchant', sortable: false },
        { key: 'is_reminder_active', label: 'Core Status', sortable: false },
        { key: 'Action', label: 'Action', sortable: false },
      ],
      items: [],
      form: {
        reminder_body: '',
        sms_body: '',
        email_body: '',
        user_roles: { 'staff-api': [], 'merchant-api': [] },
      },
      selectedTextfieldId: '',
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  watch: {
    options: {
      handler() {
        this.fetchDataForReminderNotification()
      },
    },
  },
  mounted() {
    this.fetchDataForReminderNotification()
    this.readDataforRole()
  },
  methods: {
    async fetchDataForReminderNotification() {
      try {
        const { data } = (await NotificationSettingRepository.getReminderNotificationResource())
        this.items = data.data
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.dataLoading = false
    },
    async readDataforRole() {
      try {
        const { data } = (await RoleRepository.getRoleListDropdown()).data
        this.roleoptions = data
      } catch (e) {
        this.convertAndNotifyError(e)
      }
    },
    async handleSubmit() {
      try {
        this.modalLoading = true
        const payload = {
          key: this.form.key,
          is_sms_active: this.form.is_sms_active,
          is_email_active: this.form.is_email_active,
          is_push_notification_active: this.form.is_push_notification_active,
          is_reminder_active: this.form.is_reminder_active,
          is_merchant_active: this.form.is_merchant_active,
          sms_body: this.form.sms_body,
          email_body: this.form.email_body,
          subject: this.form.subject,
          user_roles: this.form.user_roles,
          reminder_body: this.form.reminder_body,
          reminder_period: this.form.reminder_period,
        }
        const res = (await NotificationSettingRepository.updateReminderNotificationResource(payload))
        if (res.status === 200) {
          this.showSuccessMessage('Data Updated Successfully')
          this.$bvModal.hide('modal-set-reminder')
          this.fetchDataForReminderNotification()
        } else {
          this.showErrorMessage('Data Update Failed')
        }
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.modalLoading = false
    },
    addTag(tag) {
      if (tag !== false) {
        const field = document.getElementById(this.selectedTextfieldId)
        this.form[this.selectedTextfieldId] = this.form[this.selectedTextfieldId] || ''
        this.form[this.selectedTextfieldId] = `${this.form[this.selectedTextfieldId].substring(0, field.selectionStart)} ${tag} ${this.form[this.selectedTextfieldId].substring(field.selectionStart)}`
        field.focus()
      }
    },
    handleOnFocus(e) {
      this.selectedTextfieldId = e.target.id
    },
    statusAction(form) {
      this.form = { ...form }
      this.form.reminder_body = this.form.reminder_body || ''
      this.form.email_body = this.form.email_body || ''
      this.form.sms_body = this.form.sms_body || ''
      this.form.user_roles = this.form.user_roles || { 'staff-api': [], 'merchant-api': [] }
      this.$bvModal.show('modal-set-reminder')
    },
    clearForm() {
      this.form.is_sms_active = false
      this.form.is_email_active = false
      this.form.is_push_notification_active = false
      this.form.is_reminder_active = false
      this.form.is_merchant_active = false
      this.form.reminder_body = ''
      this.form.reminder_period = ''
      this.form.sms_body = ''
      this.form.email_body = ''
      this.form.subject = ''
      this.form.user_roles = { 'staff-api': [], 'merchant-api': [] }
    },
  },
}
</script>
