<template>
  <b-modal
    id="update-terms-and-conditions-modal"
    ref="modal"
    v-model="open"
    size="xl"
    no-close-on-backdrop
    hide-footer
    title="UPDATE TERMS & CONDITIONS"
    no-enforce-focus
    @shown="onModalShown"
  >
    <b-row>
      <b-col
        cols="12"
        class="mb-1 mt-2"
      >
        <h4>Content</h4>
      </b-col>
    </b-row>
    <b-overlay
      :show="modalLoading"
      blur="10px"
      opacity="0.30"
      rounded="sm"
      variant="light"
    >
      <b-form
        @submit.prevent
        @submit="updateTermsAndCondition"
      >
        <b-row>
          <b-col
            cols="12"
            class="mb-1 mt-2"
          >
            <div id="app">
              <vue2-tinymce-editor
                v-if="!loading && !modalLoading"
                ref="tinymceEditor"
                v-model="content"
              />
            </div>
          </b-col>
        </b-row>
        <!-- update and reset -->
        <b-row>
          <b-col cols="12 mb-1 ">
            <div class="d-flex justify-content-end">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="primary"
                class="mr-1"
                :disabled="!isContentModified"
              >
                Update
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="button"
                variant="outline-secondary"
                @click="clearContent"
              >
                Clear
              </b-button>
            </div>
          </b-col>
        </b-row>
      </b-form>
    </b-overlay>
  </b-modal>
</template>
<script>
import {
  BModal,
  VBModal,
  BRow,
  BCol,
  BForm,
  BButton,
  BOverlay,
} from 'bootstrap-vue'
import Vue2TinymceEditor from '@core/mixins/TinymceEditor'
import Ripple from 'vue-ripple-directive'
import { RepositoryFactory } from '@/repository/RepositoryFactory'
import SuccessMessage from '@core/mixins/SuccessMessage'
import ErrorMessage from '@core/mixins/ErrorMessage'

const MerchantSettingsRepository = RepositoryFactory.get('merchantSetting')

function initialState() {
  return {
    open: false,
    modalLoading: false,
    loading: true,
    content: '',
    id: null,
    originalContent: '',
  }
}

export default {
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  components: {
    BModal,
    BRow,
    BCol,
    BForm,
    BButton,
    BOverlay,
  },
  mixins: [Vue2TinymceEditor, SuccessMessage, ErrorMessage],
  data() {
    return initialState()
  },
  computed: {
    isContentModified() {
      return this.content !== this.originalContent
    },
  },
  methods: {
    async openModal(id, editorContent) {
      this.open = true
      this.id = id
      this.modalLoading = true
      this.content = editorContent
      this.originalContent = editorContent
    },

    async onModalShown() {
      this.modalLoading = false
      this.loading = false
    },

    async updateTermsAndCondition() {
      this.modalLoading = true
      try {
        await this.openGetConfirmationModel()
        this.open = false
        const payload = {
          content_type: 1,
          content: this.content,
          notify: String(this.isNotifyConfirmed),
        }
        const res = await MerchantSettingsRepository.updateTermsAndCondition(payload)
        if (res.status === 200) {
          this.showSuccessMessage('Terms and Conditions Update Successfully')
        } else {
          this.showErrorMessage('Terms and Conditions Update Fail')
        }
        this.$parent.fetchTermsAndConditionsVersions()
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.modalLoading = false
    },
    clearContent() {
      this.content = ''
    },
    async openGetConfirmationModel() {
      const dialogText = 'Do you want to notify the merchants of the Updated terms and conditions via a banner notification?'
      return this.$swal({
        title: 'Are you sure?',
        text: dialogText,
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
        allowOutsideClick: false,
        backdrop: 'modal-no-backdrop',
      }).then(value => {
        this.isNotifyConfirmed = value.isConfirmed
      })
    },
  },
}
</script>
<style scoped>
</style>
