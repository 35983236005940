<template>
  <b-modal
    id="email-settings-modal"
    ref="modal"
    v-model="open"
    size="lg"
    no-close-on-backdrop
    hide-footer
    centered
  >
    <template #modal-title>
      Email Settings
      <span
        v-if="$route.meta.help"
        class="ml-1"
      >
        <a
          :href="$route.meta.help"
          target="_blank"
        >
          <feather-icon
            v-b-tooltip.hover.v-primary="'Click to view user guide'"
            icon="HelpCircleIcon"
            size="15"
            class="cursor-pointer text-primary"
          />
        </a>
      </span>
    </template>
    <b-form @submit.prevent>
      <b-row>
        <b-col md="4">
          <b-form-group
            label-for="mc-email-driver"
            label="Mail Driver"
          >
            <b-form-input
              id="mc-email-driver"
              v-model="form.driver"
              placeholder="Driver"
              autocomplete="nope"
            />
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group
            label="Host"
            label-for="mc-email-host"
          >
            <b-form-input
              id="mc-email-host"
              v-model="form.host"
              placeholder="Host"
              autocomplete="nope"
            />
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group
            label="Port"
            label-for="mc-email-port"
          >
            <b-form-input
              id="mc-email-port"
              v-model="form.port"
              placeholder="Port"
              autocomplete="nope"
            />
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group
            label="Username"
            label-for="mc-email-username"
          >
            <b-form-input
              id="mc-email-username"
              v-model="form.username"
              placeholder="Username"
              autocomplete="nope"
            />
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group
            label="Password"
            label-for="mc-email-password"
          >
            <b-form-input
              id="mc-email-password"
              v-model="form.password"
              placeholder="Password"
              autocomplete="nope"
            />
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group
            label="Encryption"
            label-for="mc-email-encryption"
          >
            <b-form-input
              id="mc-email-encryption"
              v-model="form.encryption"
              placeholder="tls /ssl"
              autocomplete="nope"
            />
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group
            label="From Address"
            label-for="mc-email-from-address"
          >
            <b-form-input
              id="mc-email-from-address"
              v-model="form.from_address"
              placeholder="From Address"
              autocomplete="nope"
            />
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group
            label="From Name"
            label-for="mc-email-from-name"
          >
            <b-form-input
              id="mc-email-from-name"
              v-model="form.from_name"
              placeholder="From Name"
              autocomplete="nope"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="d-flex justify-content-end">
        <b-form-input
          v-model="testEmail"
          placeholder="Test email address"
          class="w-25 mr-1"
        />
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          type="submit"
          variant="success"
          class="mr-1"
          @click="sendTestEmail"
        >
          Send Test Email
        </b-button>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          type="submit"
          variant="primary"
          @click="setEmailConfig"
        >
          Submit
        </b-button>
      </b-row>
    </b-form>
  </b-modal>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BModal, BFormInput, BButton, VBModal,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import SuccessMessage from '@core/mixins/SuccessMessage'
import ErrorMessage from '@core/mixins/ErrorMessage'
import { RepositoryFactory } from '@/repository/RepositoryFactory'

const EmailSettingsRepository = RepositoryFactory.get('emailSetting')

function initialState() {
  return {
    open: false,
    driverOptions: [
      { name: 'Mail Driver', value: 'mail driver' },
    ],
    form: {},
    testEmail: '',
  }
}

export default {
  components: {
    BRow,
    BCol,
    BModal,
    BFormGroup,
    BFormInput,
    BButton,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  mixins: [SuccessMessage, ErrorMessage],
  data() {
    return initialState()
  },
  methods: {
    async openModal() {
      Object.assign(this.$data, initialState())
      this.open = true
      this.readEmailConfig()
    },
    async readEmailConfig() {
      try {
        const { data } = (await EmailSettingsRepository.getEmailConfigResource()).data
        this.form = data
      } catch (e) {
        this.showErrorMessage('Sorry for the inconvenience! Please Try Again!!')
      }
    },
    async setEmailConfig() {
      try {
        const res = await EmailSettingsRepository.updateEmailConfigResource(this.form)
        if (res.status === 200) {
          this.showSuccessMessage('Email Configuration Updated Successfully')
          this.open = false
        } else {
          this.showErrorMessage('Cannot Update Email Config')
        }
      } catch (e) {
        this.convertAndNotifyError(e)
      }
    },
    async sendTestEmail() {
      try {
        const payload = {
          email: this.testEmail,
        }
        const res = await EmailSettingsRepository.sendTestEmail(payload)
        if (res.status === 200) {
          this.showSuccessMessage('Email Sent Successfully')
        } else {
          this.showErrorMessage('Cannot Send Email')
        }
      } catch (e) {
        this.convertAndNotifyError(e)
      }
    },
  },
}
</script>

<style lang="scss">
@import '../../../@core/scss/vue/libs/vue-select.scss';
</style>
