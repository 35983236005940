<template>
  <b-row>
    <b-col
      md="4"
      sm="8"
      class="my-1"
    >
      <b-input-group size="sm">
        <b-form-select
          id="sortBySelect"
          v-model="sortBy"
          :options="sortOptions"
          class="w-75"
        >
          <template v-slot:first>
            <option value="">
              -- Sort --
            </option>
          </template>
        </b-form-select>
        <b-form-select
          v-model="sortDesc"
          size="sm"
          :disabled="!sortBy"
          class="w-25"
        >
          <option :value="false">
            Asc
          </option>
          <option :value="true">
            Desc
          </option>
        </b-form-select>
      </b-input-group>
    </b-col>
    <b-col
      md="6"
      class="my-1"
    >
      <b-input-group size="sm">
        <b-form-input
          id="filterInput"
          v-model="filter"
          type="search"
          placeholder="Type to Search"
        />
        <b-input-group-append>
          <b-button
            :disabled="!filter"
            @click="filter = ''"
          >
            Clear
          </b-button>
        </b-input-group-append>
      </b-input-group>
    </b-col>

    <b-col cols="12">
      <b-overlay
        blur="10px"
        opacity="0.30"
        rounded="sm"
        variant="light"
      >
        <b-table
          striped
          hover
          responsive
          :per-page="perPage"
          :items="items"
          :fields="fields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          show-empty
          :filter="filter"
          :filter-included-fields="filterOn"
        >
          <template #empty>
            <TableDataFetching
              :rows="items"
              :data-loading="dataLoading"
            />
          </template>
          <template #cell(created_at)="data">
            {{ data.value.toString().substring(0,10) }}
          </template>
          <!--        Portal Notification Active-->
          <template #cell(is_push_notification_active)="data">
            <div v-if="data.value">
              <b-badge variant="light-success">
                Active
              </b-badge>
            </div>
            <div v-if="!data.value">
              <b-badge variant="light-danger">
                Inactive
              </b-badge>
            </div>
          </template>
          <!--        Sms Active-->
          <template #cell(is_sms_active)="data">
            <div v-if="data.value">
              <b-badge variant="light-success">
                Active
              </b-badge>
            </div>
            <div v-if="!data.value">
              <b-badge variant="light-danger">
                Inactive
              </b-badge>
            </div>
          </template>
          <!--        Email Active-->
          <template #cell(is_email_active)="data">
            <div v-if="data.value">
              <b-badge variant="light-success">
                Active
              </b-badge>
            </div>
            <div v-if="!data.value">
              <b-badge variant="light-danger">
                Inactive
              </b-badge>
            </div>
          </template>

          <template #cell(merchant_ids)="data">
            <v-select
              v-model="data.value"
              :options="merchantOptions"
              :reduce="option => option.id"
              multiple
              deselect-from-dropdown
              style="background-color: #f0f0f0; pointer-events:none; cursor: default;"
              label="name"
              size="sm"
              placeholder="Select Merchants"
            >
              <template v-slot:option="option">
                {{ option.name }}
              </template>
            </v-select>
          </template>
        </b-table>
      </b-overlay>
    </b-col>
    <b-col
      class="d-flex align-items-center justify-content-sm-start"
      md="3"
    >
      <label class="d-inline-block text-sm-left mr-50">Per page</label>
      <b-form-select
        id="perPageSelect"
        v-model="perPage"
        size="sm"
        :options="pageOptions"
        class="w-50"
      />
    </b-col>
    <b-col
      cols="12"
      md="9"
      class="d-flex align-items-center justify-content-end"
    >
      <span class="text-muted mr-1">Showing {{ meta.from }} to {{ meta.to }} of {{ meta.total }} entries</span>
      <b-pagination
        v-model="currentPage"
        :per-page="perPage"
        :total-rows="(meta.total)? meta.total : 0"
        class="mb-0 mt-1 mt-sm-0"
        first-number
        last-number
        next-class="next-item"
        prev-class="prev-item"
      >
        <template #prev-text>
          <feather-icon
            icon="ChevronLeftIcon"
            size="18"
          />
        </template>
        <template #next-text>
          <feather-icon
            icon="ChevronRightIcon"
            size="18"
          />
        </template>
      </b-pagination>
    </b-col>
  </b-row>
</template>

<script>
import {
  BTable, BBadge, BRow, BCol, BFormSelect, BPagination, BInputGroup, BFormInput, BInputGroupAppend, BButton, BOverlay,
} from 'bootstrap-vue'
import SuccessMessage from '@core/mixins/SuccessMessage'
import ErrorMessage from '@core/mixins/ErrorMessage'
import { RepositoryFactory } from '@/repository/RepositoryFactory'

const NotificationRepository = RepositoryFactory.get('notification')

export default {
  components: {
    BTable,
    BBadge,
    BRow,
    BCol,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BOverlay,
  },
  mixins: [SuccessMessage, ErrorMessage],
  props: {
    merchantOptions: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      dataLoading: false,
      meta: {},
      perPage: 5,
      pageOptions: [3, 5, 10],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      fields: [
        { key: 'notification_body', label: 'Message', sortable: false },
        { key: 'is_push_notification_active', label: 'Portal Notification', sortable: false },
        { key: 'is_sms_active', label: 'Active SMS', sortable: false },
        { key: 'is_email_active', label: 'Active Email', sortable: false },
        { key: 'merchant_ids', label: 'Broadcasted To', sortable: false },
        { key: 'created_at', label: 'Date', sortable: true },
      ],
      items: [],
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  watch: {
    currentPage() {
      this.fetchMerchantNotifications()
    },
    perPage() {
      this.fetchMerchantNotifications()
    },
  },
  async mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length
    await this.fetchMerchantNotifications()
  },
  methods: {
    async fetchMerchantNotifications() {
      this.dataLoading = true
      this.items = []
      try {
        const { data } = (await NotificationRepository.getMerchantNotifications(this.currentPage, this.perPage))
        this.items = data.data
        this.items = this.items.map(item => {
          // eslint-disable-next-line no-param-reassign
          item.merchant_ids = item.merchant_ids === 'all' ? [-1] : item.merchant_ids
          return item
        })
        this.meta = data.meta
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.dataLoading = false
    },
    handleChangePage(page) {
      this.currentPage = page
    },
    changePage(value) {
      this.perPage = value
    },
  },
}
</script>
