<template>
  <div>
    <b-card title="Update Merchant Settings">
      <b-overlay
        :show="loading"
        blur="10px"
        opacity="0.30"
        rounded="sm"
        variant="light"
      >
        <b-form
          @submit.prevent
          @submit="setMerchantSetting"
        >
          <b-row>
            <b-card
              title="Merchant Type (Cash/Credit) Enable Setting"
            >
              <b-form-radio-group
                v-model="merchantType"
                :options="radioOptions"
                class="demo-inline-spacing mb-1"
                value-field="value"
                text-field="text"
                disabled-field="disabled"
              />
            </b-card>
          </b-row>
          <b-row>
            <div class="ml-2">
              If this mode is on, the Merchant Type (Cash/Credit) Setting will be shown in both merchant and staff portal.
            </div>
          </b-row>
          <b-row>
            <b-card
              title="Merchant Pickup Request Enable Setting"
            >
              <b-form-radio-group
                v-model="pickupEnable"
                :options="radioOptions"
                class="demo-inline-spacing mb-1"
                value-field="value"
                text-field="text"
                disabled-field="disabled"
              />
            </b-card>
          </b-row>
          <b-row>
            <div class="ml-2">
              When enabled, 'Merchant Pickup' is accessible in both merchant and staff portals. Disabling this option will set 'Merchant Pickup' to 'Disabled' for all clients. Please note that if there are any pending pickup requests with 'Pickup Request' enabled, they must be cleared before disabling this setting.
            </div>
          </b-row>
          <b-row>
            <b-card
              title="Terms and Conditions Enable Setting"
            >
              <b-form-radio-group
                v-model="merchantTerm"
                :options="radioOptions"
                class="demo-inline-spacing mb-1"
                value-field="value"
                text-field="text"
                disabled-field="disabled"
              />
            </b-card>
          </b-row>
          <b-row>
            <div class="ml-2">
              When this settings is enabled, the terms and conditions will be displayed to merchants, requiring them to read and agree to them during the account registration process.
            </div>
          </b-row>
          <b-row>
            <!-- submit -->
            <b-col class="d-flex justify-content-end">
              <b-button
                v-can="'business-profile-manage'"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                class="mt-1 custom-button-color"
              >
                Submit
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </b-overlay>
      <add-terms-and-conditions-modal ref="add_terms_and_conditions_modal" />
      <upload-terms-and-condition-pdf-modal ref="upload_terms_and_condition_pdf_modal" />
      <update-terms-and-conditions-modal ref="update_terms_and_conditions_modal" />
    </b-card>
    <b-card-actions
      v-if="merchantTerm"
      title="Filters"
      action-collapse
      collapsed
    >
      <b-row>
        <b-col md="3">
          <b-form-group
            label="Version Number"
            label-for="version_number"
          >
            <b-form-input
              id="version_number"
              v-model="filters['version_number']"
              placeholder="Search"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label="Created Date"
            label-for="created_date"
          >
            <div class="position-relative">
              <flat-pickr
                v-model="filters['created_at']"
                class="form-control"
                :config="{ mode: 'range',enableTime: true, dateFormat: 'Z', altInput: true, altFormat: 'Y-m-d H:i:s'}"
                placeholder="Search Date"
              />
              <div
                v-if="filters['created_at']"
                class="flatpickr-cancel"
                @click="clearDate('created_at')"
              >
                <feather-icon icon="XIcon" />
              </div>
            </div>
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label="Status"
            label-for="status"
          >
            <v-select
              id="status"
              v-model="filters['is_active']"
              :options="statusOptions"
              :reduce="option => option.key"
              label="name"
              placeholder="Select Status"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <!-- Apply and Clear Filters -->
      <b-row class="mt-1">
        <b-col>
          <filter-buttons
            :filter-data="filters"
            :apply-filters="applyFilters"
            :clear-filters="clearFilters"
          />
        </b-col>
      </b-row>
    </b-card-actions>
    <b-card
      v-if="merchantTerm"
      title="Terms and Conditions"
    >
      <b-row class="d-sm-flex align-items-start">
        <b-col
          md="12"
          lg="8"
          class="d-block d-sm-flex flex-wrap justify-content-start align-items-center mb-1 mb-md-0"
        >
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            class="mr-1 add-margin-bottom custom-button-color"
            @click="onClickOpenAddNewTermsAndConditionsModal()"
          >
            <feather-icon icon="PlusIcon" />
            Add New
          </b-button>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            class="mr-1 add-margin-bottom custom-button-color"
            @click="onClickOpenUploadPdfModel()"
          >
            <feather-icon icon="UploadCloudIcon" />
            Upload Pdf
          </b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-overlay
            id="overlay-background"
            class="mt-2"
            blur="10px"
            opacity="0.30"
            rounded="sm"
            variant="light"
          >
            <b-table
              hover
              responsive
              striped
              show-empty
              :items="rows"
              :fields="fields"
              class="hide-vertical-scroll"
            >
              <template #empty>
                <TableDataFetching
                  :rows="rows"
                  :data-loading="dataLoading"
                />
              </template>
              <template #cell(version_number)="data">
                <div class="d-flex align-items-start">
                  {{ data.value }}
                </div>
              </template>
              <template #cell(created_at)="data">
                <div class="d-flex align-items-start">
                  {{ __dateTimeFormatter(data.value) || '---' }}
                </div>
              </template>
              <template #cell(staff)="data">
                <div class="d-flex align-items-start">
                  {{ data.item.staff.first_name }} {{ data.item.staff.last_name }}
                </div>
              </template>
              <template #cell(updated_at)="data">
                <div
                  v-if="data.item.created_at !== data.item.updated_at"
                  class="d-flex align-items-start"
                >
                  {{ __dateTimeFormatter(data.value) || '---' }}
                </div>
              </template>
              <template #cell(is_active)="data">
                <div class="d-flex align-items-start">
                  <div v-if="data.value === 1">
                    <b-badge variant="light-success">
                      Active
                    </b-badge>
                  </div>
                  <div v-if="data.value === 0">
                    <b-badge variant="light-danger">
                      Inactive
                    </b-badge>
                  </div>
                </div>
              </template>
              <template #cell(action)="data">
                <div class="d-flex align-items-start">
                  <b-dropdown
                    variant="link"
                    toggle-class="text-decoration-none"
                    :class="rows.length !== 1 ? 'dropdown-no-scroll':''"
                    no-caret
                  >
                    <template v-slot:button-content>
                      <feather-icon
                        icon="MoreVerticalIcon"
                        size="12"
                        class="text-body align-middle"
                      />
                    </template>
                    <b-dropdown-item
                      @click="onClickEditTermsAndCondition(data.item.id)"
                    >
                      <feather-icon icon="EditIcon" />
                      Edit
                    </b-dropdown-item>
                    <b-dropdown-item
                      v-if="!data.item.is_active"
                      @click="onClickDelete(data.item.id)"
                    >
                      <feather-icon icon="ToolIcon" />
                      Delete
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </template>
            </b-table>
          </b-overlay>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BRow, BCard, BOverlay, BFormRadioGroup, BCol, BForm,
  BButton,
  BTable,
  BDropdown,
  BDropdownItem,
  BBadge,
  BFormGroup,
  BFormInput,
} from 'bootstrap-vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import Ripple from 'vue-ripple-directive'
import SuccessMessage from '@core/mixins/SuccessMessage'
import ErrorMessage from '@core/mixins/ErrorMessage'
import { RepositoryFactory } from '@/repository/RepositoryFactory'
import AddTermsAndConditionsModal from '@/components/TermsAndConditions/AddTermsAndConditionsModal.vue'
import UpdateTermsAndConditionsModal from '@/components/TermsAndConditions/UpdateTermsAndConditionsModal.vue'
import UploadTermsAndConditionPdfModal from '@/components/TermsAndConditions/UploadTermsAndConditionPdfModal.vue'
import FilterButtons from '@/components/Filter/FilterButtons.vue'
import Filter from '@core/mixins/filter'
import flatPickr from 'vue-flatpickr-component'

const MerchantSettingsRepository = RepositoryFactory.get('merchantSetting')

export default {
  components: {
    BRow,
    BCard,
    BOverlay,
    BFormRadioGroup,
    BCol,
    BForm,
    BButton,
    AddTermsAndConditionsModal,
    BTable,
    BDropdown,
    BDropdownItem,
    BBadge,
    UpdateTermsAndConditionsModal,
    FilterButtons,
    BCardActions,
    BFormGroup,
    BFormInput,
    UploadTermsAndConditionPdfModal,
    flatPickr,
  },
  directives: {
    Ripple,
  },
  mixins: [SuccessMessage, ErrorMessage, Filter],
  data() {
    return {
      dataLoading: false,
      loading: false,
      form: {},
      radioOptions: [
        { text: 'On', value: true },
        { text: 'Off', value: false },
      ],
      merchantType: false,
      pickupEnable: false,
      merchantTerm: false,
      rows: [],
      meta: {},
      fields: [
        {
          key: 'version_number', label: 'Version Number', sortable: true, thClass: ' text-left', tdClass: ' text-left',
        },
        {
          key: 'created_at', label: 'Created Date', sortable: true, thClass: ' text-left', tdClass: ' text-left',
        },
        {
          key: 'staff', label: 'Created By', sortable: true, thClass: ' text-left', tdClass: ' text-left',
        },
        {
          key: 'updated_at', label: 'Edited Date', sortable: true, thClass: ' text-left', tdClass: ' text-left',
        },
        {
          key: 'is_active', label: 'Status', sortable: true, thClass: ' text-left', tdClass: ' text-left',
        },
        {
          key: 'action', label: 'Action', sortable: false, thClass: ' text-left', tdClass: ' text-left',
        },
      ],
      filters: {},
      statusOptions: [
        { name: 'Active', key: '1' },
        { name: 'Inactive', key: '0' },
      ],
      filterOn: [],
    }
  },
  mounted() {
    this.readMerchantSetting()
    this.fetchTermsAndConditionsVersions()
  },
  methods: {
    async readMerchantSetting() {
      this.loading = true
      try {
        const { data } = (await MerchantSettingsRepository.getMerchantSettingsResource()).data
        this.form = data
        this.merchantType = this.form.merchant_type_select
        this.pickupEnable = this.form.merchant_pickup_enable
        this.merchantTerm = this.form.terms_and_condition_enabale
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.loading = false
    },
    async setMerchantSetting() {
      this.loading = true
      // if (!this.pickupEnable) {
      //   await this.showMsgBox()
      // }
      try {
        // if (this.isDeliveryConfirmed === true || this.pickupEnable) {
        this.form.merchant_type_select = this.merchantType
        this.form.merchant_pickup_enable = this.pickupEnable
        this.form.terms_and_condition_enabale = this.merchantTerm
        const res = await MerchantSettingsRepository.updateMerchantSettingsResource(this.form)
        if (res.status === 200) {
          this.showSuccessMessage('Merchant Settings Updated Successfully')
        } else {
          this.showErrorMessage('Cannot Update Merchant Settings')
        }
        // }
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.readMerchantSetting()
      this.loading = false
    },
    // async showMsgBox() {
    //   this.isDeliveryConfirmed = false
    //   const dialogText = 'When disabled, pickup requests will be automatically sent for orders with \'Pickup Request\' enabled.'
    //   return this.$swal({
    //     title: 'Are You Sure ?',
    //     text: dialogText,
    //     icon: 'info',
    //     showCancelButton: true,
    //     confirmButtonText: 'Yes, confirm!',
    //     cancelButtonText: 'No',
    //     customClass: {
    //       confirmButton: 'btn btn-primary',
    //       cancelButton: 'btn btn-outline-danger ml-1',
    //     },
    //     buttonsStyling: false,
    //     allowOutsideClick: false,
    //     backdrop: false,
    //   }).then(value => {
    //     this.isDeliveryConfirmed = value.isConfirmed
    //   })
    // },
    onClickOpenAddNewTermsAndConditionsModal() {
      this.$refs.add_terms_and_conditions_modal.openModal()
    },
    async fetchTermsAndConditionsVersions() {
      this.dataLoading = true
      try {
        // eslint-disable-next-line no-underscore-dangle
        this.filterQuery = this.__refineDateQuery(this.filterQuery, [this.filters])
        const { data } = await MerchantSettingsRepository.fetchTermsAndConditionsVersions(this.filterQuery)
        this.rows = data.data
        this.meta = data.meta
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.dataLoading = false
    },
    async onClickDelete(id) {
      try {
        const res = await MerchantSettingsRepository.onClickDelete(id)
        if (res.status === 200) {
          this.showSuccessMessage('Terms and Conditions Deleted Successfully')
        } else {
          this.showErrorMessage('Cannot Delete Terms and Conditions')
        }
        this.fetchTermsAndConditionsVersions()
      } catch (e) {
        this.convertAndNotifyError(e)
      }
    },
    async onClickEditTermsAndCondition(id) {
      try {
        const res = await MerchantSettingsRepository.onClickEditTermsAndCondition(id)
        this.contentType = res.data.data.content_type
        if (this.contentType === 1) {
          this.editorContent = res.data.data.content
          this.$refs.update_terms_and_conditions_modal.openModal(id, this.editorContent)
        } else {
          this.$refs.upload_terms_and_condition_pdf_modal.openModal()
        }
      } catch (e) {
        this.convertAndNotifyError(e)
      }
    },
    applyFilters() {
      this.fetchTermsAndConditionsVersions()
    },
    clearFilters() {
      this.filters = {}
      this.$nextTick(() => {
        this.fetchTermsAndConditionsVersions()
      })
    },
    onClickOpenUploadPdfModel() {
      this.$refs.upload_terms_and_condition_pdf_modal.openModal()
    },
    clearDate(filterKey) {
      this.filters[filterKey] = ''
    },
  },
}
</script>
<style scoped>
.custom-button-color {
  background-color: #4186f5 !important;
  border-color: #4186f5 !important;
}
.content-body-cell {
  /* Limit the text to 3 lines */
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
  /* Add ellipsis for overflow */
  text-overflow: ellipsis;
  /* Adjust width as needed */
  max-width: 300px; /* Set the maximum width */
}
.add-margin-bottom {
    margin-bottom: 20px;
}
@media (max-width: 767px) {
  .b-table thead th {
    position: sticky;
    top: 0;
  }
  .add-margin-bottom {
    width: 100%;
  }
}
</style>
