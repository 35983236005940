<template>
  <validationObserver
    ref="taxForm"
    v-slot="{ handleSubmit }"
    slim
  >
    <b-card
      v-can="'create-tax'"
      title="Update Tax Settings"
    >
      <b-overlay
        :show="loading"
        blur="10px"
        opacity="0.30"
        rounded="sm"
        variant="light"
      >
        <b-form
          ref="taxForm"
          @submit.prevent="handleSubmit(setTaxSetting)"
        >
          <!-- Start of : Tax   -->
          <b-row>
            <b-card
              title="Tax Option"
            >
              <b-form-radio-group
                v-model="taxSetting.enabled"
                :options="taxOptions"
                value-field="item"
                text-field="name"
                disabled-field="notEnabled"
                @change="updateTaxSetting()"
              />
            </b-card>
          </b-row>
          <b-row>
            <div class="ml-2">
              If this Option is enabled the tax types rates which will be added to the final invoice can be added and customized here.
            </div>
          </b-row>
          <b-row
            v-if="taxSetting.enabled"
          >
            <b-card
              title="Create Your Taxes Here"
            >
              <div>
                <div
                  v-for="(items, index) in inputFields"
                  :key="index"
                >
                  <b-row>
                    <b-col
                      md="6"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Tax Name"
                        rules="required|max:50"
                      >
                        <label for="name">Tax Name: *</label>
                        <b-form-input
                          v-model="items.tax_name"
                          :state="errors.length > 0 ? false:null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-col>
                    <b-col
                      md="6"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Tax Number"
                        rules="required|max:50"
                      >
                        <label for="name">Tax Number: *</label>
                        <b-form-input
                          v-model="items.tax_number"
                          :state="errors.length > 0 ? false:null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-col>
                    <b-col
                      md="6"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Tax Type"
                        rules="required|max:50"
                      >
                        <label for="name">Tax Rate Type: *</label>
                        <v-select
                          v-model="items.tax_rate_type"
                          :options="taxTypes"
                          :state="errors.length > 0 ? false:null"
                          placeholder="Select Tax Type"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-col>
                    <b-col
                      md="6"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Tax Rate"
                        rules="required|numeric"
                      >
                        <label for="name">Tax Rate: *</label>
                        <b-form-input
                          v-model="items.tax_rate"
                          :state="errors.length > 0 ? false:null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-col>
                    <b-col
                      md="6"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Effective Date"
                        rules="required"
                      >
                        <label for="name">Effective Date: *</label>
                        <b-form-datepicker
                          v-model="items.effective_date"
                          class="effectiveDate"
                          :style="{ 'max-height': '500px' }"
                          :state="errors.length > 0 ? false:null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-col>
                    <b-col
                      md="6"
                      class="mt-2"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Is Active"
                        rules="required"
                      >
                        <label for="name">Is Active: *</label>
                        <b-form-checkbox
                          v-model="items.is_active"
                          :value="true"
                          :state="errors.length > 0 ? false:null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-col>
                    <b-col
                      md="12"
                    >
                      <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="success"
                        class="mt-0 mt-md-2 mb-2 ml-2 btn-icon set_right"
                        @click="addField()"
                      >
                        <feather-icon
                          icon="PlusIcon"
                          class="mr-25"
                        />
                      </b-button>
                      <b-button
                        v-if="index != 0"
                        v-ripple.400="'rgba(238, 114, 115)'"
                        variant="danger"
                        class="mt-0 mt-md-2 mb-2 ml-2 btn-icon set_right"
                        @click="removeField(index)"
                      >
                        <feather-icon
                          icon="Trash2Icon"
                          class="mr-25"
                        />
                      </b-button>
                    </b-col>
                  </b-row>
                </div>
              </div>
            </b-card>
          </b-row>
          <!-- End of : Override Actual Weight   -->
          <b-row
            v-if="taxSetting.enabled"
          >
            <!-- submit -->
            <b-col class="d-flex justify-content-end">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                class="mt-1 custom-button-color"
              >
                Submit
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </b-overlay>
    </b-card>
    <b-card>
      <b-overlay
        :show="loading"
        blur="10px"
        opacity="0.30"
        rounded="sm"
        variant="light"
      >
        <b-form>
          <!-- Start of : Tax   -->
          <b-row
            v-if="taxSetting.enabled && updateInputFields.length > 0"
          >
            <b-card
              title="Update Tax Statuses"
            >
              <div class="mb-2">
                All Taxes that have been created and saved will be shown here. The tax types can also be updated as active and inactive as required.
              </div>
              <div>
                <div
                  v-for="(updateItems, updateIndex) in updateInputFields"
                  :key="updateIndex"
                >
                  <b-row>
                    <b-col
                      md="6"
                    >
                      <label for="name">Tax Name: *</label>
                      <b-form-input
                        v-model="updateItems.tax_name"
                        readonly
                      />
                    </b-col>
                    <b-col
                      md="6"
                    >
                      <label for="name">Tax Number: *</label>
                      <b-form-input
                        v-model="updateItems.tax_number"
                        readonly
                      />
                    </b-col>
                    <b-col
                      md="6"
                    >
                      <label for="name">Tax Rate Type: *</label>
                      <b-form-input
                        v-model="updateItems.tax_rate_type"
                        :readonly="true"
                      />
                    </b-col>
                    <b-col
                      md="6"
                    >
                      <label for="name">Tax Rate: *</label>
                      <b-form-input
                        v-model="updateItems.tax_rate"
                        readonly
                      />
                    </b-col>
                    <b-col
                      md="6"
                    >
                      <label for="name">Effective Date: *</label>
                      <b-form-datepicker
                        v-model="updateItems.effective_date"
                        readonly
                      />
                    </b-col>
                    <b-col
                      v-can="'edit-tax-status'"
                      md="6"
                      class="mt-2"
                    >
                      <label for="name">Is Active: *</label>
                      <b-form-checkbox
                        v-model="updateItems.is_active"
                        :value="true"
                      />
                    </b-col>
                  </b-row><hr>
                </div>
              </div>
            </b-card>
          </b-row>
          <!-- End of : Override Actual Weight   -->
          <b-row
            v-if="taxSetting.enabled && updateInputFields.length > 0"
          >
            <!-- submit -->
            <b-col
              v-can="'edit-tax-status'"
              class="d-flex justify-content-end"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="button"
                class="mt-1 custom-button-color"
                @click="saveTaxStatus()"
              >
                Update
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </b-overlay>
    </b-card>
  </validationObserver>
</template>

<script>
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import { max, numeric } from 'vee-validate/dist/rules'
import {
  BRow, BCol, BForm, BButton, BCard, BOverlay, BFormRadioGroup, BFormInput, BFormDatepicker, BFormCheckbox,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import SuccessMessage from '@core/mixins/SuccessMessage'
import ErrorMessage from '@core/mixins/ErrorMessage'
import { RepositoryFactory } from '@/repository/RepositoryFactory'
import vSelect from 'vue-select'

extend('max', max)
extend('numeric', numeric)

const TaxSettingsRepository = RepositoryFactory.get('taxSetting')

export default {
  components: {
    BRow,
    BCol,
    BForm,
    BButton,
    BCard,
    BOverlay,
    BFormRadioGroup,
    BFormInput,
    BFormDatepicker,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    // BFormRadio,
  },
  directives: {
    Ripple,
  },
  mixins: [SuccessMessage, ErrorMessage],
  data() {
    return {
      loading: false,
      form: {
      },
      taxSetting: {
        enabled: '',
      },
      taxes: [],
      taxTypes: ['Fixed', 'Percentage'],
      taxOptions: [
        { item: true, name: 'On' },
        { item: false, name: 'Off' },
      ],
      inputFields: [
        {
          tax_name: '',
          tax_number: '',
          tax_rate_type: '',
          tax_rate: '',
          is_active: true,
          effective_date: '',
        },
      ],
      updateInputFields: [
        {
          tax_name: '',
          tax_number: '',
          tax_rate_type: '',
          tax_rate: '',
          is_active: true,
          effective_date: '',
        },
      ],
      types: {
        tax_types: [
        ],
      },
      updateTypes: {
        tax_status: [
        ],
      },
    }
  },
  mounted() {
    this.readTaxSetting()
    this.getTaxes()
  },
  methods: {
    addField() {
      const object = {
        tax_name: '',
        tax_number: '',
        tax_rate: '',
        tax_rate_type: '',
        is_active: true,
        effective_date: '',
      }
      this.inputFields.push(object)
    },
    removeField(index) {
      this.inputFields.splice(index, 1)
    },
    async readTaxSetting() {
      this.taxSetting = await TaxSettingsRepository.getTaxSetting()
      this.taxSetting = this.taxSetting.data.data
    },
    async getTaxes() {
      this.taxes = await TaxSettingsRepository.getTaxes()
      this.updateInputFields = this.taxes.data.data
    },
    async updateTaxSetting() {
      const res = await TaxSettingsRepository.updateTaxSetting(this.taxSetting)
      if (res.status === 200) {
        this.showSuccessMessage('Tax Settings Updated Successfully')
      } else {
        this.showErrorMessage('Cannot Update Tax Settings')
      }
    },
    async setTaxSetting() {
      this.loading = true
      try {
        this.types.tax_types = this.inputFields
        const res = await TaxSettingsRepository.saveTaxes(this.types)
        if (res.status === 200) {
          this.showSuccessMessage('Taxes Saved Successfully')
          this.getTaxes()
        } else {
          this.showErrorMessage('Cannot Update Taxes')
        }
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.loading = false
    },
    async saveTaxStatus() {
      this.loading = true
      try {
        this.updateTypes.tax_status = this.updateInputFields
        const res = await TaxSettingsRepository.updateTaxes(this.updateTypes)
        if (res.status === 200) {
          this.showSuccessMessage('Taxes Updated Successfully')
        } else {
          this.showErrorMessage('Cannot Update Taxes')
        }
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.loading = false
    },
  },
}
</script>
<style scoped lang="scss">
.set_right {
  float:right;
}
.custom-button-color {
  background-color: #4186f5 !important;
  border-color: #4186f5 !important;
}
</style>
