<template>
  <b-card title="">
    <b-overlay
      :show="loading"
      blur="10px"
      opacity="0.30"
      rounded="sm"
      variant="light"
    >
      <validation-observer>
        <b-form
          v-if="canEditTrackingImage"
          @submit.prevent="ValidateBgImg"
        >
          <b-row>
            <b-col md="12">
              <div class="d-sm-flex justify-content-between align-items-center mb-3">
                <h4
                  title="'Tooltip text here'"
                  class="card-title mb-50 mb-sm-0"
                >
                  {{ $t('Update Tracking Page Image') }}
                </h4>
                <feather-icon
                  v-b-tooltip.hover.v-primary="'The following validations should apply when uploading image: file type must be either PNG or JPEG, file size cannot exceed 5MB, and image resolution must be 1380 pixels wide by 920 pixels high.'"
                  icon="HelpCircleIcon"
                  size="15"
                  class="cursor-pointer text-primary"
                />
              </div>
              <b-form-group
                label="Tracking Background Image"
                label-for="BgTracking"
              >
                <validation-provider
                  rules="required"
                >
                  <b-form-file
                    v-model="file"
                    accept="image/*"
                    :placeholder="imageName"
                    drop-placeholder="Drop file here..."
                    required
                  />
                  <div
                    v-if="showError"
                    class="invalid-feedback"
                  />
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- submit -->
            <b-col class="d-flex justify-content-end">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                class="custom-button-color"
              >
                Submit
              </b-button>
            </b-col>
          </b-row>
        </b-form>
        <b-form
          v-if="canEditAdditionalDetails"
          @submit.prevent="ValidateAddDeatils"
        >
          <b-row>
            <b-card
              title="Additional Tracking Details"
            >
              <b-form-radio-group
                v-model="form.advance_tracking_details"
                :options="radioOptions"
                class="demo-inline-spacing mb-1"
                value-field="value"
                text-field="text"
                disabled-field="disabled"
                @change="ValidateAddDeatils"
              />
            </b-card>
          </b-row>
          <b-row v-if="form.advance_tracking_details">
            <b-col>
              <b-table
                hover
                responsive
                striped
                show-empty
                :per-page="perPage"
                :items="tableRows"
                :fields="fields"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection"
                sticky-header="100vh"
                class="hide-vertical-scroll"
              >
                <template #empty>
                  <TableDataFetching
                    :rows="tableRows"
                    :data-loading="dataLoading"
                  />
                </template>
                <template #cell(display_values)="data">
                  <div>
                    <div
                      v-for="(value, index) in data.item.display_values.values"
                      :key="index"
                    >
                      <div
                        v-for="(val, key) in value"
                        :key="key"
                      >
                        <span class="mr-1">{{ val }}</span>
                      </div>
                    </div>
                  </div>
                </template>
                <template #cell(display_values.enabled)="data">
                  <b-badge :variant="data.value ? 'light-success' : 'light-danger'">
                    {{ data.value ? 'Active' : 'Inactive' }}
                  </b-badge>
                </template>
                <template #cell(action)="data">
                  <b-dropdown
                    variant="link"
                    toggle-class="text-decoration-none"
                    :class="tableRows.length !== 1 ? 'dropdown-no-scroll' : ''"
                    no-caret
                  >
                    <template v-slot:button-content>
                      <feather-icon
                        icon="MoreVerticalIcon"
                        size="12"
                        class="text-body align-middle mr-25"
                      />
                    </template>
                    <b-dropdown-item
                      @click="onClickStatusUpdate(data.item.id, !data.item.display_values.enabled)"
                    >
                      <feather-icon icon="ToolIcon" />
                      {{ !data.item.display_values.enabled ? 'Activate' : 'Deactivate' }}
                    </b-dropdown-item>
                  </b-dropdown>
                </template>
              </b-table>
            </b-col>
          </b-row>
          <div class="mx-2 mb-2" v-if="form.advance_tracking_details">
            <b-row cols="12">
              <b-col
                class="d-flex align-items-center justify-content-sm-start"
                md="3"
              >
                <label>Per page</label>
                <v-select
                  v-model="perPage"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="pageOptions"
                  :clearable="false"
                  class="per-page-selector d-inline-block ml-1 width-100 mr-1"
                />
              </b-col>
              <b-col
                md="9"
                class="d-flex align-items-center justify-content-end"
              >
                <span class="text-muted mr-1">Showing {{ meta.from }} to {{ meta.to }} of {{ meta.total }} entries</span>
                <b-pagination
                  v-model="page"
                  :per-page="perPage"
                  :total-rows="(meta.total)? meta.total : 0"
                  class="mb-0 mt-1 mt-sm-0"
                  first-number
                  last-number
                  next-class="next-item"
                  prev-class="prev-item"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </b-col>
            </b-row>
          </div>
          <b-row>
            <!-- submit -->
            <b-col class="d-flex justify-content-end">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                class="custom-button-color mb-2"
              >
                Submit
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-overlay>
  </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard, BOverlay, BForm, BRow, BCol, BFormGroup, BFormFile, BButton, VBTooltip, BFormRadioGroup, BTable, BBadge, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'
import SuccessMessage from '@core/mixins/SuccessMessage'
import ErrorMessage from '@core/mixins/ErrorMessage'
import Ripple from 'vue-ripple-directive'
import { RepositoryFactory } from '@/repository/RepositoryFactory'
import VSelect from 'vue-select'
import store from '@/store/index'

const TrackingSettingsRepository = RepositoryFactory.get('trackingSetting')

export default {
  name: 'BgTracking',
  components: {
    BCard,
    BOverlay,
    BForm,
    BRow,
    BCol,
    BFormGroup,
    BFormFile,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BFormRadioGroup,
    BTable,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    VSelect,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  mixins: [SuccessMessage, ErrorMessage],
  data() {
    return {
      loading: false,
      dataLoading: false,
      file: null,
      showError: false,
      form: {
        advance_tracking_details: null,
      },
      currentLogo: '',
      typevalidation: true,
      sizevalidation: true,
      resolutionvalidate: true,
      radioOptions: [
        { text: 'On', value: true },
        { text: 'Off', value: false },
      ],
      tableRows: [],
      fields: [
        { key: 'name', label: 'Status Name' },
        { key: 'display_values', label: 'Details to show', sortable: true },
        { key: 'display_values.enabled', label: 'Status', sortable: true },
        store.getters.permissions.filter(permission => permission === 'show-edit-additional-tracking-details').length > 0 ? {
          key: 'action', label: 'Action',
        } : {},
      ],
      sort: '',
      meta: {},
      perPage: 20,
      pageOptions: [10, 20, 50],
      totalRows: 1,
      page: 1,
    }
  },
  computed: {
    canEditAdditionalDetails() {
      return store.getters.permissions.filter(permission => permission === 'show-edit-additional-tracking-details').length > 0
    },
    canEditTrackingImage() {
      return store.getters.permissions.filter(permission => permission === 'tracking-page-image').length > 0
    },
  },
  watch: {
    page() {
      this.getAdvanceTrackingList()
    },
    perPage() {
      this.getAdvanceTrackingList()
    },
  },
  mounted() {
    this.getCurrentLogo()
    this.getAdvanceTrackingList()
  },
  methods: {
    async getCurrentLogo() {
      this.loading = true
      try {
        const response = await TrackingSettingsRepository.getCurrentLogo()
        const logoData = response.data.data
        // Assign the image or set file to an empty string if tracking_page_image is null
        this.file = logoData.tracking_page_image || ''
        // If the image exists, extract its name
        this.imageName = this.file ? this.file.split('/').pop() : 'No image selected'
        // Ensure advance_tracking_details is set correctly
        this.form.advance_tracking_details = logoData.advance_tracking_details
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.loading = false
    },
    async getAdvanceTrackingList() {
      this.dataLoading = true
      this.tableRows = []
      try {
        const { data } = await TrackingSettingsRepository.getAdvanceTrackingList(this.page, this.perPage)
        this.tableRows = data.data
        this.meta = data.meta
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.dataLoading = false
    },
    async onClickStatusUpdate(id, active) {
      try {
        const payload = {
          display_values: {
            enabled: active,
          },
        }
        const res = await TrackingSettingsRepository.setActiveStatus(id, payload)

        if (res.status === 200) {
          this.showSuccessMessage(`Status ${active ? 'Activated' : 'Deactivated'} Successfully`)
          this.getAdvanceTrackingList()
        } else {
          this.showErrorMessage(`Couldn't ${active ? 'Activate' : 'Deactivate'} Status`)
        }
      } catch (e) {
        this.convertAndNotifyError(e)
      }
    },
    async handleSubmit() {
      if (this.canEditTrackingImage) {
        await this.ValidateBgImg()
      }
      if (this.canEditAdditionalDetails) {
        await this.updateAdvanceTrackingValue()
      }
    },
    async ValidateAddDeatils() {
      // Add any validation or additional logic if needed before submitting
      await this.updateAdvanceTrackingValue()
    },
    async updateAdvanceTrackingValue() {
      try {
        const payload = {
          advance_tracking_details: this.form.advance_tracking_details,
        }
        const res = await TrackingSettingsRepository.updateAdvanceTrackingValue(payload)
        if (res.status === 200) {
          this.showSuccessMessage('Update Additional Tracking Details Successfully')
        } else {
          this.showErrorMessage('Failed to Update Additional Tracking Details')
        }
      } catch (e) {
        this.convertAndNotifyError(e)
      }
    },
    async ValidateBgImg() {
      this.loading = true
      const reader = new FileReader()
      reader.readAsDataURL(this.file)
      // Start file type validation
      const allowedTypes = process.env.VUE_APP_ALLOWED_BACKGROUND_IMAGE_TYPES.split(',')
      const typeValidateData = await this.$validate.validateType(this.file.type, allowedTypes)
      this.typevalidation = typeValidateData.status
      if (!typeValidateData.status) {
        this.showErrorMessage(typeValidateData.message)
      }
      // End of file type validation

      // Start file size validation
      const allowedFileSize = parseInt(process.env.VUE_APP_MAXIMUM_FILE_SIZE, 10)
      const sizeValidateData = await this.$validate.validateSize(this.file.size, allowedFileSize)
      this.sizevalidation = sizeValidateData.status
      if (!sizeValidateData.status) {
        this.showErrorMessage(sizeValidateData.message)
      }
      // End of file size validation

      // Start image resolution validation
      reader.onload = async () => {
        const image = new Image()
        image.src = reader.result
        image.onload = async () => {
          this.resolutionValidate(image.width, image.height)
        }
      }
      this.loading = false
      // End of resolutition validator
    },
    async resolutionValidate(width, height) {
      const fixWidth = 1380
      const fixHeight = 920
      const resolutionValidateData = await this.$validate.resolutionValidate(width, height, fixWidth, fixHeight)
      this.resolutionvalidate = resolutionValidateData.status
      if (!resolutionValidateData.status) {
        this.showErrorMessage(resolutionValidateData.message)
      }
      this.saveBackgroundImage()
    },
    async saveBackgroundImage() {
      this.loading = true
      try {
        if (this.typevalidation && this.sizevalidation && this.resolutionvalidate) {
          const res = await TrackingSettingsRepository.saveBackgroundImage(this.file)
          if (res.status === 200) {
            this.showSuccessMessage('Image Uploaded Successfull')
          } else {
            this.showErrorMessage('Image not uploaded')
          }
        } else {
          this.typevalidation = true
          this.sizevalidation = true
          this.resolutionvalidate = true
        }
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.loading = false
    },
  },
}
</script>

<style scoped>
.custom-button-color {
  background-color: #4186f5 !important;
  border-color: #4186f5 !important;
}
</style>
